import { callApiPostMethod } from "./api.action";
import { DATA_INSERTED, ORDER } from "./apiResponseInterfaces";

export const createLimitOrderVoucher = async (data: ORDER) => {
  const {
    pairAddress,
    assetIn,
    assetOut,
    amountsIn,
    amountsOut,
    userAddress,
    rate,
    salt,
    expiryTime,
    callData,
    signature,
    dispatch,
    message,
    chainId,
  } = data;
  try {
    const result: DATA_INSERTED = await dispatch(
      callApiPostMethod(
        "CREATE_LIMIT_ORDER",
        {
          pairAddress,
          assetIn,
          assetOut,
          amountsIn,
          amountsOut,
          userAddress,
          rate,
          salt,
          expiryTime,
          callData,
          signature,
          message,
          chainId,
        },
        false,
        false
      )
    );
    if (result?.status == 200) {
      return true;
    }
  } catch (error: any) {
    console.log(error.mesage);
    return false;
  }
};

export const createStopOrderVoucher = async (data: ORDER) => {
  const {
    pairAddress,
    assetIn,
    assetOut,
    amountsIn,
    amountsOut,
    userAddress,
    rate,
    salt,
    expiryTime,
    callData,
    signature,
    dispatch,
    message,
    chainId,
  } = data;
  try {
    const result: DATA_INSERTED = await dispatch(
      callApiPostMethod(
        "CREATE_STOP_LOSS_ORDER",
        {
          pairAddress,
          assetIn,
          assetOut,
          amountsIn,
          amountsOut,
          userAddress,
          rate,
          salt,
          expiryTime,
          signature,
          callData,
          message,
          chainId,
        },
        false,
        false
      )
    );
    if (result?.status == 200) {
      return true;
    }
  } catch (error: any) {
    console.log(error.mesage);
    return false;
  }
};

import { createSlice } from "@reduxjs/toolkit";
import tokenABi from "../../Abi/tokenabi.json";
import libertyfinanceIcon from "../../Assets/Images/Icons/tokens/libertyfinance-white.png";
import ethIcon from "../../Assets/Images/Icons/tokens/Eth.svg";
import usdcIcon from "../../Assets/Images/Icons/tokens/usdcIcon.svg";
import usdtIcon from "../../Assets/Images/Icons/tokens/usdticon.svg";
import {
  dynamicContractDetails,
  timeDuration,
  tokenCollection,
} from "../../Services/dynamicContractDetails";

const tokenOne = {
  name: "LIBFI",
  symbol: "LIBFI",
  address: dynamicContractDetails.find((a) => a.symbol == "LIBFI")?.address,
  abi: tokenABi,
  img: libertyfinanceIcon,
  isSelected: true,
  isNative: false,
  id: "libfx",
  decimals: 18,
};
const tokenTwo = {
  name: "ETH",
  symbol: "ETH",
  address: dynamicContractDetails.find((a) => a.symbol == "ETH")?.address,
  abi: tokenABi,
  img: ethIcon,
  isSelected: true,
  isNative: true,
  id: "ethereum",
  decimals: 18,
};

const initialState = {
  tokenOne: tokenOne,
  tokenTwo: tokenTwo,
  tokenList: tokenCollection,
  orderExpiredDays: {
    label: "7 Days",
    convertedTime: "604800",
    id: "6",
    isSelected: false,
  },
  timeList: timeDuration,
};

export const tokenSlice = createSlice({
  name: "token",
  initialState,

  reducers: {
    settokenOne: (state, action) => {
      state.tokenOne = action.payload;
    },
    settokenTwo: (state, action) => {
      state.tokenTwo = action.payload;
    },
    settokenList: (state, action) => {
      state.tokenList = action.payload;
    },
    setOrderExpiredDays: (state, action) => {
      state.orderExpiredDays = action.payload;
    },
    resetTokenSlice: () => initialState,
  },
});

export const {
  settokenOne,
  settokenTwo,
  resetTokenSlice,
  setOrderExpiredDays,
} = tokenSlice.actions;

import React, { useEffect, useState } from "react";

import "./SetupPage.scss";
import CommonHeading from "../../../Common/Heading/Heading";
import ButtonCustom from "../../../Common/Button/ButtonCustom";
import { Link, useNavigate } from "react-router-dom";
import GetWallet from "../../../Common/Modals/GetWallet/GetWallet";
import WalletOptionsModal from "../../../Common/Modals/WalletOptions/WalletOptions";
import metamaskIcon from '../../../../Assets/Images/Icons/wallets/metamask.svg';
import walletConnect from "../../../../Assets/Images/Icons/wallets/wallet-connect.svg";
import { isMobile } from "react-device-detect";
import { isMetaMaskInstalled } from "../../../../Redux/Actions/user.action";
import { setUserOnboardingData } from "../../../../Redux/Slices/user.slice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const SetupPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(false);
  const [showWalletOptions, setShowWalletOptions] = useState<boolean>(false);
  const [metamaskChecks, setMetamaskChecks] = useState<boolean>(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (!isMobile) {
      metamaskCheck();
    }
  }, []);

  const metamaskCheck = async () => {
    const result = await isMetaMaskInstalled();
    setMetamaskChecks(result);
  };

  const handleSkipped = () => {
    dispatch(setUserOnboardingData({ progress: 100, path: "/auth/dashboard/libfi" }));
    navigate("/auth/dashboard/libfi");
  };

  // const handleWalletRedirect = async () => {
  //   if (!metamaskChecks && !isMobile) {
  //     window.open("https://metamask.io/download/");
  //   } else if (isMobile) {
  //     window.location.href = "market://details?id=io.metamask";
  //   }
  // };
  // const metamaskCheck = async () => {
  //   const result: boolean = await isMetaMaskInstalled();
  //   setMetamaskChecks(result);
  // };

  const wallets = [
    { name: "MetaMask", icon: metamaskIcon },
    //{ name: "WalletConnect", icon: walletConnect},
  ]

  const onSelectWallet = (wallet) => {
    console.log("Selected Wallet:", wallet.name);
  };

  const handleWalletRedirect = () => {
    setShowWalletOptions(true);
  };

  return (
    <section className="SetupPage">
      <CommonHeading
        heading={t("getSetup")}
        subText={t("getSetupDesc")}
        centered
      />
      <div className="SetupPageAction">
        <ButtonCustom
          title={t("getWallet")}
          className="bordered-blue SetupPageBtn"
          fluid
          centered
          onClick={handleWalletRedirect}
          disabled={false}
        />
        <ButtonCustom
          title={t("connectExistingWallet")}
          className="bordered-blue SetupPageBtn"
          fluid
          centered
          onClick={() => setShow(true)}
          disabled={!isMetaMaskInstalled()}
        />
      </div>
      <Link to="" className="setupLater" onClick={handleSkipped}>
        { t("setupWalletLater")}
      </Link>
      <GetWallet show={show} handleClose={() => setShow(false)} />
      {showWalletOptions && (
       <WalletOptionsModal
       show={showWalletOptions}
       handleClose={() => setShowWalletOptions(false)}
       wallets={wallets}
       onSelectWallet={onSelectWallet}
        />
      )}
    </section>
  );
};
export default SetupPage;

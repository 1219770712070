import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './ErrorPage.scss';
import { useTranslation } from 'react-i18next';


const ErrorPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <div className="error_page">
            <h2>{t('error404Heading')}</h2>
            <h3>{t('error404Line1')}</h3>
            <p>{t('error404Line2')}</p>
            <Link to="" onClick={() => navigate(-1)}>
                {t('error404BackButton')}
            </Link>
        </div>
    )
}

export default ErrorPage
// i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

export const supportedLngs = {
    en: "English",
    fr: "Français",
    ar: "العربية",
};

export const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
};
  
i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(Backend)
    .init({
        supportedLngs: Object.keys(supportedLngs),
        fallbackLng: supportedLngs && Object.keys(supportedLngs)[0],
        detection: {
            order: ["cookie", 'htmlTag', "localStorage", "path", "subdomain"],
            caches: ['cookie'],
            excludeCacheFor: ['cimode']
        },
        backend: {
            loadPath: '/locales/{{lng}}.json'
        }
    });
i18n.on('languageChanged', (lng) => { 
    document.body.dir = i18n.dir(lng);
    document.documentElement.lang = lng;
})
export default i18n;

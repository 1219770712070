import { Link } from 'react-router-dom';
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CloseIcon } from "../../../../Assets/Images/Icons/SvgIcons";
import './FlexBanner.scss'; // Corrected import statement for SCSS file

export function FlexBanner ({
  title,
  ctaLink,
  ctaTitle,
  isCenter,
  crossIconSize,
  animationTime,
  delayToShowBanner,
  daysToLive,
  wrapperStyle,
  mainStyleTitle,
  mainStyleLink,
  crossStyle,
  hidePermanentlyOnDate
}) {
  if (title === undefined) {
    throw new Error('title is required!!!');
  }
  if (ctaLink === undefined) {
    throw new Error('ctaLink is required');
  }

  const banner = useRef<HTMLDivElement>(null!)
  const [isVisible, setVisibility] = useState(false);
  const [isSlide, setSlide] = useState(false);


  const handleClick = () => {
    setSlide(true);
    setTimeout(() => {
       banner.current.remove();
    }, animationTime * 1000);
  };


  useEffect(() => {
    document.documentElement.style.setProperty('--timing', `${animationTime}s`);
    if (banner.current) {
      document.documentElement.style.setProperty(
        '--bannerHeight',
        `-${banner.current.offsetHeight}px`
      );
    }


    if (
         (!(hidePermanentlyOnDate instanceof Date) ||
          hidePermanentlyOnDate === undefined)
    ) {
      setTimeout(() => {
        setVisibility(true);
      }, delayToShowBanner * 1000);
    }

  }, [
    isVisible,
    daysToLive,
    animationTime,
    banner,
    delayToShowBanner,
    hidePermanentlyOnDate
  ]);


  if (isVisible) {
    return (
      <div
        style={{
          ...defaultWrapperStyle,
          ...wrapperStyle
        }}
        ref={banner}
        className={`${isSlide ? 'bannerSlideUp' : 'bannerSlideDown'}`} // Using string literals for class names
      >
        {ctaTitle && (
          <p
            style={{
              ...defaultMainStyleTitle,
              ...mainStyleTitle,
              marginLeft: `${isCenter && 'auto'}`,
              marginRight: `${isCenter && 'auto'}`,
              textAlign: "center"
            }}
          >
            {title}
            {ctaLink && (
              <Link to={ctaLink}>
                <a
                  style={{
                    ...defaultMainStyleLink,
                    ...mainStyleLink
                  }}
                >
                    {ctaTitle}
                </a>
              </Link>
            )}
          </p>
        )}
        {!ctaTitle && (
          <Link to={ctaLink}>
            <a
              //href={ctaLink}
              style={{
                ...defaultMainStyleTitle,
                ...mainStyleTitle,
                marginLeft: `${isCenter && 'auto'}`,
                marginRight: `${isCenter && 'auto'}`,
                textAlign: "center"
              }}
            >
              <span>{title}</span>
            </a>
          </Link>
        )}

        {crossIconSize !== 0 && (
          <div
            onClick={handleClick}
            style={{
              ...defaultCrossStyle,
              ...crossStyle,
              fontSize: `${crossIconSize}px`
            }}
          >
            <CloseIcon />
          </div>
        )}
      </div>
    );
  }
  return <React.Fragment></React.Fragment>;
}
FlexBanner.propTypes = {
  title: PropTypes.string.isRequired,
  ctaLink: PropTypes.string.isRequired,
  ctaTitle: PropTypes.string,
  isCenter: PropTypes.bool,
  crossIconSize: PropTypes.number,
  animationTime: PropTypes.number,
  delayToShowBanner: PropTypes.number,
  daysToLive: PropTypes.number,
  wrapperStyle: PropTypes.object,
  mainStyleTitle: PropTypes.object,
  mainStyleLink: PropTypes.object,
  crossStyle: PropTypes.object,
  hidePermanentlyOnDate: PropTypes.instanceOf(Date)
};

FlexBanner.defaultProps = {
  isCenter: true,
  crossIconSize: 22,
  animationTime: 1,
  delayToShowBanner: 2,
  daysToLive: 0
};

const defaultWrapperStyle = {
  padding: '4px',
  justifyContent: 'space-between',
  background: 'transparent',
  display: 'flex',
  backgroundColor: '#091050',
  paddingLeft: '24px',
  paddingRight: '24px'
};

const defaultMainStyleTitle = {
  marginTop: '4px',
  marginBottom: '4px',
  color: '#fff',
  margin: '10px'
};

const defaultMainStyleLink = {
  marginLeft: '5px',
  marginRight: '5px',
  color: '#3245F4'
};

const defaultCrossStyle = {
  marginTop: 'auto',
  marginBottom: 'auto',
  color: 'white',
  cursor: 'pointer'
};

export default FlexBanner;

import React, { useState } from "react";
import "./TradeSimple.scss";
import { Col, Row } from "react-bootstrap";
import DetailsCard from "../../../Common/Cards/DetailsCard/DetailsCard";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import RecentTrades from "../../../Common/Cards/RecentTrades/RecentTrades";
import SubHeader from "../../../Common/SubHeader/SubHeader";
import CommonCard from "../../../Common/Cards/CommonCard/CommonCard";
import Tooltip from "../../../Common/Tooltip/Tooltip";
import { useSelector } from "react-redux";
import { SettingsIcon } from "../../../../Assets/Images/Icons/SvgIcons";
import SettingModal from "../../../Common/Modals/SettingModal/SettingModal";
import { cryptoDecimals } from "../../../../Services/common.service";
import { useTranslation } from "react-i18next";

const TradeSimple = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { walletAddress }: { walletAddress: string } = useSelector(
    (state: any) => state?.user
  );
  const [show, setShow] = useState<boolean>(false);
  const navigationLinks = [
    {
      id: 1,
      label: t('tradeNavSimple'),
      to: "/auth/trade/simple/market",
    },
    {
      id: 2,
      label: t('tradeTabLimit'),
      key: "limit",
      to: "/auth/trade/simple/limit",
    },
    {
      id: 3,
      label: t('tradeTabStop'),
      key: "stop",
      
      to: "/auth/trade/simple/stop-orders",
    },
  ];
  window?.addEventListener("popstate", function (event) {
    navigate("/auth/dashboard/libfi");
  });

  let newDetailsCard: string = "ETH";
  if (
    location.pathname == "/auth/trade/simple/limit" ||
    location.pathname == "/auth/trade/simple/stop-orders"
  ) {
    newDetailsCard = "USDT";
  } else {
    newDetailsCard = "ETH";
  }

  return (
    <section className="TradeSimplePage">
      <div className="TradeSimplePageBanner">
        <Row>
          <Col md={4} xs={6}>
            <DetailsCard value={`${newDetailsCard}/LIBFI`} />
          </Col>
          <Col md={4} xs={6}>
            <DetailsCard value={`${newDetailsCard}/ARB`} type="darkBlue" />
          </Col>
          <Col md={4} xs={6}>
            <DetailsCard value={"LIBFI/ARB"} type="yellow" />
          </Col>
        </Row>
      </div>
      <div className="TradeSimplePageContent">
        <Row>
          <Col lg={5}>
            <CommonCard
              noHeaderSpacing
              cardTitle={t('tradeOrderForm')}
              walletNotConnected={walletAddress ? true : false}
            >
              <SubHeader navigationLinks={navigationLinks} />
              <Outlet />
              <button className="setting_btn" onClick={() => setShow(true)}>
                <SettingsIcon />
              </button>
            </CommonCard>
            <SettingModal show={show} handleClose={() => setShow(false)} />
          </Col>
          <Col lg={7}>
            <RecentTrades />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default TradeSimple;

import { useState, useMemo } from "react";
import "./LiquidityPoolGraph.scss";
import moment from 'moment';
import 'moment/min/locales';
import { useTheme } from "@mui/material/styles";
import { Theme } from "@mui/system";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Scatter,
} from "recharts";
import Box from "@mui/material/Box";
import PulsatingLastPoint from "./PulsatingLastPoint";
import { useTranslation } from "react-i18next";
import ButtonCustom from "../../Common/Button/ButtonCustom";
import LiquidityPoolGraphTooltip from "./LiquidityPoolGraphTooltip";

const LiquidityPoolGraph = (props: any) => {
  const [hoverValue,] = useState<any>();
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const [type, setType] = useState<string>("tvl");

  moment.locale(i18n.language);

  const getHoverColors = (
    color: string,
    hoverColor: string,
    hoverPoint?: any
  ): [string, string] => {
    return [color, hoverPoint !== undefined ? hoverColor : color];
  };
  const getColors = (
    theme: Theme,
    activePoint: any,
    hoverPoint?: any
  ): [string, string] => {
    return getHoverColors("#000", "transparent", hoverPoint);
  };

  const getXAxisConfig = (dataRange: any): ((v: string | number) => string) => {
    switch (dataRange) {
      case "DayData":
        return (value) => moment(Number(value)).format("ha");
      case "MonthData":
        return (value) => moment.unix(Number(value) / 1000).format("MMM Do");
      case "WeekData":
        return (value) => moment.unix(Number(value) / 1000).format("MMM Do");
      case "YearData":
        return (value) => moment.unix(Number(value) / 1000).format("MMM Do");
      default:
        throw new Error(t('graphDateRangeError'));
    }
  };

  const xAxisFormatter = useMemo(() => getXAxisConfig("MonthData"), []);

  let indexOfHover = 0;

  const isDataValid = (entry: any) => { 
    if (entry?.volume === undefined) return false;
    if (entry?.volumeChange === undefined) return false;
    if (entry?.tvl === undefined) return false;
    if (entry?.tvlChange === undefined) return false;
    if (entry?.timestamp === undefined) return false;
    if (entry?.pair0In1Out === undefined) return false;
    if (entry?.reserve0 === undefined) return false;
    if (entry?.reserve1 === undefined) return false;

    return true;
  }

  const data: any = useMemo(
    () =>
      props?.data?.filter(isDataValid)?.map((entry: any, index: number) => ({
        ...entry,
        // amount: entry?.liquidity,
        value: type === "volume" ? entry.volume : entry.tvl,
        valueChange: type === "volume" ? entry.volumeChange : entry.tvlChange,
        timestamp: entry.timestamp * 1000
        // beforeHover:
        //   index <= indexOfHover && entry?.liquidity,
        // afterHover:
        //   index >= indexOfHover && entry?.liquidity,
      })),
    [hoverValue, props?.data, type]
  );

  const lastPoint = data?.length ? data[data?.length - 1] : {};
  const activePoint = hoverValue ?? lastPoint;

  indexOfHover =
    data?.length && activePoint?.key
      ? data?.findIndex((v: any) => v.key === activePoint.key)
      : 0;
  const percentage = `${(100 * indexOfHover) / (data?.length - 1)}%`;

  const [lineColor, bodyColor] =
    data?.length && activePoint
      ? getColors(theme, activePoint, hoverValue)
      : ["#6cc8ef", "#6cc8ef"];

  return (
    <>
      <Box className="rtl-container">
        <>
          <div className="graph-btn-main">
            <ButtonCustom
              transparent={type === "volume"}
              title={t('earnModelTVL')}
              className={type === "volume" ? "" : "bordered-green"}
              onClick={() => { setType('tvl') }}
            />
            <ButtonCustom
              transparent={type !== "volume"}
              title={t('earnModelVolume')}
              className={type !== "volume" ? "" : "bordered-green"}
              onClick={() => { setType('volume') }}
            />
          </div>
          <ResponsiveContainer width="100%" height={props.height || 300}>
            {data && data.length > 0 ?
              <AreaChart
                width={700}
                height={250}
                data={data}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
              >
                <defs>
                  <linearGradient id="swapChartBody1" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor={"#5269eb"} stopOpacity={0.8} />
                    <stop offset="100%" stopColor={"#5269eb"} stopOpacity={0.0} />
                  </linearGradient>
                  <linearGradient id="swapChartBody2" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor={"#293994"} stopOpacity={0.5} />
                    <stop offset="100%" stopColor={"#293994"} stopOpacity={0.0} />
                  </linearGradient>
                  <linearGradient id="swapChartLine1" x1="0" y1="0" x2="1" y2="0">
                    <stop offset="0%" stopColor={lineColor} />
                    <stop offset={percentage} stopColor={lineColor} />
                    <stop offset={percentage} stopColor={"#0da2ff"} />
                    <stop offset="100%" stopColor={"#0da2ff"} />
                  </linearGradient>{" "}
                  <linearGradient id="swapChartLine2" x1="0" y1="0" x2="1" y2="0">
                    <stop offset="0%" stopColor={lineColor} />
                    <stop offset={percentage} stopColor={lineColor} />
                    <stop offset={percentage} stopColor={"#5167eb	"} />
                    <stop offset="100%" stopColor={"#5167eb	"} />
                  </linearGradient>
                </defs>
                <Tooltip
                  wrapperStyle={{ backgroundColor: "transparent" }}
                  cursor={{
                    strokeDasharray: 4,
                    strokeWidth: 1,
                    stroke: "#AFAFAF",
                  }}
                  active={true}
                  content={<LiquidityPoolGraphTooltip />}
                />
                <Area
                  type="monotone"
                  dataKey={"value"}
                  strokeWidth={2}
                  stroke="url(#swapChartLine1)"
                  fill="url(#swapChartBody1)"
                  activeDot={{
                    stroke: lineColor,
                    fill: lineColor,
                  }}
                />
                <Scatter
                  type="monotone"
                  dataKey={"value"}
                  stroke={lineColor}
                  isAnimationActive={true}
                  shape={<PulsatingLastPoint lastPoint={lastPoint} />}
                />
                <YAxis
                  type="number"
                  domain={[0, "auto"]}
                  dataKey={"value"}
                  allowDecimals={true}
                  orientation={document.body.dir === "rtl" ? "right" : "left"}
                />
                <XAxis
                  dataKey={"timestamp"}
                  tickCount={2}
                  tickSize={2}
                  tickFormatter={xAxisFormatter}
                  domain={["auto", "auto"]}
                  reversed={document.body.dir === "rtl"}
                />
              </AreaChart>
              :
              <div className="no-data">
                {t('earnModelGraphNoData')}
              </div>
            }
          </ResponsiveContainer>
        </>
      </Box>
    </>
  );
};

export default LiquidityPoolGraph;

import React, { useEffect, useMemo, useState } from "react";
import {
  CloseIcon,
  DownArrowCircle,
  QuestionMarkBlack,
  SettingsIcon,
  SwapIcon,
  WalletIcon,
  InfoIcon,
  InfiniteSpinner,
} from "../../../../Assets/Images/Icons/SvgIcons";


import { toast } from "react-hot-toast";
import ShariahIcon from '../../../../Assets/Images/Icons/shariah.png'
import "./EarnModal.scss";
import { Modal } from "react-bootstrap";
import Tooltip from "../../Tooltip/Tooltip";
import ButtonCustom from "../../Button/ButtonCustom";
import InputCustom from "../../FormInputs/Input/Input";
import { Link } from "react-router-dom";
import Slider from "rc-slider";
import {useFetchTokenBalance} from "../../../../hooks/usefetchTokenBalance";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { dynamicContractDetails } from "../../../../Services/dynamicContractDetails";
import {
  cryptoDecimals,
  divideWithDecimal,
  slicedValue,
  dynamicToDecimal,
  toCustomFixed,
  toFixed,
  formatNumberWithMagnitudeSuffix,
  validateInput, 
} from "../../../../Services/common.service";
import {
  getReservesHelper,
  getTotalReservesOfTokensInPool,
} from "../../../Pages/Private/EarnAMMPools/EarnAMMHelper";
import {
  convertUsingTokenDecimals,
  getLPBalance,
  getPairService,
} from "../../../../Services/contractCallService";
import { EXPLORER_LINK, FAILURE, hundred, SUCCESS } from "../../../../Utils";
import { localeStringFunction } from "../../../../Services/contractSendService";
import {
  executeRemoveLiquidity,
  getUserInformationForRemovingLiquidity,
} from "../../../Pages/Private/EarnAMMPools/RedeemAMMHelper";
import { addLiquidityHelperFunction } from "../../../Pages/Private/EarnAMMPools/EarnAMMHelper";
import { callContractGetMethod } from "../../../../Redux/Actions/contract.action";
import TransactionModal from "../TransactionDone/TransactionModal";
import SettingModal from "../SettingModal/SettingModal";
import DashboardGraph from "../../../Pages/graph/DashboardGraph";
import { checkUserConnectivity } from "../../../../Services/contract.service";
import {
  COMMON_DATA,
  ReserveHelper,
  TOKENS,
  TOKEN_DETAILS,
} from "../../../../interfaces/commonInterfaces";
import { callApiPostMethod } from "../../../../Redux/Actions/api.action";
import Shimmer from "../../Shimmer/Shimmer";
import {
  POOLS,
  PORTFOLIO_GRAPH,
} from "../../../../Redux/Actions/apiResponseInterfaces";
import { original } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import LiquidityPoolGraph from "../../../Pages/graph/LiquidityPoolGraph";

type EarnModal = {
  show: boolean;
  handleClose: Function;
  showTab: string;
  handlePools: Function;
  showSettingsModal: Function;
  selectedPool: POOLS["data"][1];
  poolData?: any;
};

const EarnModal = (props: EarnModal) => {

  // Redux dispatch function for actions.
  const dispatch: any = useDispatch();

  // Retrieving information from Redux store state.
  const poolsData = useSelector( (state: any) => state?.user.poolData );
  const { walletAddress, slippage, }: { walletAddress: string; slippage: number } = useSelector( (state: any) => state?.user );

  // State variables
  const [show, setShow] = useState<boolean>(false);
  const [showConnect, setShowConnect] = useState<boolean>(false);
  const [settingModal, setSettingModal] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<boolean>();
  const [loader, setLoader] = useState<boolean>(false);
  const pool: POOLS["data"][1] = props?.selectedPool;
  const [poolInformation, setPoolInformation] = useState<POOLS["data"][1]>(props?.selectedPool);
  const [graphData, setGraphData] = useState<PORTFOLIO_GRAPH["data"]>([]);
  const [withdrawValue, setWithdrawValue] = useState<any>(0);
  const [lpTokensToBurn, setlpTokensToBurn] = useState<any>(0);
  const [poolShare, setPoolShare] = useState(0)
  const [estimatedPoolShare, setEstimatedPoolShare] = useState(0)
  const [insufficientLp, setinsufficientLp] = useState<boolean>(false);
  const [totalVolumeLocked, setTotalVolumeLocked] = useState<string | 0>("");
  const [maxValueCheck, setmaxValueCheck] = useState<boolean>(false);
  const [inputOne, setinputOne] = useState<{ convertedValue: string; inputValue: string; }>({ convertedValue: "", inputValue: "", });
  const [inputTwo, setinputTwo] = useState<{ convertedValue: string; inputValue: string; }>({ convertedValue: "", inputValue: "", });
  const [minimumReceiveTokens, setminimumReceiveTokens] = useState<{ token1Receive: number; token1?: string; token2Receive: number; token2?: string; }>({ token1Receive: 0, token1: "", token2Receive: 0, token2: "", });
  const [userTotalAssetsInPool, setuserTotalAssetsInPool] = useState<{ tokenA: string; tokenB: string; }>({ tokenA: "", tokenB: "", });
  const [userLpBalance, setUserLpBalance] = useState<string | 0>("");
  const [totalReserves, setTotalReserves] = useState<{ reserve1: string; decimals1: number; reserve2: string; decimals2: number; }>({ reserve1: "0", decimals1: 0, reserve2: "0", decimals2: 0, });
  const [modalData, setModalData] = useState<{ heading: string; bodyText: string; status: string; txHash: string | null; }>({ heading: "", bodyText: "", status: "", txHash: "", });
  const correctedMinimumReceiveTokens = { token1Receive: minimumReceiveTokens.token2Receive, token2Receive: minimumReceiveTokens.token1Receive, };
  const [isLeftTokenInRationToken0Name, setIsLeftTokenInRationToken0Name] = useState(true);
  const [fetchingInputFields, setFetchingInputFields] = useState({ isLoadingInputField1: false, isLoadingInputField2: false, });
  const [fetchingUserTokensLiquidity, setFetchingUserTokensLiquidity] = useState(false);
  const [fetchingPoolInformation, setFetchingPoolInformation] = useState(true);
  const [fetchingUserLPLiquidity, setFetchingUserLPLiquidity] = useState(false);
  const [fetchingWithdrawTokensDetails, setFetchingWithdrawTokensDetails] = useState(false);
  const [fetchingWithdrawEstimatedPoolShare, setFetchingWithdrawEstimatedPoolShare] = useState(false);
  const [fetchingTotalReserves, setFetchingTotalReserves] = useState(false);
  const [fetchingTotalSupply, setFetchingTotalSupply] = useState(false);
  const [triggerAnimation, setTriggerAnimation] = useState(false);
  const [recentFieldInput, setRecentFieldInput] = useState("");
  const [swapBtnEffect, setSwapBtnEffect] = useState(false);
  const tokenABalance = userTotalAssetsInPool?.tokenB;
  const tokenBBalance = userTotalAssetsInPool?.tokenA;
  const { t } = useTranslation();

  /**
  * Computes the total reserves from two sources and then calculates the percentage contribution of each source to the total reserves 
  **/
  const total = parseFloat(totalReserves?.reserve1) + parseFloat(totalReserves?.reserve2);
  const percentageToken0 = total === 0 ? 0 : (parseFloat(totalReserves?.reserve1) / total) * 100;
  const percentageToken1 = total === 0 ? 0 : (parseFloat(totalReserves?.reserve2) / total) * 100;
  
  // Hooks

  /**
  * This is a memoized function to get details of both tokens of the selected pool
  **/
  const tokenDetails: TOKEN_DETAILS = useMemo(() => {
    const tokenOne: string = pool?.token0Address;
    const tokenTwo: string = pool?.token1Address;
    return {
      tokenOneAddress: tokenOne,
      tokenTwoAddress: tokenTwo,
      tokenOneName: pool?.token0Name,
      tokenTwoName: pool?.token1Name,
      isTokenOneNative: tokenOne == "0x22a4c090bd7744aa94be47fe368240de5c000920" ? true : false,
      isTokenTwoNative: tokenTwo == "0x22a4c090bd7744aa94be47fe368240de5c000920" ? true : false,
    };
  }, [pool]);
  
  /**
  * This is a custom hook fetching the balance from the user's wallet.
  **/
  const { tokenBalance, fetchData, fetchingBalance } = useFetchTokenBalance({
    dispatch,
    tokenDetails,
  });

  /**
  * This useEffect hook sets the latest information of the selected pool
  **/
  useEffect(() => {
    if (poolsData && props?.show) {
      const poolData = poolsData.find(entry => entry.token0Name === pool?.token0Name && entry.token1Name === pool?.token1Name);
  
      if (poolData) {
        setPoolInformation(poolData);
        setFetchingPoolInformation(false);
      }
    }
  }, [poolsData, pool]);

  /**
  * This useEffect hook allows to retrieve various data 
  **/
  useEffect(() => {
    if (tokenDetails?.tokenOneAddress !== undefined && tokenDetails?.tokenTwoAddress !== undefined) {
      getTotalReservesOfTokens();
      getTotalSupply(); 
      getGraphData(); 
      if (walletAddress) userTotalLiquidity();
    }
  }, [pool]);

  /**
  * This useEffect hook manages the effects when loading the data of the selected pool
  **/
  useEffect(() => {
    getTotalReservesOfTokens();
    getTotalSupply(); 
    if (walletAddress) userTotalLiquidity();
    fetchData();

    if ( recentFieldInput == "field1" && Number(inputOne.inputValue) !== 0 ) {
      handleInputs(inputOne.inputValue, "field1", false);

      setFetchingInputFields({
        isLoadingInputField1: false,
        isLoadingInputField2: true,
      });

    } else if ( recentFieldInput == "field2" && Number(inputTwo.inputValue) !== 0 ) {
      handleInputs(inputTwo.inputValue, "field2", false);

      setFetchingInputFields({
        isLoadingInputField1: true,
        isLoadingInputField2: false,
      });

    }
  }, [poolsData]);


  

  
  /**
  * This useEffect hook retrieves the amount of tokens locked by the user in the selected pool
  **/
  useEffect(() => {
    if (tokenDetails?.tokenOneAddress !== undefined && tokenDetails?.tokenTwoAddress !== undefined) {
      userTotalLiquidity();
    }
  }, [walletAddress]);

  /**
  * This useEffect hook computes the pool share and estimated pool share
  **/
  useEffect(() => {
    if (totalVolumeLocked) {
      setPoolShare(calculatePoolShare(totalVolumeLocked));
      setEstimatedPoolShare(calculateEstimatedPoolShare(totalVolumeLocked));
      setFetchingTotalSupply(false);
    }
  }, [totalVolumeLocked]);

  /**
  * This useEffect hook dynamically determines the amount of tokens to withdraw based on the withdrawal slider
  **/
  useEffect(() => {
    if (tokenDetails?.tokenOneAddress !== undefined && tokenDetails?.tokenTwoAddress !== undefined) {
      if (walletAddress) {
        estimateTokenChangeOnWithdrawal();
      }
    }
  }, [withdrawValue]);

  /**
  * This useEffect hook dynamically calculates the estimated pool share as the user adjusts the withdrawal slider
  **/
  useEffect(() => {
    if (totalVolumeLocked) {
      setEstimatedPoolShare(calculateEstimatedPoolShare(totalVolumeLocked))
    }
  }, [lpTokensToBurn]);

  /**
  * This useEffect hook enables tracking of the active modal tab
  **/
  useEffect(() => {
    setActiveTab(props?.showTab == "earn" ? true : false);
  }, [props?.show, props?.showTab]);


  // Functions

  /**
  * This function clears the input boxes
  **/
  const emptyValues = async () => {
    setinputOne({
      convertedValue: "",
      inputValue: "",
    });
    setinputTwo({
      convertedValue: "",
      inputValue: "",
    });
  };

  /**
  * This function sets the show state to true
  **/
  const handleOpen = () => setShow(true);

  /**
  * This function resets and fetches all values when user closes has successfully added or redeemed liquidity
  **/
  const handleClose = () => {
    setWithdrawValue(0);
    setuserTotalAssetsInPool({
      tokenA: "",
      tokenB: "",
    });
    setlpTokensToBurn(0);
    setminimumReceiveTokens({
      token1Receive: 0,
      token2Receive: 0,
    });
    getBackToOriginalState();
  };

  /**
  * This function resets and recalculates all values when a transaction occurs
  **/
  const getBackToOriginalState = async () => {
    setFetchingPoolInformation(true);
    emptyValues();
    fetchData();
    props?.handlePools(); // Refresh All The Pools from EarnAAMPools
    getTotalReservesOfTokens();
    getTotalSupply(); 
    getGraphData(); 
    userTotalLiquidity();
  };

  /**
  * This function computes the TVL of the selected pool
  **/
  const calculateTVL = (item) => {
    const tvl = (Number(item.reserve1) * Number(item.dollarValue0)) + (Number(item.reserve2) * Number(item.dollarValue1));
    return tvl;
  };

  /**
  * This function computes the estimated pool share of the user, displayed in the 'Redeem' tab
  */
  const calculateEstimatedPoolShare = (item) => {
    const estimatedPoolShare = Number(userLpBalance) * (1 - withdrawValue / 100 ) * 100 / Number(item);
    return estimatedPoolShare;
  };

  /**
  * This function computes the pool share of the user, displayed the 'Earn' tab
  **/
  const calculatePoolShare = (item) => {
    const poolShare = Number(userLpBalance) * 100 / Number(item);
    return poolShare;
  };

  /**
  * This function makes a graph showing the TVL of the selected pool for the last 30 days
  **/
  const getGraphData = async () => {
    setLoader(true);
    const result: PORTFOLIO_GRAPH = await dispatch(
      callApiPostMethod(
        "GET_GRAPH",
        //{ pairAddress: pool?.pairAddress },
        { pairAddress: pool?.pairAddress },
        false,
        false
      )
    );
    setGraphData(result?.data);
    setLoader(false);
  };

  /**
  * This function retrieves the total LP supply for the selected pool
  **/
  const getTotalSupply = async () => {

    setFetchingTotalSupply(true);

    const pairAddress: string = await getPairService({
      tokenOneAddress: tokenDetails?.tokenOneAddress,
      tokenTwoAddress: tokenDetails?.tokenTwoAddress,
      dispatch,
    });
    const totalSupply: string = await dispatch(
      callContractGetMethod("totalSupply", [], "pair", false, pairAddress)
    );
    setTotalVolumeLocked(totalSupply || 0);
  };

  /**
  * This function retrieves total reserves for the selected pool
  **/
  const getTotalReservesOfTokens = async () => {

    setFetchingTotalReserves(true);

    const data: COMMON_DATA = {
      tokenOneAddress: tokenDetails?.tokenOneAddress,
      tokenTwoAddress: tokenDetails?.tokenTwoAddress,
      dispatch,
    };

    let reserveA, reserveB;
    if (data.tokenOneAddress !== undefined && data.tokenTwoAddress !== undefined) {
      ({ reserveA, reserveB } = await getTotalReservesOfTokensInPool(data));
    }
    
    if (reserveA && reserveB) {
      const decimalsA: number = await dispatch(
        callContractGetMethod(
          "decimals",
          [],
          "dynamic",
          false,
          data.tokenOneAddress
        )
      );
      const decimalsB: number = await dispatch(
        callContractGetMethod(
          "decimals",
          [],
          "dynamic",
          false,
          data.tokenTwoAddress
        )
      );

      setTotalReserves({
        reserve1: reserveA.toString(),
        decimals1: decimalsA,
        reserve2: reserveB.toString(),
        decimals2: decimalsB,
      });
      
    } else {
      setTotalReserves({
        reserve1: "0",
        decimals1: 0,
        reserve2: "0",
        decimals2: 0,
      });
    }

    setFetchingTotalReserves(false);

  };

  /**
  * This function checks whether the user has a LP balance or not 
  **/
  const estimateTokenChangeOnWithdrawal = async () => {
    const info = await getLpInfo();

    let userLiquiditytoRemove: any =
      withdrawValue == 100 ? info?.userLiquidity
                           : slicedValue( localeStringFunction( (Number(info?.userLiquidity) * Number(withdrawValue)) / hundred ) );
  
    if (userLiquiditytoRemove == 0 && info?.userLiquidity == 0) {
      setinsufficientLp(true)
    }
    else {
      setinsufficientLp(false)
    }
  };

  /**
  * This function handles input fields when the user clicks on the 'max' button
  * @param data : Input field where the user has clicked the 'max' button
  **/
  const handleMaximumFunction = async (data: any) => {
    setmaxValueCheck(true);
  
    if (data == "field1" && tokenBalance?.token1BalanceConverted > 0) {
      if (tokenDetails?.isTokenOneNative) {

        // Deduct 0.001 as estimated gas fees for native currency
        const newBalance = toFixed(
          Number(tokenBalance?.token1BalanceConverted) - 10000000000000000
        ); 
        
        handleInputs(newBalance > 0 ? newBalance : 0, data, true);

      } else {

        handleInputs(tokenBalance?.token1BalanceConverted, data, true);

      }
    } else if (data == "field2" && tokenBalance?.token2BalanceConverted > 0) {
      if (tokenDetails?.isTokenTwoNative) {

        // Deduct 0.001 as estimated gas fees for native currency
        const newBalance = toFixed(
          Number(tokenBalance?.token2BalanceConverted) - 10000000000000000
        ); 

        handleInputs(newBalance > 0 ? newBalance : 0, data, true);

      } else {

        handleInputs(tokenBalance?.token2BalanceConverted, data, true);
        
      }
    } else {
    }
  };

  /**
  * This function handles both input fields
  * @param e : Value that the user has entered
  * @param field : Field in which user has entered the value
  * @param max : Boolean value indicating  whether user has clicked on the 'max' button or not
  **/
  const handleInputs = async (e: any, field: string, max: boolean) => {
    try {
      let values: any;
      values = validateInput(e, field, totalReserves?.decimals1.toString(), totalReserves?.decimals2.toString()) !== null ? validateInput(e, field, totalReserves?.decimals1.toString(), totalReserves?.decimals2.toString()) : null;

      let fieldCondition = field == "field1" ? true : false;

      if (!values && !values?.toString().includes(".")) {
        setmaxValueCheck(false);
        //setMaxDeposit(true);
        setinputOne({
          convertedValue: "",
          inputValue: "",
        });
        setinputTwo({
          convertedValue: "",
          inputValue: "",
        });
        setTimeout(() => {
          emptyValues();
        }, 100);
      } else {

        setRecentFieldInput(field);
        setSwapBtnEffect(true);
        
        const decimals = fieldCondition
          ? totalReserves?.decimals1
          : totalReserves?.decimals2;
        let inputValue = values;
        
        const decimalsNumber = Number(decimals);
        let convertedValue: any = max
          ? inputValue
          : (Number(inputValue) * 10 ** decimalsNumber).toLocaleString("fullwide", { useGrouping: !1 });       

        let originalValue: any = max
          ? cryptoDecimals(Number(inputValue) / 10 ** decimalsNumber).toLocaleString("fullwide", { useGrouping: false }) : inputValue;

        fieldCondition
          ? setinputOne({
              convertedValue: convertedValue,
              inputValue: originalValue,
            })
          : setinputTwo({
              convertedValue: convertedValue,
              inputValue: originalValue,
            });

        setFetchingInputFields(prevState => ({
          ...prevState,
          ...(fieldCondition ? { isLoadingInputField1: false } : { isLoadingInputField2: false })
        }));
        
        // Notify the user that a portion of the maximum token amount has been deducted for gas fees
        if (max && fieldCondition ? tokenDetails?.isTokenOneNative : tokenDetails?.isTokenTwoNative ) {
          toast.success((t('earnModelGasFeesDeduction')).replace('{{tokenName}}', tokenDetails?.isTokenOneNative ? tokenDetails?.tokenOneName+"" : tokenDetails?.tokenTwoName+"" ), {
              id: "GasFeesRemoval",
              duration: 3000,
          });
        }

        const data = {
          tokenOneAddress: tokenDetails?.tokenOneAddress,
          tokenTwoAddress: tokenDetails?.tokenTwoAddress,
          input1: fieldCondition ? inputValue : 0,
          input2: fieldCondition ? 0 : inputValue,
          selectedField: fieldCondition ? "field1" : "field2",
          max: max,
          dispatch,
        };

        const reserveData: any = await getReservesHelper(data);
        const calculatedBalance: string | number =
          await convertUsingTokenDecimals(
            fieldCondition ? data?.tokenTwoAddress : data?.tokenOneAddress,
            //fieldCondition ? data?.tokenOneAddress : data?.tokenTwoAddress, 
            reserveData,
            dispatch
          );

        if (Number(calculatedBalance) === 0) {
          fieldCondition
            ? setinputTwo({
                convertedValue: reserveData,
                inputValue: "0",
              })
            : setinputOne({
                convertedValue: reserveData,
                inputValue: "0",
              });
        } else if (Number(calculatedBalance)) {
          fieldCondition
            ? setinputTwo({
                convertedValue: reserveData,
                inputValue: dynamicToDecimal(calculatedBalance, 6),
              })
            : setinputOne({
                convertedValue: reserveData,
                inputValue: dynamicToDecimal(calculatedBalance, 6),
              });
        }

        setSwapBtnEffect(false);

        setFetchingInputFields(prevState => ({
          ...prevState,
          ...(!fieldCondition ? { isLoadingInputField1: false } : { isLoadingInputField2: false })
        }));

      }
    } catch (error) {
      console.error("Error in handleInputs:", error);
      console.error("Error handling input:", error);
    }
  };

  /**
  * This function fetches the user total liquidities (LP & Tokens balances) for the selected pool
  **/
  const userTotalLiquidity = async () => {

    setFetchingUserLPLiquidity(true);
    setFetchingUserTokensLiquidity(true);

    const pairAddress: string | undefined = await getPairService({
      tokenOneAddress: tokenDetails?.tokenOneAddress,
      tokenTwoAddress: tokenDetails?.tokenTwoAddress,
      dispatch,
    });

    const lpBalance: string | 0 = await getLPBalance({
      pairAddress,
      walletAddress,
      dispatch,
    });

    setUserLpBalance(walletAddress ? lpBalance : 0);

    setFetchingUserLPLiquidity(false);

    const { tokenBReceiveable, tokenAReceiveable } =
      await getUserInformationForRemovingLiquidity({
        tokenDetails,
        walletAddress,
        withdrawValue: 100,
        dispatch,
      });

    setuserTotalAssetsInPool({
      tokenA: tokenAReceiveable,
      tokenB: tokenBReceiveable,
    });

    setFetchingUserTokensLiquidity(false);

  };

  /**
  * This function retrieves information about the LP tokens of the user for the selected pool
  * @returns an object containing the necessary information for the removal of liquidity to happen
  **/
  const getLpInfo = async () => {
    const pairAddressExist: string = await getPairService({
      tokenOneAddress: tokenDetails?.tokenOneAddress,
      tokenTwoAddress: tokenDetails?.tokenTwoAddress,
      dispatch,
    });

    if (pairAddressExist != "0") {
      const {
        tokenAReceiveable,
        tokenBReceiveable,
        token1,
        token2,
        lpTokensToBurn,
        isNative,
        pairAddress,
        userLiquidity,
        customToken,
        nativeToken,
      } = await getUserInformationForRemovingLiquidity({
        tokenDetails,
        walletAddress,
        withdrawValue,
        dispatch,
      });

      setminimumReceiveTokens({
        token1Receive: tokenAReceiveable,
        token1: token1,
        token2Receive: tokenBReceiveable,
        token2: token2,
      });

      setlpTokensToBurn(lpTokensToBurn);
      const info = {
        isNative,
        pairAddress,
        userLiquidity,
        customToken,
        nativeToken,
      };

      setFetchingWithdrawTokensDetails(false);
      setFetchingWithdrawEstimatedPoolShare(false);

      return info;

    } else {
      const info = {
        isNative: false,
        pairAddress: 0,
        userLiquidity: 0,
        customToken: 0,
        nativeToken: 0,
      };

      setFetchingWithdrawTokensDetails(false);
      setFetchingWithdrawEstimatedPoolShare(false);

      return info;
    }

  };

  /**
  * This function manages the addition of liquidity from the selected pool
  **/
  const handleAddLiquidity = async () => {
    try {
      const isUserConnected = await checkUserConnectivity(dispatch);
      if (!isUserConnected) return;
      handleOpen();
      setModalData({
        heading: t('earnModelAddLiquidity'),
        bodyText: (t('earnModelAddLiquidityDescription'))
          .replace('{{token1Name}}', tokenDetails.tokenOneName || "")
          .replace('{{token2Name}}', tokenDetails.tokenTwoName || ""),
        status: "pending",
        txHash: null,
      });
      const liquidityResult: boolean | undefined =
        await addLiquidityHelperFunction(
          dispatch,
          tokenDetails,
          inputOne,
          inputTwo,
          setModalData,
          t
        );
      if (liquidityResult) {
        handleClose();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
  * This function manages the removal of liquidity from the selected pool
  **/
    const withdrawLiquidity = async () => {
      try {
        const isUserConnected = await checkUserConnectivity(dispatch);
        if (!isUserConnected) return;
        const info = await getLpInfo();
  
        let percentageRemoval: any = localeStringFunction( (Number(info?.userLiquidity) *  withdrawValue / hundred ).toLocaleString("fullwide", { useGrouping: !1, }) );
  
        let userLiquiditytoRemove: any = withdrawValue == 100 ? info?.userLiquidity
                                                              : slicedValue( percentageRemoval );
  
        if (userLiquiditytoRemove != 0) {
          handleOpen();
          setModalData({
            heading: t('earnModelRemoveLiquidity'),
            bodyText: (t('earnModelRemoveLiquidityDescription'))
              .replace('{{token1Name}}', props?.poolData?.token0Name || "")
              .replace('{{token2Name}}', props?.poolData?.token1Name || ""),
            status: "pending",
            txHash: null,
          });
  
          const tokenA = tokenDetails?.tokenOneName;
          const tokenB = tokenDetails?.tokenTwoName;
  
          if (tokenA && tokenB) {
            const response : string | undefined = await executeRemoveLiquidity({
              dispatch,
              withdrawValue,
              info,
              walletAddress,
              minimumReceiveTokens,
              tokenDetails,
              setModalData,
              userLiquiditytoRemove,
              tokenA,
              tokenB,
              t
            });
          
            if (response) {
              handleClose();
            } 
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    };

  /**
  * This function is called when user clicks on the 'Maximize Deposit' button
  **/
  const addMaxLiquidity = async () => {

    const isUserConnected = await checkUserConnectivity(dispatch);
    if (!isUserConnected) return;
    
    if (Number(tokenBalance?.token1Balance) * Number(poolInformation?.dollarValue0) <= Number(tokenBalance?.token2Balance) * Number(poolInformation?.dollarValue1) ) {
      handleMaximumFunction("field1");
    } else {
      handleMaximumFunction("field2");
    }

  };

  /**
  * This function is called when the user interacts with the slider in the 'Withdraw' tab
  * If the user has no LP token, the slider will be disabled and a toast will popup
  **/
  const SliderDisabledToast = () => {
    if (insufficientLp) toast.error(t('earnModelSliderDisabledError'), { id: "SliderDisabled" })
  };

  /**
  * This function is called when the user clicks on the 'double arrows' button of the token ratio
  * This toggles the state of the variable 'isLeftTokenInRationToken0Name'
  **/
  const handleClick = () => {
    setIsLeftTokenInRationToken0Name(prevValue => !prevValue);
  };

  /**
  * Checks whether the input fields are filled and correctly formatted
  * Returns a boolean value indicating the validity of the form.
  **/
    const isFormValid: boolean | undefined =
    inputOne?.inputValue !== "" &&
    inputTwo?.inputValue !== "" &&
    inputOne?.inputValue?.split(".")[1] !== "" &&
    inputTwo?.inputValue?.split(".")[1] !== "";

  /**
  * Checks if the input values exceed available balances
  * Returns a boolean indicating whether there are sufficient funds
  **/
  const insufficientFunds =
    Number(tokenBalance?.token1BalanceConverted) <
      Number(inputOne?.convertedValue) ||
    Number(tokenBalance?.token2BalanceConverted) <
      Number(inputTwo?.convertedValue) ||
    (maxValueCheck && Number(inputOne?.convertedValue) == 0) ||
    (maxValueCheck && Number(inputTwo?.convertedValue) == 0);

  
  /**
  * Checks if the user has enough native tokens to pay the estimated gas fees
  * Returns a boolean indicating whether there are sufficient funds for gas fees
  **/
  const insufficientGasFees = () => {
    if (
      (tokenDetails.isTokenOneNative && 
        Number(tokenBalance?.token1BalanceConverted) - Number(inputOne?.convertedValue) < 10000000000000000 &&
        Number(tokenBalance?.token1BalanceConverted) - Number(inputOne?.convertedValue) >= 0 &&
        Number(tokenBalance?.token2BalanceConverted) > Number(inputTwo?.convertedValue)) ||
      (tokenDetails.isTokenTwoNative && 
        Number(tokenBalance?.token2BalanceConverted) - Number(inputTwo?.convertedValue) < 10000000000000000 &&
        Number(tokenBalance?.token2BalanceConverted) - Number(inputTwo?.convertedValue) >= 0 &&
        Number(tokenBalance?.token1BalanceConverted) > Number(inputOne?.convertedValue))
    ) {
        return true;
    }
    return false;
  };

  return (
    /**
    * pool is used to display "constant" data like pairAddress, token0Address, token1Address, token0Name, token1Name, ... 
    * poolInformation is used to display "dynamic" data like totalLPAmount, dollarValue0, dollarValue1, ...
    **/
    <>
      <Modal
        backdropClassName="earnModalBg"
        centered
        show={props?.show}
        className="earn_modal"
      >
        <button
          className="modal_close_btn"
          onClick={() => {
            emptyValues();
            setWithdrawValue(0);
            props?.handleClose();
          }}
        >
          <CloseIcon />
        </button>
        <Modal.Body>
          <div className="earn_modal_data">
            <div className="d-flex">
              <ButtonCustom
                transparent={!activeTab}
                title={t('earnModelEarnButton')}
                className={activeTab ? "bordered-green" : ""}
                onClick={() => setActiveTab(true)}
              />
              <ButtonCustom
                transparent={activeTab}
                className={!activeTab ? "bordered-green" : ""}
                title={t('earnModelRedeemButton')}
                onClick={() => {
                  setActiveTab(false);
                }}
              />
              <button
                className="settings_btn"
                onClick={() => {
                  setSettingModal(true);
                }}
              >
                <SettingsIcon />
              </button>
              <SettingModal
                show={settingModal}
                handleClose={() => setSettingModal(false)}
              />
            </div>
            {activeTab ? (
              <>
                <div className="earn-details">
                  <div className="amount-details-container">
                    <div className="amount-details-box-container">
                        <InputCustom
                          // shimmer={shimmerState == "field2" ? true : false}
                          className={`${fetchingInputFields.isLoadingInputField1 ? 'greyedout' : ''}`}
                          placeholder={"0.00"}
                          name="value1"
                          leftIcon={
                            <>
                              <img src={pool?.firstTokenURI} alt="" />{" "}
                              {pool?.token0Name}
                            </>
                          }
                          type="text"
                          maxLength={18}
                          onChange={(e: any) => {
                            e.preventDefault();
                            handleInputs(e?.target?.value, "field1", false);

                            setFetchingInputFields({
                              isLoadingInputField1: false,
                              isLoadingInputField2: true,
                            });

                          }}
                          value={
                            !inputTwo?.inputValue ? "" : inputOne?.inputValue
                          }
                        />
                      <div className="amount-details-box-details">
                        {/* <p> */}
                        <p className={`${fetchingPoolInformation ? 'greyedout' : ''}`}>  
                          <span>$</span>
                          <span>{formatNumberWithMagnitudeSuffix(poolInformation?.dollarValue0 || 0)}</span>
                        </p>
                        <p>
                          {t('earnModelBalance')}:{" "}
                          {/* <span> */}
                          <span className={`${fetchingBalance ? 'text-shadow' : ''} ${ !fetchingBalance ? 'lineUp' : '' }`}>
                            {formatNumberWithMagnitudeSuffix(tokenBalance?.token1Balance)}
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                    <button className={`amount-details-box-swap-btn ${swapBtnEffect ? 'active' : ''}`}>{/* <PlusI /> */}+</button>
                    <div className="amount-details-box-container">
                      <InputCustom
                        // shimmer={shimmerState == "field1" ? true : false}
                        className={`${fetchingInputFields.isLoadingInputField2 ? 'greyedout' : ''}`}
                        placeholder={"0.00"}
                        name="value1"
                        leftIcon={
                          <>
                            <img src={pool?.secondTokenURI} alt="" />{" "}
                            {pool?.token1Name}
                          </>
                        }
                        type="text"
                        maxLength={18}
                        onChange={(e: any) => {
                          e.preventDefault();
                          handleInputs(e?.target?.value, "field2", false);

                          setFetchingInputFields({
                            isLoadingInputField1: true,
                            isLoadingInputField2: false,
                          });
                        }}
                        value={
                          !inputOne?.inputValue ? "" : inputTwo?.inputValue
                        }
                      />
                      <div className="amount-details-box-details">
                        {/* <p> */}
                        <p className={`${fetchingPoolInformation ? 'greyedout' : ''}`}>  
                          <span>$</span>
                          <span>{formatNumberWithMagnitudeSuffix(poolInformation?.dollarValue1)}</span>
                        </p>
                        <p>
                          {t('earnModelBalance')}:{" "}
                          {/* <span> */}
                          <span className={`${fetchingBalance ? 'text-shadow' : ''} ${ !fetchingBalance ? 'lineUp' : '' }`}>
                            {formatNumberWithMagnitudeSuffix(tokenBalance?.token2Balance)}
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  {walletAddress ? (
                    <ButtonCustom
                      title={t('earnModelMaximizeDeposit')}
                      fluid
                      onClick={() => {
                        addMaxLiquidity();
                      
                        setFetchingInputFields({
                          isLoadingInputField1: true,
                          isLoadingInputField2: true,
                        });
                      }}

                      disabled={ fetchingBalance || tokenBalance?.token1Balance == 0 || tokenBalance?.token2Balance == 0 }
                    />
                  ) : null}
                  <div className="user-assets-container">
                    <h4 className="user-assets-title">{t('earnModelYourPoolHolding')}</h4>
                    <table className="user-assets-table">
                      <tbody>
                        <tr>
                          <td className={`user-assets-table-column1 ${fetchingUserLPLiquidity ? 'greyedout' : ''}`}>
                            {/* <td className="user-assets-table-column1"> */}
                            <p>{(t('earnModelLPTokens')).replace('{{balance}}', formatNumberWithMagnitudeSuffix(`${divideWithDecimal(userLpBalance, 18)}`))}</p>
                          </td>
                          <td className="user-assets-table-column2">
                            <button className="user-assets-table-down-arrow-btn">
                              <DownArrowCircle />
                            </button>
                          </td>
                          <td>
                            
                          <div className="user-assets-table-tokens-breakdown">
                            <img src={pool?.firstTokenURI} alt="" />
                              {/* <p> */}
                              <p className={`${fetchingUserTokensLiquidity ? 'greyedout' : ''}`}>
                                {formatNumberWithMagnitudeSuffix(cryptoDecimals(tokenABalance))} {pool?.token0Name}
                              </p>
                          </div>
                          <div className="user-assets-table-tokens-breakdown">
                            <img src={pool?.secondTokenURI} alt="" />
                              {/* <p> */}
                              <p className={`${fetchingUserTokensLiquidity ? 'greyedout' : ''}`}>
                                {formatNumberWithMagnitudeSuffix(cryptoDecimals(tokenBBalance))} {pool?.token1Name}
                              </p>
                          </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div className="user-assets-pool-share">
                      <p>{t('earnModelPoolShare')}:</p>
                      {/* <p> */}
                      <p className={`${fetchingTotalSupply ? 'greyedout' : ''}`}>  
                        <span>{`${poolShare ? toCustomFixed(poolShare, 2) : "0.00"} `} </span>
                        <span>%</span>
                      </p>
                    </div>
                  </div>

                  {walletAddress ? (
                    <ButtonCustom
                      title={
                        //walletAddress && !insufficientFunds 
                        //  ? t('earnModelAddLiquidity')
                        //  : t('earnModelInsufficientFund')
                        walletAddress && !insufficientFunds && !insufficientGasFees()
                          ? t('earnModelAddLiquidity')
                          : insufficientGasFees()
                            ? (t('earnModelInsufficientGasFees')).replace('{{tokenName}}', tokenDetails.isTokenOneNative ? pool?.token0Name : pool?.token1Name ) 
                            : t('earnModelInsufficientFund')
                      }
                      fluid
                      onClick={() => handleAddLiquidity()}
                      disabled={!isFormValid || insufficientFunds || insufficientGasFees()}
                    />
                  ) : (
                    <ButtonCustom
                      fluid
                      title={
                        <>
                          <WalletIcon /> {t('connectWallet')}
                        </>
                      }
                      show={showConnect}
                      setShow={setShowConnect}
                      onClick={() => setShowConnect(true)}
                    />
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="redeem-details">
                  <h2>
                    <span>{withdrawValue}</span>
                    <span>%</span>
                  </h2>
                  {/* <p className="lp_text">
                    LP Tokens: {formatNumberWithMagnitudeSuffix(lpTokensToBurn)} / {formatNumberWithMagnitudeSuffix(divideWithDecimal(userLpBalance, 18))}
                  </p> */}

                  <div onClick={SliderDisabledToast}>
                    <Slider
                      max={100}
                      min={0}
                      // dots
                      step={1}
                      onChange={(value: any) => {
                        SliderDisabledToast();
                        setWithdrawValue(value);
                        setFetchingWithdrawTokensDetails(true);
                        setFetchingWithdrawEstimatedPoolShare(true);
                        }}
                      disabled={insufficientLp}
                      value={withdrawValue}
                      reverse={document.body.dir === 'rtl'}
                      dotStyle={{
                        width: "8px",
                        height: "8px",
                        border: "1px solid #4169EF",
                        backgroundColor: "#241F47",
                      }}
                      activeDotStyle={{
                        width: "15px",
                        height: "15px",
                        backgroundColor: " #4169EF",
                      }}
                      marks={document.body.dir === "rtl" ?
                        { 0: "%0", 25: "%25", 50: "%50", 75: "%75", 100: "%100" }
                        : { 0: "0%", 25: "25%", 50: "50%", 75: "75%", 100: "100%" }
                      }
                    />
                  </div>

                  <button className="redeem-down-arrow-btn">
                    <DownArrowCircle />
                  </button>
                  <div className="redeem-balance-container">
                    <table className="redeem-balance-table">
                      <tbody>
                        <tr>
                          <td></td>
                          <td><h4>{t('earnModelWithdrawing')}</h4></td>
                          <td><h4>{t('earnModelTotalHeld')}</h4></td>
                        </tr>
                        <tr>
                          <td>
                            <h4 className="token-data">
                              <img className="redeem-balance-table-token-image" src={pool?.firstTokenURI} alt="" />
                                <p className="redeem-balance-table-token">{pool?.token0Name}</p>
                                <sup>
                                  <Tooltip icon={<img className="redeem-balance-table-shariah-image" src={ShariahIcon} />}>
                                    <p>{t('earnModelApprovedBySharishBoard')}</p>
                                  </Tooltip>
                                </sup>
                            </h4>
                          </td>
                          <td>
                            {/* <p> */}
                            <p className={`${fetchingWithdrawTokensDetails ? 'greyedout' : ''}`}>
                              {formatNumberWithMagnitudeSuffix(cryptoDecimals(
                                correctedMinimumReceiveTokens?.token1Receive
                              )) || 0}
                            </p>
                          </td>
                          <td>
                            {/* <p> */}
                            <p className={`${fetchingUserTokensLiquidity ? 'greyedout' : ''}`}>
                              {formatNumberWithMagnitudeSuffix(cryptoDecimals(
                                tokenABalance)) || 0}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4 className="token-data">
                              <img className="redeem-balance-table-token-image" src={pool?.secondTokenURI} alt="" />
                              <p className="redeem-balance-table-token">{pool?.token1Name}</p>
                              {/*<Tooltip icon={<QuestionMarkBlack />} />*/}
                            </h4>
                          </td>
                          <td>
                            {/* <p> */}
                            <p className={`${fetchingWithdrawTokensDetails ? 'greyedout' : ''}`}>
                              {formatNumberWithMagnitudeSuffix(cryptoDecimals(
                                correctedMinimumReceiveTokens?.token2Receive
                              )) || 0}
                            </p>
                          </td>
                          <td>
                            {/* <p> */}
                            <p className={`${fetchingUserTokensLiquidity ? 'greyedout' : ''}`}>
                              {formatNumberWithMagnitudeSuffix(cryptoDecimals(
                                tokenBBalance)) || 0}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p className="hyphen-text"></p>
                  {/*
                    <ul className="slippage_text">
                        <li>
                          <h4>Slippage Tolerance</h4>
                          <p>{slippage} %</p>
                        </li>``
                      <li>
                        <h4>Trading Fee</h4>
                        <p>-</p>
                      </li>
                    </ul>
                  */}
                  <div className="user-assets-pool-share">
                      <p>{t('earnModelEstPoolShare')}:</p>
                      {/* <p> */}
                      <p className={`${fetchingTotalSupply || fetchingWithdrawEstimatedPoolShare ? 'greyedout' : ''}`}>  
                        <span>{`${estimatedPoolShare ? toCustomFixed(estimatedPoolShare, 2) : "0.00"} `} </span>
                        <span>%</span>
                      </p>
                  </div>
                  {walletAddress ? (
                    <ButtonCustom
                      title={
                        !insufficientLp ? t('earnModelWithdraw') : t('earnModelInsufficientLPTokens')
                      }
                      className="wallet_btn"
                      fluid
                      onClick={() => withdrawLiquidity()}
                      disabled={
                        !withdrawValue || !walletAddress || insufficientLp
                      }
                    />
                  ) : (
                    <ButtonCustom
                      title={
                        <>
                              <WalletIcon /> {t('connectWallet')}
                        </>
                      }
                      className="wallet_btn"
                      show={showConnect}
                      fluid
                      setShow={setShowConnect}
                      onClick={() => setShowConnect(true)}
                    />
                  )}
                </div>
              </>
            )}
          </div>
          {show ? (
            <TransactionModal
              show={show}
              modalData={modalData}
              //handleClose={handleClose}
              handleClose={() => setShow(false)}
              handleFunction={
                activeTab ? handleAddLiquidity : withdrawLiquidity
              }
            />
          ) : null}
            <>
              <div className="modal-earn-chart">
                <Link
                  to={`${EXPLORER_LINK}address/${pool?.pairAddress}`}
                  target="blank"
                  className="view_link"
                >
                  {t('earnModelViewPoolTrx')}
                </Link>
                <div className="chart_img">
                  {/* <img src={Graphimg} alt="" /> */}
                  {loader ? (
                    // <Shimmer height={272} fluid />
                    <InfiniteSpinner />
                  ) : (
                    <LiquidityPoolGraph data={graphData} />
                  )}
                </div>
                <div className="pool-information-container">
                  <h3 className="pool-information-title">{t('earnModelPoolInformation')}</h3>

                  <div className="pool-information-current-price-container">
                    <div className="pool-information-current-price-title">
                      <p>{t('earnModelCurrentPrice')}</p>
                    </div>
                    {/* <div className="pool-information-current-price"> */}
                    <div className={`pool-information-current-price ${fetchingTotalReserves ? 'greyedout' : ''}`}>
                        {isLeftTokenInRationToken0Name ? (
                          <p>
                            {totalReserves ? (
                            <>
                              <span>1</span>
                              <span>{pool?.token0Name}</span>
                              <span>=</span>
                              <span>{formatNumberWithMagnitudeSuffix(Number(totalReserves?.reserve2) / Number(totalReserves?.reserve1))}</span>
                              <span>{pool?.token1Name}</span>
                            </>
                            ) : (
                                  "N/A"
                            )}
                          </p>
                        ) : (
                          <p>
                            {totalReserves ? (
                            <>
                                <span>1</span>
                                <span>{pool?.token1Name}</span>
                                <span>=</span>
                                <span>{formatNumberWithMagnitudeSuffix(Number(totalReserves?.reserve1) / Number(totalReserves?.reserve2))}</span>
                                <span>{pool?.token0Name}</span>
                            </>
                              ) : (
                                "N/A"
                            )}
                          </p>
                          )}
                        <button className="swapBtn" onClick={handleClick}>
                            <SwapIcon />
                        </button>
                    </div>
                  </div>

                  <div className="pool-information-trading-fee-container">
                    <div className="pool-information-trading-fee-title">
                      <p>{t('earnModelTradingFee')}</p>
                      <Tooltip icon={<InfoIcon />}>
                        <p>{t('earnModelTradingFeeDescription')}</p>
                     </Tooltip>
                    </div>
                    <div className="pool-information-trading-fee">
                      <p>
                        <span>0.25</span>
                        <span>%</span>
                      </p>
                    </div>
                  </div>

                  <div className="pool-information-table-container">     
                    <div className="pool-information-Token-TVL-container">
                      <div className="pool-information-Token-TVL-row">
                        <div className="pool-information-Token-TVL-row-Token">
                          {/* <span className="green_text">+0.00%</span> */}
                          <div className="circleimg">
                            <img src={pool.firstTokenURI} alt="" />{" "}
                            {/* <h3> */}
                            <h3 className={`${fetchingTotalReserves ? 'greyedout' : ''}`}>
                              <span>{formatNumberWithMagnitudeSuffix( totalReserves?.reserve1, )}</span>
                              <span>{pool?.token0Name}</span>
                              <span>({percentageToken0.toFixed(2)}</span>
                              <span>%)</span>
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="pool-information-Token-TVL-row">
                        <div className="pool-information-Token-TVL-row-TVL-Volume">
                          <h3 className="faded">{t('earnModelTVL')}</h3>
                          {/* <p> */}
                          <p className={`${fetchingPoolInformation ? 'greyedout' : ''}`}> 
                            <span>$</span>
                            <span>{formatNumberWithMagnitudeSuffix(calculateTVL(poolInformation))}</span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="pool-information-Token-TVL-container">
                      <div className="pool-information-Token-TVL-row">
                        <div className="pool-information-Token-TVL-row-Token">
                          {/* <span className="green_text">+0.00%</span> */}
                          <div className="circleimg">
                            <img src={pool?.secondTokenURI} alt="" />{" "}
                              {/* <h3> */}
                              <h3 className={`${fetchingTotalReserves ? 'greyedout' : ''}`}>
                              <span>{formatNumberWithMagnitudeSuffix( totalReserves?.reserve2, )}</span>
                              <span>{pool?.token1Name}</span>
                              <span>({toCustomFixed(percentageToken1, 2)}</span>
                              <span>%)</span>
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="pool-information-Token-TVL-row">
                        <div className="pool-information-Token-TVL-row-TVL-Volume">
                          <h3 className="faded">{t('earnModelVolume24H')}</h3>
                          {/* <p> */}
                          <p className={`${fetchingPoolInformation ? 'greyedout' : ''}`}> 
                            {poolInformation?.volumeInUSD ? (
                              <>
                                <span>{"$ "}</span>
                                <span>{formatNumberWithMagnitudeSuffix(poolInformation?.volumeInUSD)}</span>
                                </>
                            ) : (
                              "0.00"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>  

                </div>
              </div>
            </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EarnModal;

import React, { useEffect, useMemo, useState } from "react";
import TokenSelect from "../../FormInputs/TokenSelect/TokenSelect";
import "./Limit.scss";
import {
  InfoIcon,
  SwapIcon,
  WalletIcon,
} from "../../../../Assets/Images/Icons/SvgIcons";
import CustomSelect from "../../FormInputs/Select/Select";
import ButtonCustom from "../../Button/ButtonCustom";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { connectToWallet } from "../../../../Redux/Actions/user.action";
import {
  setOrderExpiredDays,
  settokenOne,
  settokenTwo,
} from "../../../../Redux/Slices/tokenSlice";
import { convertUsingTokenDecimals } from "../../../../Services/contractCallService";
import { getReservesHelper } from "../../../Pages/Private/EarnAMMPools/EarnAMMHelper";
import {
  cryptoDecimals,
  multiplyBigDigitsWithDecimals,
  tofixFunctionSliced,
} from "../../../../Services/common.service";
import {useFetchTokenBalance} from "../../../../hooks/usefetchTokenBalance";
import {
  dynamicContractDetails,
  tokenCollection,
} from "../../../../Services/dynamicContractDetails";
import { performLimitOrderOrStopOrderExecution } from "./LimitOrderHelper";
import TransactionModal from "../../Modals/TransactionDone/TransactionModal";
import { callApiPostMethod } from "../../../../Redux/Actions/api.action";
import {
  fetchRecentTrade,
  setOrderPlaced,
} from "../../../../Redux/Slices/user.slice";
import Tooltip from "../../Tooltip/Tooltip";
import {
  ReserveHelper,
  TOKENS,
  TOKEN_DETAILS,
} from "../../../../interfaces/commonInterfaces";
import { DOLLAR_VAL } from "../../../../Redux/Actions/apiResponseInterfaces";
import { useTranslation } from "react-i18next";

const Limit = () => {
  const dispatch: any = useDispatch();
  const { t } = useTranslation();
  const userwalletAddress: string = useSelector(
    (state: any) => state?.user?.walletAddress
  );
  const tokenInfo: TOKENS[] = useSelector(
    (state: any) => state?.tokens?.tokenList
  );
  const {
    tokenOne,
    tokenTwo,
    timeList,
  }: {
    tokenOne: any;
    tokenTwo: any;
    timeList: any;
  } = useSelector((state: any) => state?.tokens);

  const userDropdownObject: any = useSelector(
    (state: any) => state?.tokens?.orderExpiredDays
  );

  const [LimitPrice, setLimitPrice] = useState<number>(0);
  const [show, setShow] = useState<boolean>(false);
  const [tokenADollarValue, setTokenADollarValue] = useState<string>("");
  const [tokenBDollarValue, setTokenBDollarValue] = useState<string>("");
  const [userValidLimitPrice, setuserValidLimitPrice] = useState<any>("");
  const [LimitPriceCheck, setLimitPriceCheck] = useState<boolean>(false);
  const [ifUsertypedAmount, setifUsertypedAmount] = useState<any>(false);
  const [inputOne, setinputOne] = useState<{
    convertedValue: any;
    inputValue: any;
  }>({
    convertedValue: 0,
    inputValue: 0,
  });
  const [defaultSelectedTokens, setDefaultSelectedTokens] = useState<{
    token1: TOKENS | null;
    token2: TOKENS | null;
  }>({
    token1: null,
    token2: null,
  });
  const [tokensList, setTokensList] = useState<any>([]);

  const [inputTwo, setinputTwo] = useState<any>({
    convertedValue: "",
    inputValue: "",
  });
  const [selectedOption, setSelectedOption] = useState<any>({
    optionOne: "",
    optionTwo: "",
  });

  const [modalData, setModalData] = useState<any>({
    heading: "",
    bodyText: "",
    status: "",
    txHash: "",
  });

  useEffect(() => {
    tokenOptionsFunction();
    // dispatch(setShowLoader(false));
  }, [tokenInfo, tokenOne, tokenTwo]);

  /**
   * function to reset and fetch all values, that will be called when user closes the transaction wait modal
   */
  const handleClose = () => {
    setShow(false);
    emptyValues();
  };

  const fetchDollarValue = async () => {
    const result: DOLLAR_VAL | undefined = await dispatch(
      callApiPostMethod("DOLLAR_VALUE", {}, false, false)
    );

    await result?.data?.map((value: any) => {
      if (
        tokenOne?.address?.toLowerCase() == value?.assetAddress.toLowerCase()
      ) {
        setTokenADollarValue(value?.price);
      } else if (
        tokenTwo?.address?.toLowerCase() == value?.assetAddress.toLowerCase()
      ) {
        setTokenBDollarValue(value?.price);
      }
    });
  };

  /**
   * memoized function to get details of both tokens
   */

  useEffect(() => {
    setLimitTokens();
    fetchDollarValue();
  }, [tokenOne, tokenTwo]);

  const setLimitTokens = async () => {
    if (tokenOne?.symbol == "ETH") {
      const newTokenOne: TOKENS | undefined = tokenCollection.find(
        (item: any) => {
          return (
            item.symbol != tokenTwo.symbol && item.symbol != tokenOne.symbol
          );
        }
      );
      dispatch(settokenOne(newTokenOne));
    } else if (tokenTwo?.symbol == "ETH") {
      const newTokenTwo: TOKENS | undefined = tokenCollection.find(
        (item: any) => {
          return (
            item.symbol != tokenOne.symbol && item.symbol != tokenTwo.symbol
          );
        }
      );
      dispatch(settokenTwo(newTokenTwo));
    }
  };
  const tokenDetails: TOKEN_DETAILS | undefined = useMemo(() => {
    const token1: string = tokenOne?.address;
    const token2: string = tokenTwo?.address;
    const tokenData: TOKENS | undefined = dynamicContractDetails.find(
      (a) => a.symbol == "WETH"
    );
    return {
      tokenOneAddress: token1,
      tokenTwoAddress: token2,
      tokenOneName: tokenOne?.symbol,
      tokenTwoName: tokenTwo?.symbol,
      isTokenOneNative: tokenData?.address == token1 ? true : false,
      isTokenTwoNative: tokenData?.address == token2 ? true : false,
    };
  }, [tokenOne, tokenTwo]);

  /**
   * custom hook to fetch token Balance of the user
   */
  const { tokenBalance, fetchData } = useFetchTokenBalance({
    dispatch,
    tokenDetails,
  });

  useEffect(() => {
    if (inputOne?.inputValue == "") getReservesFirstTime();
  }, [inputOne]);

  const getReservesFirstTime = async () => {
    const data: ReserveHelper = {
      tokenOneAddress: tokenOne?.address,
      tokenTwoAddress: tokenTwo?.address,
      input1: 1,
      input2: 0,
      selectedField: "field1",
      dispatch,
    };
    const reserveData: string | undefined = await getReservesHelper(data);
    const calculatedBalance: string | 0 = await convertUsingTokenDecimals(
      data?.tokenTwoAddress,
      reserveData,
      dispatch
    );
    setuserValidLimitPrice(calculatedBalance);
  };

  /**
   * memoized function to get details of selected tokens by the user
   */
  const tokensFullInfo: TOKENS[] = useMemo(() => {
    return tokensList?.map((data: any) => {
      if (
        data?.address?.toLowerCase() == tokenOne?.address?.toLowerCase() ||
        data?.address?.toLowerCase() == tokenTwo?.address?.toLowerCase()
      ) {
        data.isSelected = true;
      } else {
        data.isSelected = false;
      }
      return data;
    });
  }, [tokensList]);

  /**
   * function to update when user selects a token
   * @param item object containing information about the selected token by the user
   * @param selectedField field in which user has selected the token
   */
  const SelectTokens = async (item: TOKENS, selectedField: string) => {
    emptyValues();
    if (selectedField == "field1") {
      setSelectedOption({
        optionOne: item,
        optionTwo: selectedOption?.optionTwo,
      });
      dispatch(settokenOne(item));
    } else if (selectedField == "field2") {
      setSelectedOption({
        optionOne: selectedOption?.optionOne,
        optionTwo: item,
      });
      dispatch(settokenTwo(item));
    }
  };

  /**
   * function to manipulate the list of tokens
   */
  const tokenOptionsFunction = async () => {
    const tokenList: TOKENS[] = JSON.parse(JSON.stringify(tokenInfo));
    const filteredList: TOKENS[] = tokenList.filter((item: any) => {
      return item?.symbol != "ETH";
    });
    const result: any = filteredList?.map((data: any) => {
      data.label = (
        <div className="token_option">
          <img src={data?.img} alt="token-icon" /> {data?.symbol}
        </div>
      );
      return data;
    });

    setDefaultSelectedTokens({
      token1: result?.find((data: any) => data?.symbol == tokenOne?.symbol),
      token2: result?.find((data: any) => data?.symbol == tokenTwo?.symbol),
    });
    setTokensList(result);
  };

  /**
   * function to handle dispplaying of tokens and its calculation when user clicks on switch button
   */
  const handleSwitch = async () => {
    // dispatch(setgraphtokenInterchangeState(!graphData));
    const tokenList: TOKENS[] = JSON.parse(JSON.stringify(tokenInfo));
    const result: TOKENS[] = tokenList?.map((data: any) => {
      data.label = (
        <>
          <img src={data?.img} alt="token-icon" /> {data?.symbol}
        </>
      );
      return data;
    });
    const token1: TOKENS | undefined = result?.find(
      (data: any) => data?.symbol == tokenOne?.symbol
    );
    const token2: TOKENS | undefined = result?.find(
      (data: any) => data?.symbol == tokenTwo?.symbol
    );

    dispatch(settokenOne(token2));
    dispatch(settokenTwo(token1));

    setinputOne({
      convertedValue: inputTwo?.convertedValue,
      inputValue: inputTwo?.inputValue,
    });
    const reservesData: ReserveHelper = {
      tokenOneAddress: tokenOne?.address,
      tokenTwoAddress: tokenTwo?.address,
      input1: 0,
      input2: inputTwo?.inputValue,
      selectedField: "field2",
      max: false,
      dispatch,
    };
    const reservesResult: string | undefined = await getReservesHelper(
      reservesData
    );
    const calculatedBalance: string | 0 = await convertUsingTokenDecimals(
      tokenOne?.address,
      reservesResult,
      dispatch
    );
    if (Number(calculatedBalance)) {
      setinputTwo({
        convertedValue: reservesResult,
        inputValue: calculatedBalance,
      });
    }
    setLimitPrice(
      tofixFunctionSliced(
        Number(reservesResult ?? 0) /
          Number(inputTwo?.inputValue * 10 ** tokenTwo?.decimals)
      ) || 0
    );
  };

  /**
   * function to handle both inputs fields
   * @param e value that the user has entered
   * @param field field in which user has entered
   */
  const handleChange = async (e: any, field: string) => {
    let values: any;
    const maxLength: number = 26;
    const regexHere = /^(\d+)?([.]?\d{0,6})?$/;
    const isInputValid: boolean = regexHere.test(e);
    if (isInputValid) {
      values = e;
    } else {
      return;
    }
    if (e?.length <= maxLength) {
      values = e;
    } else if (e?.length > maxLength) {
      return;
    }

    if (
      values?.length > 1 &&
      Array.from(values)[0] == "0" &&
      Array.from(values)[1] != "."
    ) {
      values = values?.slice(1);
    }

    if (values?.toString().charAt(0) == ".") {
      values = "0" + values;
    }

    // let decimals = 18;
    // if (values?.toString()?.length > 8) return;
    setifUsertypedAmount(true);
    let fieldCondition: boolean = field == "field1" ? true : false;
    if (!values && !values?.toString()?.includes(".") && fieldCondition) {
      setinputOne({
        convertedValue: "",
        inputValue: "",
      });
      setinputTwo({
        convertedValue: "",
        inputValue: "",
      });
      // setTimeout(() => {
      //   emptyValues(1);
      // }, 100);
    } else {
      let inputValue = values;
      const tokenInfo: any = fieldCondition ? tokenOne : tokenTwo;
      let inputSetter: React.Dispatch<
        React.SetStateAction<{
          convertedValue: string | number;
          inputValue: string | number;
        }>
      > = fieldCondition ? setinputOne : setinputTwo;
      inputSetter({
        convertedValue: inputValue * 10 ** tokenInfo?.decimals,
        inputValue: inputValue,
      });
      if (fieldCondition) {
        const reservesData: ReserveHelper = {
          tokenOneAddress: tokenOne?.address,
          tokenTwoAddress: tokenTwo?.address,
          input1: inputValue,
          input2: 0,
          selectedField: "field1",
          max: false,
          dispatch,
        };
        const reserveData: string | undefined = await getReservesHelper(
          reservesData
        );
        const calculatedBalance: string | 0 = await convertUsingTokenDecimals(
          reservesData?.tokenTwoAddress,
          reserveData,
          dispatch
        );
        if (Number(calculatedBalance) > 0) {
          setinputTwo({
            convertedValue: reserveData,
            inputValue: calculatedBalance,
          });
          setLimitPrice(
            tofixFunctionSliced(
              Number(reserveData ?? 0) /
                Number(inputValue * 10 ** tokenTwo?.decimals)
            ) || 0
          );
        }
        setLimitPriceCheck(false);
      }
      // else {
      //   setLimitPrice(
      //     tofixFunctionSliced(
      //       Number(inputValue * 10 ** tokenTwo?.decimals ?? 0) /
      //         Number(inputOne.convertedValue)
      //     ) || 0
      //   );
      // }
    }
  };

  /**
   * function to handle input field of limit rate
   * @param e value entered in the field
   */
  const handleLimitChange = async (e) => {
    let values: any;
    const maxLength: number = 26;
    const regexHere = /^(\d+)?([.]?\d{0,6})?$/;
    const isInputValid = regexHere.test(e);
    if (isInputValid) {
      values = e;
    } else {
      return;
    }
    if (e?.length <= maxLength) {
      values = e;
    } else if (e?.length > maxLength) {
      return;
    }

    if (
      values?.length > 1 &&
      Array.from(values)[0] == "0" &&
      Array.from(values)[1] != "."
    ) {
      values = values?.slice(1);
    }

    if (values?.toString().charAt(0) == ".") {
      values = "0" + values;
    }
    setifUsertypedAmount(false);
    if (!values && !values?.toString()?.includes(".")) {
      emptyValues(1);
    } else {
      setuserValidLimitPrice(values);
      if (Number(e) >= LimitPrice) {
        setinputTwo({
          convertedValue: multiplyBigDigitsWithDecimals(
            e,
            tokenTwo?.symbol == "USDT"
              ? (inputOne?.inputValue * 10 ** 6).toString()
              : tokenOne?.symbol == "USDT"
              ? (inputOne?.inputValue * 10 ** 18).toString()
              : inputOne?.convertedValue.toString()
          ),
          inputValue: multiplyBigDigitsWithDecimals(
            e,
            inputOne?.inputValue?.toString()
          ),
        });
        // console.log("")
        setLimitPriceCheck(false);
      } else {
        setinputTwo({
          convertedValue: multiplyBigDigitsWithDecimals(
            e,
            inputOne?.convertedValue.toString()
          ),
          inputValue: multiplyBigDigitsWithDecimals(
            e,
            inputOne?.inputValue?.toString()
          ),
        });
        setLimitPriceCheck(true);
      }
    }
  };

  /**
   * function to handle time value when user selects on the time duration list
   * @param data object containing info about the selected time
   */
  const handleTimeSelect = (data: any) => {
    dispatch(setOrderExpiredDays(data));
  };

  const handleOpen = () => setShow(true);

  /**
   * function to perform limit order execution
   */
  const executeLimitOrder = async () => {
    const currentTime: number = Math.floor(Date.now() / 1000);
    if (
      Number(tokenBalance?.token1BalanceConverted) <
      Number(inputOne?.convertedValue)
    ) {
      return;
    } else {
      handleOpen();
      const result: boolean | undefined =
        await performLimitOrderOrStopOrderExecution(
          tokenDetails,
          inputOne,
          inputTwo,
          userValidLimitPrice || LimitPrice,
          Number(currentTime) + Number(userDropdownObject?.convertedTime),
          dispatch,
          setModalData,
          "limit",
          t
        );
      if (result) {
        fetchData();
        dispatch(setOrderPlaced(true));
      } else {
        dispatch(setOrderPlaced(false));
      }
    }
  };

  /**
   * function to empty input fields
   * @param type value to check if only limit value should be emptied or not
   */

  const emptyValues = async (type?: number) => {
    setuserValidLimitPrice("");
    if (!type) {
      setinputOne({
        convertedValue: "",
        inputValue: "",
      });
    }
    setinputTwo({
      convertedValue: "",
      inputValue: "",
    });
  };
  const isFormValid: boolean =
    inputOne?.inputValue !== "" &&
    inputTwo?.inputValue !== "" &&
    inputOne?.inputValue?.split(".")[1] !== "" &&
    inputTwo?.inputValue?.split(".")[1] !== "";

  const insuuficientBalance: string | boolean =
    Number(tokenBalance?.token1BalanceConverted) <
      Number(inputOne?.convertedValue) && userwalletAddress;

  return (
    <div className="LimitCard" style={{ pointerEvents: 'none' }}>
      <div className='coming_soon'  style={{ top: '50px', pointerEvents: 'none' }}>
            {/* <ClockIcon /> */}
            <h2>{t('comingSoon')}</h2>
        </div>
      <div className="LimitCardBox" >
        <div>
          <label htmlFor="sell">{t('tradeLimitSell')}</label>
          <input
            className="amountInput"
            type="text"
            id="sell"
            placeholder="0.0"
            value={inputOne?.inputValue}
            onChange={(e) => {
              e.preventDefault();
              handleChange(e?.target?.value, "field1");
            }}
            autoComplete="off"
            maxLength={8}
            disabled
          />
          <p className="LimitCardBoxTxt">
            <span>{"$ "}</span>
            <span>{cryptoDecimals(tokenADollarValue || 0)}</span>
          </p>
        </div>
        <div className="text-end">
          <p className="LimitCardBoxTxt">
          {t('tradeLimitBalance')}:{" "}
            <span>
              {userwalletAddress
                ? cryptoDecimals(tokenBalance?.token1Balance)
                : 0}
            </span>
          </p>
          <TokenSelect
            className="ms-auto"
            options={tokensFullInfo}
            dropIcon
            defaultValue={defaultSelectedTokens?.token1}
            selectedField="field1"
            selectedOption={selectedOption?.optionOne}
            SelectTokens={SelectTokens}
          />
        </div>
      </div>
      <button className="swapBtn" onClick={() => handleSwitch()}>
        <SwapIcon />
      </button>
      <div className="LimitCardBox">
        <div>
          <label htmlFor="buy">{t('tradeLimitBuy')}</label>
          <input
            className="amountInput"
            type="text"
            id="buy"
            value={inputTwo?.inputValue}
            disabled={true}
            placeholder="0.0"
            // onChange={(e) => {
            //   e.preventDefault();
            //   handleChange(e?.target?.value, "field2");
            // }}
            autoComplete="off"
            // maxLength={8}
          />
          <p className="LimitCardBoxTxt">
            <span>{"$ "}</span>
            <span>{cryptoDecimals(tokenBDollarValue || 0)}</span>
          </p>
        </div>
        <div className="text-end">
          <p className="LimitCardBoxTxt">
          {t('tradeLimitBalance')}:{" "}
            <span>
              {userwalletAddress
                ? cryptoDecimals(tokenBalance?.token2Balance)
                : 0}
            </span>
          </p>
          <TokenSelect
            className="ms-auto"
            options={tokensFullInfo}
            dropIcon
            defaultValue={defaultSelectedTokens?.token2}
            selectedField="field2"
            selectedOption={selectedOption?.optionTwo}
            SelectTokens={SelectTokens}
          />
        </div>
      </div>
      <div className="LimitCardMore">
        <div className={LimitPriceCheck ? "LimitCardMoreDanger" : ""}>
          <p>
            {(t('tradeLimitSellRate')).replace('{{asset}}', tokenOne?.symbol)}
            <Tooltip
              content={
                LimitPriceCheck
                  ? t('tradeLimitPriceLowerCheck')
                  : t('tradeLimitPlaceOrder')
              }
            />
          </p>
          <input
            className="amountInput"
            type="text"
            id="buy"
            value={
              ifUsertypedAmount && inputOne.inputValue
                ? LimitPrice
                : userValidLimitPrice
            }
            placeholder="0.0"
            onChange={(e) => {
              e.preventDefault();
              handleLimitChange(e?.target?.value);
            }}
            autoComplete="off"
            maxLength={10}
          />
        </div>
        <div>
          <p className="justify-content-end">
            {t('tradeLimitExpiry')}
            <span>
              <InfoIcon />
            </span>
          </p>
          <CustomSelect
            className="ms-auto"
            options={timeList}
            defaultValue={
              !userDropdownObject ? timeList[5] : userDropdownObject
            }
            onChange={(e: any) => {
              handleTimeSelect(e);
            }}
          />
        </div>
      </div>
      {userwalletAddress ? (
        <ButtonCustom
          fluid
          title={
            insuuficientBalance
              ? t('tradeLimitInsufficient').replace('{{asset}}', tokenOne?.symbol)
              : // : LimitPriceCheck
                // ? "Price is lower than the current market Price"
                t('tradeLimitPlaceOrder')
          }
          onClick={() => executeLimitOrder()}
          disabled={
            (ifUsertypedAmount &&
              // Number(LimitPrice) == Number(userValidLimitPrice) &&
              inputOne.convertedValue == 0) ||
            (ifUsertypedAmount && inputTwo.convertedValue == 0) ||
            !isFormValid ||
            !userwalletAddress ||
            insuuficientBalance ||
            LimitPriceCheck
          }
        />
      ) : (
        <ButtonCustom
          onClick={() => connectToWallet("Metamask", dispatch)}
          fluid
          title={
            <>
              <span className="me-2">
                <WalletIcon />
              </span>
              {t('connectWallet')}
            </>
          }
        />
      )}
      <>
        {show ? (
          <TransactionModal
            show={show}
            modalData={modalData}
            handleClose={handleClose}
            handleFunction={executeLimitOrder}
          />
        ) : null}
      </>
    </div>
  );
};

export default Limit;

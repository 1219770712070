import moment from "moment";
import { storeInstance } from "../../../../Services/axios.service";
import { loader } from "../../../../Redux/Slices/loader.slice";
import {
  swapETHOrExactETHWithTokens,
  swapTokensOrExactTokensWithETH,
} from "../../../../Services/swapHelper";
import { swapTokensOrExactTokensWithTokens } from "../../../../Services/swapHelper";
import { getAllowanceAndApprovalHelper } from "../../../../Services/contractCallService";
import { calculateDeadlineHelper } from "../../../Pages/Private/EarnAMMPools/EarnAMMHelper";
import {
  AllowanceAndApproval,
  commonSwapTokens,
} from "../../../../interfaces/contractCallInterfaces";
import { TOKEN_DETAILS } from "../../../../interfaces/commonInterfaces";
import { useTranslation } from "react-i18next";
/**
 * function to execute swapping
 * @param dispatch hook to update values in redux
 * @param tokenDetails memoized variable for token related details
 * @param inputOne inputted value in field one
 * @param inputTwo inputted value in field two
 * @param selectedField field that user has inputted first
 * @param getBackToOriginalState function to update values after transaction occurs
 * @returns boolean if swap is successfull or not
 */
export const swapHelperFunction = async (
  dispatch: any,
  tokenDetails: TOKEN_DETAILS,
  inputOne: {
    convertedValue: string;
    inputValue: string | number;
  },
  inputTwo: {
    convertedValue: string;
    inputValue: string | number;
  },
  selectedField: string,
  getBackToOriginalState: () => Promise<void>,
  setModalData: any,
  tokenA: string,
  tokenB: string,
  t: (key: string) => string,
) => {
  try {
    const { walletAddress, slippage }: { walletAddress: string, slippage: number } =
      storeInstance?.getState()?.user;
      

    // const { slippage } = storeInstance?.getState().user;
    // currently set to 1% , need to set slippage according to user
    console.log("walletAddress", walletAddress);
    console.log("slippage", slippage);

    // const slippageValue: number = slippage;
    // console.log("slippageValue", slippageValue);
    // dispatch(loader(true));
    // let deadLine: number = slippage
    //   ? slippage
    //   : await calculateDeadlineHelper();
    const deadLine: number = await calculateDeadlineHelper();
    console.log("deadLine", deadLine);
    //let currentTime: Date = new Date();
    //let deadLinetime: number = moment(currentTime).unix();
    //if (deadLine <= deadLinetime) {
    //  deadLine = await calculateDeadlineHelper();
    //}
    let slippageTolerance: number = slippage;
    if (tokenDetails?.isTokenOneNative) {
      console.log("Token one is native");
      const data: commonSwapTokens = {
        input1: inputOne?.convertedValue.toString(),
        input2: inputTwo?.convertedValue.toString(),
        walletAddress,
        tokenOneAddress: tokenDetails?.tokenOneAddress,
        tokenTwoAddress: tokenDetails?.tokenTwoAddress,
        selectedField: selectedField,
        deadLine,
        slippageTolerance,
        dispatch,
      };      
      setModalData({
        heading: `${t('tradeMarketSwapHeading')}`,
        bodyText: `${(t('tradeMarketSwapTrxConfirmText'))
          .replace('{{token1}}', tokenA)
          .replace('{{token2}}', tokenB)}`,
        status: "pending",
        txHash: null,
      });
      console.log("Before swap execution");
      console.log("data for swapETHOrExactETHWithTokens", data);
      const res = await swapETHOrExactETHWithTokens(data);
      console.log("After swap execution");
      console.log("res from swapETHOrExactETHWithTokens", res);
      if (res) {
        dispatch(loader(false));
        setModalData({
          heading: `${t('tradeMarketSwapHeading')}`,
          bodyText: `${(t('tradeMarketSwapTrxSuccessText'))
          .replace('{{token1}}', tokenA)
          .replace('{{token2}}', tokenB)}`,
          status: "success",
          txHash: res?.transactionHash,
        });
        getBackToOriginalState();
        return "SWAP DONE";
      } else {
        dispatch(loader(false));
        setModalData({
          heading: t('tradeMarketSwapHeading'),
          bodyText: (t('tradeMarketSwapTrxFailedText'))
            .replace('{{token1}}', tokenA)
            .replace('{{token2}}', tokenB),
          status: "error",
          txHash: null,
        });
        return "SWAP FAILED";
      }
    } else if (tokenDetails?.isTokenTwoNative) {
      const data: AllowanceAndApproval = {
        customToken: tokenDetails?.tokenOneAddress,
        dispatch,
        tokenOneAddress: 0,
        tokenTwoAddress: 0,
        walletAddress,
        inputOne,
        inputTwo,
        swap: true,
      };
      setModalData({
        heading: t('tradeLimitModalApprovalHeading'),
        bodyText: `${(t('tradeLimitModalApprovalConfirmText')).replace('{{tokenowner}}', tokenA || "")}`,
        status: "pending",
        txHash: null,
      });
      const res = await getAllowanceAndApprovalHelper(data);

      if (res) {
        setModalData({
          heading: t('tradeLimitModalApprovalHeading'),
          bodyText: (t('tradeMarketSwapApprovalSuccessText'))
              .replace('{{token1}}', tokenA)
              .replace('{{token2}}', tokenB),
          status: "success",
          txHash: null,
        });
        setTimeout(() => {}, 2000);

        setModalData({
          heading: `${t('tradeMarketSwapHeading')}`,
          bodyText: (t('tradeMarketSwapTrxConfirmText'))
              .replace('{{token1}}', tokenA)
              .replace('{{token2}}', tokenB),
          status: "pending",
          txHash: null,
        });
        const data: commonSwapTokens = {
          input1: inputOne?.convertedValue.toString(),
          input2: inputTwo?.convertedValue.toString(),
          walletAddress,
          tokenOneAddress: tokenDetails?.tokenOneAddress,
          tokenTwoAddress: tokenDetails?.tokenTwoAddress,
          selectedField: selectedField,
          deadLine,
          slippageTolerance,
          dispatch,
        };
        const res = await swapTokensOrExactTokensWithETH(data);
        if (res) {
          dispatch(loader(false));
          setModalData({
            heading: `${t('tradeMarketSwapHeading')}`,
            bodyText: (t('tradeMarketSwapTrxSuccessText'))
              .replace('{{token1}}', tokenA)
              .replace('{{token2}}', tokenB),
            status: "success",
            txHash: res?.transactionHash,
          });
          getBackToOriginalState();
          return "SWAP DONE";
        } else {
          dispatch(loader(false));
          setModalData({
            heading: `${t('tradeMarketSwapHeading')}`,
            bodyText: (t('tradeMarketSwapTrxFailedText'))
              .replace('{{token1}}', tokenA)
              .replace('{{token2}}', tokenB),
            status: "error",
            txHash: null,
          });
          return "SWAP FAILED";
        }
      } else {
        dispatch(loader(false));
        setModalData({
          heading: t('tradeLimitModalApprovalHeading'),
          bodyText: (t('tradeMarketSwapApprovalFailedText'))
              .replace('{{token1}}', tokenA)
              .replace('{{token2}}', tokenB),
          status: "error",
          txHash: null,
        });
        return "APPROVAL FAILED";
      }
    } else {
      const data: AllowanceAndApproval = {
        customToken: 0,
        dispatch,
        tokenOneAddress: tokenDetails?.tokenOneAddress,
        tokenTwoAddress: tokenDetails?.tokenTwoAddress,
        walletAddress,
        inputOne,
        inputTwo,
        swap: true,
      };
      setModalData({
        heading: t('tradeLimitModalApprovalHeading'),
        bodyText: (t('tradeMarketSwapApprovalConfirmText'))
            .replace('{{token1}}', tokenA)
            .replace('{{token2}}', tokenB),
        status: "pending",
        txHash: null,
      });
      const res = await getAllowanceAndApprovalHelper(data);
      if (res) {
        setModalData({
          heading: t('tradeLimitModalApprovalHeading'),
          bodyText: (t('tradeMarketSwapApprovalSuccessText'))
              .replace('{{token1}}', tokenA)
              .replace('{{token2}}', tokenB),
          status: "success",
          txHash: null,
        });
        setTimeout(() => {}, 2000);

        setModalData({
          heading: `${t('tradeMarketSwapHeading')}`,
          bodyText: `${(t('tradeMarketSwapTrxConfirmText'))
              .replace('{{token1}}', tokenA)
              .replace('{{token2}}', tokenB)}`,
          status: "pending",
          txHash: null,
        });
        const data: commonSwapTokens = {
          input1: inputOne?.convertedValue.toString(),
          input2: inputTwo?.convertedValue.toString(),
          walletAddress,
          tokenOneAddress: tokenDetails?.tokenOneAddress,
          tokenTwoAddress: tokenDetails?.tokenTwoAddress,
          selectedField: selectedField,
          deadLine,
          slippageTolerance,
          dispatch,
        };
        const res = await swapTokensOrExactTokensWithTokens(data);
        if (res) {
          dispatch(loader(false));
          setModalData({
            heading: `${t('tradeMarketSwapHeading')}`,
            bodyText: `${(t('tradeMarketSwapTrxSuccessText'))
                .replace('{{token1}}', tokenA)
                .replace('{{token2}}', tokenB)}`,
            status: "success",
            txHash: res?.transactionHash,
          });
          getBackToOriginalState();
          return "SWAP DONE";
        } else {
          dispatch(loader(false));
          setModalData({
            heading: `${t('tradeMarketSwapHeading')}`,
            bodyText: `${(t('tradeMarketSwapTrxFailedText'))
                .replace('{{token1}}', tokenA)
                .replace('{{token2}}', tokenB)}`,
            status: "error",
            txHash: null,
          });
          return "SWAP FAILED";
        }
      } else {
        dispatch(loader(false));
        setModalData({
          heading: t('tradeLimitModalApprovalHeading'),
          bodyText: (t('tradeMarketSwapApprovalFailedText'))
              .replace('{{token1}}', tokenA)
              .replace('{{token2}}', tokenB),
          status: "error",
          txHash: null,
        });
        return "APPROVAL FAILED";
      }
    }
  } catch (error) {
    console.log("Error in swapHelperFunction", error);
    dispatch(loader(false));
    setModalData({
      heading: `${t('tradeMarketSwapHeading')}`,
      bodyText: `${(t('tradeMarketSwapTrxFailedText'))
                .replace('{{token1}}', tokenA)
                .replace('{{token2}}', tokenB)}`,
      status: "error",
      txHash: null,
    });
    console.log("error", error);
  }
};
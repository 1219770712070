import Swal from "sweetalert2";
import Web3 from "web3";
import toaster from "../../Components/Common/Toast";

import {
  CHAIN_ID,
  EXPLORER_LINK,
  NETWORK_DECIMALS,
  NETWORK_NAME,
  NETWORK_SYMBOL,
  RESPONSES,
  RPC_URL,
} from "../../Utils";
import { storeInstance } from "../../Services/axios.service";
import {
  saveNetworkChainId,
  setUserOnboardingData,
  walletAddress,
  walletType,
} from "../Slices/user.slice";
import store from "../Store";
import { useDispatch } from "react-redux";
import { callApiPostMethod } from "./api.action";
import { toast } from "react-hot-toast";
import { DATA_INSERTED, GET_USER } from "./apiResponseInterfaces";
import { createUserData } from "../../interfaces/commonInterfaces";

/**DECLARE ETHEREUM TYPE */
declare global {
  interface Window {
    ethereum?: any;
  }
}

export const subscribeToBlocks = (callback: () => void) => {
  const web3 = new Web3(window.ethereum);
  const subscription: any = web3.eth.subscribe('newBlockHeaders', (error, result) => {
    if (!error) {
      console.log('Block:', result);
      callback();
      return;
    }
    console.error('Error subscribing:', error);
  });

  return () => subscription.unsubscribe();
};

// wallet connect

export const connectToWallet = async (wallet: string, dispatch: any) => {
  try {
    if (wallet === "Metamask") {
      setTimeout(() => {
        dispatch(connectmetamask());
      }, 100);
    }
  } catch (error) {}
};

export const handleUserProgress = async (data: {
  dispatch: any;
  walletAddress: string;
  from: string;
}) => {
  const { dispatch, walletAddress, from } = data;
  const result: GET_USER = await dispatch(
    callApiPostMethod(
      "GET_USER_ONBOARDING",
      { walletAddress: walletAddress },
      false,
      false
    )
  );
  if (result?.status == RESPONSES?.SUCCESS) {
    if (
      result?.data?.label &&
      result?.data?.value &&
      result?.walletStatus != "inactive"
    ) {
      // dispatch(setUserOnboardingData(result));
      dispatch(
        setUserOnboardingData({
          progress: result?.data?.value,
          path: result?.data?.label,
        })
      );

      // setStepsData({ progress: result.data.value, path: result.data.label });
    }
    return result;
  }
};

export const updateUserModal = async (data: {
  email: string;
  name: string;
  image?: string;
  address: string;
  shariah: boolean;
  dispatch: any;
}) => {
  const { email, name, image, address, shariah, dispatch } = data;
  let result: DATA_INSERTED = await dispatch(
    callApiPostMethod(
      "USER_ONBOARDING",
      image
        ? {
            walletAddress: address,
            emailAddress: email,
            name: name,
            image: image,
            shariah: shariah,
          }
        : {
            walletAddress: address,
            emailAddress: email,
            name: name,
            shariah: shariah,
          },
      false
    )
  );
  return result;
  
};

export const updateUserLanguage = async (data: {
  walletAddress: string;
  language: string;
  dispatch: any;
}) => {
  const { walletAddress, language, dispatch } = data;
  let result: DATA_INSERTED = await dispatch(
    callApiPostMethod(
      "USER_ONBOARDING",
      {
        walletAddress,
        language
      }
    )
  );
  return result;
};

export const createUserProgress = async (data: createUserData) => {
  // dispatch(setSteps(value));
  const {
    value,
    label,
    address,
    email,
    name,
    dispatch,
    image,
    termsncond,
    slippage,
    deadlineInMin,
    signature,
    language,
    shariah,
  } = data;
  let result: DATA_INSERTED;
  if (slippage || deadlineInMin) {
    result = await dispatch(
      callApiPostMethod(
        "USER_ONBOARDING",
        {
          walletAddress: address,
          slippage: slippage,
          deadlineInMin: deadlineInMin,
        },
        false,
        false
      )
    );
  } else {
    result = await dispatch(
      callApiPostMethod(
        "USER_ONBOARDING",
        {
          walletAddress: address,
          value,
          label,
          emailAddress: email,
          name,
          image,
          termsncond,
          signature,
          language,
          shariah
        },
        false,
        false
      )
    );
  }
  //To Check
  if (result?.status == RESPONSES?.SUCCESS && !slippage && !deadlineInMin) {
    dispatch(
      setUserOnboardingData({
        progress: value,
        path: label,
      })
    );
    return result;
  } else if (
    result?.status == RESPONSES?.SUCCESS &&
    (slippage || deadlineInMin)
  ) {
    return result;
  } else {
    console.log("THERE was some error");
    return false;
  }
};

/**CHECK WHETHER METAMASK IS INSTALLED OR NOT */
export const isMetaMaskInstalled = () => {
  const { ethereum } = window;
  return Boolean(ethereum && ethereum.isMetaMask);
};

const { ethereum }: any = window;
// const type = walletTypeUser == "MetaMask" ? ethereum : provider;
if (ethereum) {
  ethereum.on("chainChanged", function () {
    approveNetwork();
  });
  ethereum.on("accountsChanged", function (account: any) {
    if (!account?.length) {
      storeInstance.dispatch(walletAddress(""));
      // Swal.fire({
      //   icon: "info",
      //   title: "Wallet Disconnected",
      //   text: "Please connect wallet to continue",
      //   showCancelButton: false,
      //   confirmButtonText: "Ok",
      // });
    } else {
      storeInstance.dispatch(walletAddress(account[0]));
    }
  });
}

/**CONNECT WITH METAMASK */
export const connectmetamask = () => {
  return (dispatch: DispatchType) =>
    new Promise(async (resolve, reject) => {
      /**CHECK METAMASK IN INSTALLED OR NOT */
      const installed = await isMetaMaskInstalled();

      try {
        let address;
        if (installed) {
          const { ethereum } = window;
          let validNetwork: any;
           //subscribeToBlocks(() => {});
          /**VERIFY METAMASK HAVE OUR NETWORK AND METAMASK SHOULD BE ON OUR NETWORK */
          //if (ethereum.networkVersion != CHAIN_ID) {
          if (ethereum.chainid !== CHAIN_ID) {
            validNetwork = await approveNetwork();
          } else {
            validNetwork = true;
          }
          if (validNetwork.code != 4001 || validNetwork) {
            /**METHOD CALL WHEN ACCOUNT CHANGED IN METAMASK */
            ethereum.on("accountsChanged", async function (accounts: string[]) {
              address = accounts[0];

              /**SAVE WALLET ADDRESS AND WALLET TYPE IN REDUX */
              dispatch(walletType("Metamask"));
              return dispatch(walletAddress(address));
            });

            /**METHOD CALL WHEN NETWORK CHANGED IN METAMASK */
            ethereum.on("chainChanged", function (networkId: number) {
              // setTimeout(function () { window.location.reload(); }, 1000);
            });

            /**GET ACCOUNT DETAILS */
            const accounts = await ethereum.request({
              method: "eth_requestAccounts",
            });
            address = accounts[0];

            /**SAVE WALLET ADDRESS AND WALLET TYPE IN REDUX */
            dispatch(walletType("Metamask"));
            resolve(address);

            dispatch(walletAddress(address));
            return address;
          } else {
            resolve(true);
            return validNetwork;
          }
        } else {
          /**IF METAMASK NOT INSTALLED */
          reject(false);
          return toaster.error("Please install Metamask.");
        }
      } catch (error: any) {
        reject(false);
        toaster.error(error.message);
        return error;
      }
    });
};

/**VERIFY METAMASK HAVE OUR NETWORK AND METAMASK SHOULD BE ON OUR NETWORK */
export const approveNetwork = async () => {
  return new Promise(async (resolve, reject) => {
    const { ethereum } = window;
    if (!ethereum) {
      console.error("Ethereum object doesn't exist!");
      reject("Ethereum object not found");
      return;
    }

    try {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: Web3.utils.toHex(CHAIN_ID) }],
      });
      resolve(true);
    } catch (switchError: any) {
      if (switchError.code === 4902) {
        try {
          await ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId:Web3.utils.toHex(CHAIN_ID),
                chainName:NETWORK_NAME,
                nativeCurrency: {
                  name:NETWORK_NAME,
                  symbol:NETWORK_SYMBOL,
                  decimals:Number(NETWORK_DECIMALS),
                },
                rpcUrls:[RPC_URL],
                blockExplorerUrls:[EXPLORER_LINK],
              },
            ],
          });
          resolve(true);
        } catch (addError) {
          console.error('Failed to add network:', addError);
          reject(addError);
        }
      } else {
        console.error('Failed to switch network:', switchError);
        reject(switchError);
      }
    }
  });
};

export const metamaskDisconnect = async (dispatch: DispatchType) => {
  window?.ethereum.on("disconnect", () => {
    dispatch(walletAddress(""));
  });
};

export const clearCache = () => {
  if ("caches" in window) {
    caches.keys().then((cacheNames) => {
      cacheNames.forEach((cacheName) => {
        caches.delete(cacheName);
      });
    });
  }
  localStorage.clear();
  // Manually remove the Redux Persist state key from local storage
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith("your-persist-key")) {
      localStorage.removeItem(key);
    }
  });

  window.location.reload();
};

export const removeStorage = () => {
  // deleteAllCookies()

  document.cookie.split(";").forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=expires=" + new Date().toUTCString() + "path=/");
  });

  // Remove items from local storage and session storage

  window.localStorage.clear();
  sessionStorage.clear();

  // Delete specific caches

  if ("caches" in window) {
    caches.keys().then(function (cacheNames) {
      cacheNames.forEach(function (cacheName) {
        caches.delete(cacheName);
      });
    });
  }
  window.location.reload();
};

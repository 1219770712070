import React from 'react';
import { ModalClose , RightArrow, } from "../../../../Assets/Images/Icons/SvgIcons";
import ButtonCustom from "../../../Common/Button/ButtonCustom";
import { Modal } from "react-bootstrap";
import './WalletOptions.scss';
import { useTranslation } from 'react-i18next';

const WalletOptionsModal = ({ show, handleClose, wallets, onSelectWallet }) => {
  const { t } = useTranslation();
  const handleSelectWallet = (wallet) => {
    console.log("Selected Wallet:", wallet.name);
    switch (wallet.name) {
      case "MetaMask":
        window.open("https://metamask.io/download/", "_blank");
        break;
      case "WalletConnect":
        window.open("https://walletconnect.com/", "_blank");
        break;
      case "Uniswap":
        window.open("https://wallet.uniswap.org", "_blank");
        break;
      default:
        console.log("Wallet not recognized");
    }

    onSelectWallet(wallet);
    handleClose();
  };

  const walletInfo = [
    {
      title: `${t('walletConnectHeader1')}`,
      content: `${t('walletConnectSection1')}`
    },
    {
      title: `${t('walletConnectHeader2')}`,
      content: `${t('walletConnectSection2')}`
    }
  ];

  return (
    <Modal className="wallet-options-modal" show={show} onHide={handleClose} centered>
        <button className="modal_close_btn" onClick={handleClose}>
            <ModalClose />
        </button>
        <Modal.Body>
          <div className="wallet-options-left-panel">
            <div className="wallet-options-left-panel-details">
              <h3>{t('walletConnectTitle')}</h3>
              <ul>
                {walletInfo?.map((item) => (
                  <li key={item?.title}>
                    <h4>{item?.title}</h4>
                    <p>{item?.content}</p>
                  </li>
                ))}
              </ul>
              <ButtonCustom
                className="bordered-blue learnBtn"
                fluid
                title={t('learnMore')}
                onlyIcon={<RightArrow />}
              />
            </div>
            <div className="wallet-options-right-panel">
              <h2>
                {t('getWeb3Wallet')}
              </h2>
              <p>
                {t('walletInstallation')}
              </p>
              <ul className="wallet-list">
                {wallets.map(wallet => (
                  <li key={wallet.name}>
                    <ButtonCustom
                      title={wallet.name}
                      btnIcon={wallet.icon}
                      className="walletBtn bordered-blue"
                      onClick={() => handleSelectWallet(wallet)}
                    />
                  </li>
                ))}
              </ul>
            </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default WalletOptionsModal;
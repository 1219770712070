import React, { useState } from 'react';
//import React, { useEffect, useState, useRef } from 'react';
import './TestnetGuide.scss'; 
import ReactPlayer from "react-player";
import testnetNetwork from "../../../../Assets/Images/testnet/TestnetNetworkSelection.png";
import addToken from "../../../../Assets/Videos/TestnetAddAToken.mp4";
import libfiFaucet from "../../../../Assets/Videos/TestnetLibFiFaucet.mp4";
import sepoliaFaucet from "../../../../Assets/Videos/TestnetSepoliaFaucet.mp4";
import arbitrumBridge from "../../../../Assets/Videos/TestnetArbitrumBridge.mp4";
//import WarningIcon from '../../../../Assets/Images/Icons/warning.png'
import { PlayIcon } from "../../../../Assets/Images/Icons/SvgIcons";
import videoPoster from "../../../../Assets/Images/liberty_finance_thumbnail.svg";
import { useTranslation } from 'react-i18next';
//import metamaskImage from "../../../../Assets/Images/Icons/wallets/metamask_fox.svg"

const TestnetGuide = () => {
 
    const { t } = useTranslation();
    const [imageVisible, setImageVisible] = useState<boolean>(false);

    const handleVideoEnded = () => {
        setImageVisible(!imageVisible);
    };


    const addArbitrumSepoliaToMetaMask = async () => {
    try {
        // Check if MetaMask is installed
        if (window.ethereum && window.ethereum.isMetaMask) {
            // Add Arbitrum Sepolia network to the MetaMask wallet
            const wasAdded = await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                    chainId: '0x' + parseInt("421614").toString(16), 
                    chainName: 'Arbitrum Sepolia',
                    nativeCurrency: {
                        name: 'Ethereum',
                        symbol: 'ETH',
                        decimals: 18
                    },
                    rpcUrls: ['https://sepolia-rollup.arbitrum.io/rpc'], 
                    blockExplorerUrls: ['https://sepolia.arbiscan.io/'],
                }],
            });

            if (wasAdded) {
                console.log('Arbitrum Sepolia was added successfully!');
            } else {
                console.log('Arbitrum Sepolia was not added.');
            }
        } else {
            alert(t('helperMetamastNotInstalledError'));
        }
      } catch (error) {
          console.error('An error occurred:', error);
      }
    };

    const addToMetaMask = async ( tokenAddress, tokenSymbol, tokenDecimals ) => {
        try {
            // Check if MetaMask is installed
            if (window.ethereum && window.ethereum.isMetaMask) {
                // Suggest the token to the MetaMask wallet
                const wasAdded = await window.ethereum.request({
                    method: 'wallet_watchAsset',
                    params: {
                        type: 'ERC20', // This method only supports ERC20 tokens.
                        options: {
                            address: tokenAddress,
                            symbol: tokenSymbol,
                            decimals: tokenDecimals
                        },
                    },
                });

                if (wasAdded) {
                    console.log(`${tokenSymbol} was added successfully!`);
                } else {
                    console.log(`${tokenSymbol} was not added.`);
                }
            } else {
                alert(t('helperMetamastNotInstalledError'));
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };


    return (
                <div className="user-guide-section">
            
                  <section className="user-guide-section-select-network">
                    <h2>{t('helperSelectArbitrum')}</h2>
                    <p style={{ display: 'inline-flex'}}>{t('helperSelectArbitrumDescription')} <span>➔</span> </p>
                <div className='ButtonCustom' style={{ display: 'inline-flex', width: 'max-content', padding: '3px', margin: '0 0 10px 5px', verticalAlign: 'middle' }} >
                        <img
                        src="https://metamask.io/assets/icon.svg" 
                        alt={t('helperSepoliaTooltip')} 
                        title={t('helperSepoliaTooltip')}
                        style={{ height: '32px', width: 'auto', cursor: 'pointer'}}
                        onClick={() => addArbitrumSepoliaToMetaMask()} 
                        />
                    <span style={{ paddingTop: '5px', cursor: 'pointer' }} onClick={() => addArbitrumSepoliaToMetaMask()} >{t('helperClickMe')}</span>
                      </div>
                    
                    <img src={testnetNetwork} alt="Add Testnet Network"/>
                  </section>
            
                  <section className="user-guide-section-add-token">
                    <h2>{t('helperAddTokenTitle')}</h2>
                    <div className='user-guide-section-add-token-container'>
            
                        <div className="token-address-container">
                        <p className="token-address-container-text">{t('helperAddTokenDescription')}</p>
                            <div className='token-address-container-row'>
                                <p className='token-address-container-row-name'>
                                    <span>ARB</span>
                                    <span>:</span>
                                    <span>0x3a12ea1bEa9b04f5541affBe1F6Dd83a72a9bbd7</span>
                                    <span className='arrow'>➔</span>
                                </p>
                                <div className='ButtonCustom' style={{ display: 'inline-flex', height: 'max-content', width: 'max-content', padding: '0px 5px', margin: '0 0 6px 5px'}}>
                                    <img src="https://metamask.io/assets/icon.svg" alt={t('helperAddTokenToolTip')} title={t('helperAddTokenToolTip')} onClick={() => addToMetaMask('0x3a12ea1bEa9b04f5541affBe1F6Dd83a72a9bbd7', 'ARB', 18)} style={{ verticalAlign: 'middle', height: '24px', width: 'auto', cursor: 'pointer' }} />
                                    <span style={{cursor:'pointer'}} onClick={() => addToMetaMask('0x3a12ea1bEa9b04f5541affBe1F6Dd83a72a9bbd7', 'ARB', 18)}>{t('helperClickMe')}</span>
                                </div>
                            </div>
                            <div className='token-address-container-row'>
                                <p className='token-address-container-row-name'>
                                    <span>LIBFI</span>
                                    <span>:</span>
                                    <span>0xaf8b5664505a1d777975202C5E2e2F7777E22BCB</span>
                                    <span className='arrow'>➔</span>
                                </p>
                                <div className='ButtonCustom' style={{ display: 'inline-flex', height: 'max-content', width: 'max-content', padding: '0px 5px', margin: '0 0 6px 5px'}}>
                                    <img src="https://metamask.io/assets/icon.svg" alt={t('helperAddTokenToolTip')} title={t('helperAddTokenToolTip')} onClick={() => addToMetaMask('0xaf8b5664505a1d777975202C5E2e2F7777E22BCB', 'LIBFI', 18)} style={{ verticalAlign: 'middle', height: '24px', width: 'auto', cursor: 'pointer' }} />
                                    <span style={{cursor:'pointer'}} onClick={() => addToMetaMask('0xaf8b5664505a1d777975202C5E2e2F7777E22BCB', 'LIBFI', 18)}>{t('helperClickMe')}</span>
                                </div>
                            </div>
                            <div className='token-address-container-row'>
                                <p className='token-address-container-row-name'>
                                    <span>USDT</span>
                                    <span>:</span>
                                    <span>0x21a20419571bB5cB44bE3F1AaDCf36E8d22C8874</span>
                                    <span className='arrow'>➔</span>
                                </p>
                                <div className='ButtonCustom' style={{ display: 'inline-flex', height: 'max-content', width: 'max-content', padding: '0px 5px', margin: '0 0 6px 5px'}}>
                                    <img src="https://metamask.io/assets/icon.svg" alt={t('helperAddTokenToolTip')} title={t('helperAddTokenToolTip')} onClick={() => addToMetaMask('0x21a20419571bB5cB44bE3F1AaDCf36E8d22C8874', 'USDT', 6)} style={{ verticalAlign: 'middle', height: '24px', width: 'auto', cursor: 'pointer' }} />
                                    <span style={{cursor:'pointer'}} onClick={() => addToMetaMask('0x21a20419571bB5cB44bE3F1AaDCf36E8d22C8874', 'USDT', 6)}>{t('helperClickMe')}</span>
                                </div>
                            </div>
                            <div className='token-address-container-row'>
                                <p className='token-address-container-row-name'>
                                    <span>WETH</span>
                                    <span>:</span>
                                    <span>0x22a4c090bd7744aa94be47fe368240de5c000920</span>
                                    <span className='arrow'>➔</span>
                                </p>
                                <div className='ButtonCustom' style={{ display: 'inline-flex', height: 'max-content', width: 'max-content', padding: '0px 5px', margin: '0 0 6px 5px'}}>
                                    <img src="https://metamask.io/assets/icon.svg" alt={t('helperAddTokenToolTip')} title={t('helperAddTokenToolTip')} onClick={() => addToMetaMask('0x22a4c090bd7744aa94be47fe368240de5c000920', 'WETH', 18)} style={{ verticalAlign: 'middle', height: '24px', width: 'auto', cursor: 'pointer' }} />
                                    <span style={{cursor:'pointer'}} onClick={() => addToMetaMask('0x22a4c090bd7744aa94be47fe368240de5c000920', 'WETH', 18)}>{t('helperClickMe')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                  </section>

                <div className="video-container">
                    <div className="BannerVideo">
                        <ReactPlayer
                            canPlay
                            className="mobile-responsive"
                            url={require(`../../../../Assets/Videos/${t('helperAddTokenVideoName')}`)}
                            light={
                                <img className="Thumbnail" src={videoPoster} alt="Thumbnail" />
                            }
                            pip={true}
                            controls
                            playIcon={
                                <button className="playIcon">
                                <PlayIcon />
                                </button>
                            }
                            playing={true}
                            config={{
                                file: {
                                attributes: {
                                    controlsList: "nodownload",
                                },
                                },
                            }}
                            onEnded={handleVideoEnded}
                            {...(imageVisible && (
                                <img src={videoPoster} alt="After Video" />
                            ))}
                        />
                    </div>
                </div>


          <section className="user-guide-section-gas-fees">

            <h2>{t('helperGetTokenGasTitle')}</h2>
            
            <div className="user-guide-section-gas-fees-container"> 
                <p>{t('helperGetTokenGasConnect')} <a href="https://sepolia-faucet.pk910.de/" target="_blank">{t('helperGetTokenGasConnectNetworkName')}</a></p>
                <div className="video-container">
                    <div className="BannerVideo">
                        <ReactPlayer
                            canPlay
                            className="mobile-responsive"
                            url={require(`../../../../Assets/Videos/${t('helperSepoliaFaucetVideoName')}`)}
                            light={
                                <img className="Thumbnail" src={videoPoster} alt="Thumbnail" />
                            }
                            pip={true}
                            controls
                            playIcon={
                                <button className="playIcon">
                                <PlayIcon />
                                </button>
                            }
                            playing={true}
                            config={{
                                file: {
                                attributes: {
                                    controlsList: "nodownload",
                                },
                                },
                            }}
                            onEnded={handleVideoEnded}
                            {...(imageVisible && (
                                <img src={videoPoster} alt="After Video" />
                            ))}
                        />
                    </div>
                </div>
            </div>

            <div>
                <p>{t('helperGetTokenGasBridge')} <a href="https://bridge.arbitrum.io/?destinationChain=arbitrum-sepolia&sourceChain=sepolia" target="_blank">{t('helperGetTokenGasBridgeName')}</a></p>
                <div className="video-container">
                    <div className="BannerVideo">
                        <ReactPlayer
                            canPlay
                            className="mobile-responsive"
                            url={require(`../../../../Assets/Videos/${t('helperArbitrumBridgeVideoName')}`)}
                            light={
                                <img className="Thumbnail" src={videoPoster} alt="Thumbnail" />
                            }
                            pip={true}
                            controls
                            playIcon={
                                <button className="playIcon">
                                <PlayIcon />
                                </button>
                            }
                            playing={true}
                            config={{
                                file: {
                                attributes: {
                                    controlsList: "nodownload",
                                },
                                },
                            }}
                            onEnded={handleVideoEnded}
                            {...(imageVisible && (
                                <img src={videoPoster} alt="After Video" />
                            ))}
                        />
                    </div>
                </div>
            </div>

          </section>


          <section className="user-guide-section-faucet">
            <h2>{t('helperClaimLibfiTitle')}</h2>
            <p>{t('helperClaimLibfiDescription')} <a href="http://faucet.libfi.io/" target="_blank">{t('helperClaimLibfiFaucetName')}</a></p>
            <div className="video-container">
                    <div className="BannerVideo">
                        <ReactPlayer
                            canPlay
                            className="mobile-responsive"
                            url={require(`../../../../Assets/Videos/${t('helperLibfiFaucetVideoName')}`)}
                            light={
                                <img className="Thumbnail" src={videoPoster} alt="Thumbnail" />
                            }
                            pip={true}
                            controls
                            playIcon={
                                <button className="playIcon">
                                <PlayIcon />
                                </button>
                            }
                            playing={true}
                            config={{
                                file: {
                                attributes: {
                                    controlsList: "nodownload",
                                },
                                },
                            }}
                            onEnded={handleVideoEnded}
                            {...(imageVisible && (
                                <img src={videoPoster} alt="After Video" />
                            ))}
                        />
                    </div>
                </div>
          </section>



          <section className="user-guide-section-congrats">
            <h2>{t('helperAllSetTitle')}</h2>
            <p>{t('helperAllSetDescription')}</p>
          </section>

        {/*}
          <section className="user-guide-section-quest">

            <h2>6. Provide Your Wallet Address in TaskOn </h2>
                <div className='user-guide-section-quest-container'>
                    <div className="iframe-container">
                        <iframe
                            title="Add Token Tutorial"
                            src="https://taskon.xyz/campaign/detail/40123"
                        ></iframe>
                    </div>
                </div>

          </section>
        
        */}

        </div>
      );

  

};

export default TestnetGuide;

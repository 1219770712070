import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import CommonCard from "../CommonCard/CommonCard";
import "./WhatHot.scss";
import Slider from "react-slick";
import sampleImg1 from "../../../../Assets/Images/news-sample3.png";
import ButtonCustom from "../../Button/ButtonCustom";
import {
  RightArrowCirlce,
  TickIcon,
} from "../../../../Assets/Images/Icons/SvgIcons";
import { cryptoDecimals } from "../../../../Services/common.service";
import { tokenCollection } from "../../../../Services/dynamicContractDetails";
import { settokenOne, settokenTwo } from "../../../../Redux/Slices/tokenSlice";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/scss';
import 'swiper/scss/navigation';

// Import required modules
import { Navigation } from 'swiper/modules';


const WhatHot = ({ pools, newSlider }: any) => {
  const { t } = useTranslation();

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: document.body.dir === "rtl" ? -1 : 1,
    currentSlide: document.body.dir === "rtl" ? -1 : 0,
    rtl: document.body.dir === "rtl",
    // arrows: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          currentSlide: document.body.dir === "rtl" ? 1 : 0,
          // arrows: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          currentSlide: document.body.dir === "rtl" ? 1 : 0,
          // arrows: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          currentSlide: document.body.dir === "rtl" ? 1 : 0,
          // arrows: true,
        },
      },
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 1,
          currentSlide: document.body.dir === "rtl" ? 1 : 0,
          // arrows: true,
        },
      },
    ],
  };
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const handlePairNavigate = async (data) => {
    const tokenA = data?.pair.split("/")[0];
    const tokenB = data?.pair.split("/")[1];
    const tokenAInfo = tokenCollection.find((item) => {
      return item?.symbol == tokenA;
    });
    const tokenBInfo = tokenCollection.find((item) => {
      return item?.symbol == tokenB;
    });
    dispatch(settokenOne(tokenAInfo));
    dispatch(settokenTwo(tokenBInfo));
    navigate(
      `/${data?.link}`
    );
  };

  return (
    <CommonCard
      className="WhatHot"
      cardTitle={t('homeWhatsHot')}
      viewAllNavigate="/auth/dashboard/libfi"
      walletNotConnected={true}
    >
      {pools?.length > 0 ? (
        newSlider ? (
          <div className="swiper-main">
            <div className="swiper-arrow swiper-arrow-next"></div>
            <div className="swiper-arrow swiper-arrow-prev"></div>
            <Swiper
              className="WhatHotSlider"
              dir={"ltr"}
              modules={[Navigation]}
              slidesPerView={5}
              initialSlide={0}
              navigation={{
                nextEl: ".swiper-arrow-next",
                prevEl: ".swiper-arrow-prev",
                disabledClass: "swiper-arrow-disabled"
              }}
              loop={true}
              breakpoints={{
                0: {
                  slidesPerView: 1
                },
                // when window width is >= 480px
                480: {
                  slidesPerView: 2
                },
                // when window width is >= 768px
                768: {
                  slidesPerView: 3
                },
                // when window width is >= 992px
                992: {
                  slidesPerView: 4
                },
                // when window width is >= 1200px
                1200: {
                  slidesPerView: 5
                },
              }}
            >
              {pools.map((obj, index) => {
                return (
                  <SwiperSlide>
                    <div className="WhatHotSlide" dir={document.body.dir}>
                      <img src={obj?.image} alt="" />
                      <span className="tickIcon">
                        <TickIcon />
                      </span>
                      <div className="WhatHotSlideBody">
                        <p className="TokenName">{obj?.pair}</p>
                        <h3>{t('whatHotVol').replace("{{volume}}", cryptoDecimals(obj?.vol))}</h3>
                        <h5>
                          <span>{obj?.percentage}</span>
                          <span>%</span>
                        </h5>
                        <h6>
                          <span>+</span>
                          <span className="mx-1">{t('whatHotPlusAnalyst')}</span>
                          <span>{obj?.analyst}</span>
                          </h6>
                        <ButtonCustom
                          title={t('whatHotTradeButton')}
                          onClick={() => {
                            handlePairNavigate(obj);
                          }}
                          fluid
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        ) : (
          <Slider
            nextArrow={
              <button className="SlideBtn">
                <RightArrowCirlce />
              </button>
            }
            prevArrow={
              <button className="SlideBtn">
                <RightArrowCirlce />
              </button>
            }
            className="WhatHotSlider"
            {...settings}
          >
            {pools.map((obj, index) => {
              return (
                <div className="WhatHotSlide" dir={document.body.dir}>
                      <img src={obj?.image} alt="" />
                      <span className="tickIcon">
                        <TickIcon />
                      </span>
                      <div className="WhatHotSlideBody">
                        <p className="TokenName">{obj?.pair}</p>
                        <h3>{t('whatHotVol').replace("{{volume}}", cryptoDecimals(obj?.vol))}</h3>
                        <h5>
                          <span>{obj?.percentage}</span>
                          <span>%</span>
                        </h5>
                        <h6>
                          <span>+</span>
                          <span className="mx-1">{t('whatHotPlusAnalyst')}</span>
                          <span>{obj?.analyst}</span>
                          </h6>
                        <ButtonCustom
                          title={t('whatHotTradeButton')}
                          onClick={() => {
                            handlePairNavigate(obj);
                          }}
                          fluid
                        />
                      </div>
                    </div>
              );
            })}
          </Slider>
        )

      ) : (
        <h1>{t('homeFetchingData')}</h1>
      )}
    </CommonCard>
  );
};

export default WhatHot;

import React from 'react'
import './ProgressLine.scss';

const ProgressLine = (props: { done?: number, position?: 'bottom' | 'top' }) => {
    const { done, position } = props;
    return (
        <div className={`ProgressLine ${position || 'bottom'}`}>
            <span style={{ width: `${done}%` }}></span>
        </div>
    )
}

export default ProgressLine

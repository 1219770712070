import Web3 from "web3";
import Big from 'big.js';
import {
  callContractGetMethod,
  callContractSendMethod,
} from "../Redux/Actions/contract.action";
import { callWeb3 } from "./contract.service";
import {
  divideWithDecimal,
  errorHelperContract,
  fromWeiConvert,
  toCustomFixed,
  tofixFunctionSliced,
} from "./common.service";
import { MAX_APPROVAL } from "../Utils";
import { zeroAddress } from "../Components/Pages/Private/EarnAMMPools/EarnAMMHelper";
import {
  dynamicContractDetails,
  tokenCollection,
} from "./dynamicContractDetails";
import {
  AllowanceAndApproval,
  smartContractType,
  minimumBalanceUser,
} from "../interfaces/contractCallInterfaces";
import { GET_AMOUNTS_DATA } from "../interfaces/commonInterfaces";

const routerAddress: string | undefined | number = dynamicContractDetails?.find(
  (data) => data?.symbol == "router"
)?.address;

const factoryAddress: string | undefined | number =
  dynamicContractDetails?.find((data) => data?.symbol == "factory")?.address;
// const getTotalSupply = async (data: { pairAddress: string; dispatch: any }) => {
//   try {
//     const { pairAddress, dispatch } = data;
//     const totalSupplyString = await dispatch(
//       callContractGetMethod("totalSupply", [], "pair", false, pairAddress)
//     );
//     const totalSupply = BigInt(totalSupplyString);
//     return totalSupply;
//   } catch (error) {
//     errorHelperContract(error, "call", "totalSupply");
//     return BigInt(0);
//   }
// };
// gettingTotalSupply
const getTotalSupply = async (data: { pairAddress: string; dispatch: any }) => {
  try {
    const { pairAddress, dispatch } = data;
    const totalSupply: number = Number(await dispatch(
      callContractGetMethod("totalSupply", [], "pair", false, pairAddress)
    ));
    return totalSupply;
  } catch (error) {
    errorHelperContract(error, "call", "totalSupply");
    return 0;
  }
};

// const walletAddress = storeInstance.getState().user.walletAddress;
/**
 * this function is used to fetch LP token balances that the user's wallet hold
 * @param data it is an object that contains various params required for this function
 * pairAddress is the address of two tokens of which balance is to be found
 * wallet address of the user
 * @returns LP token balance of user
 */

const getLPBalance = async (data: {
  pairAddress: string;
  dispatch: any;
  walletAddress: string;
}) => {
  try {
    const { pairAddress, dispatch, walletAddress } = data;
    const res: string = await dispatch(
      callContractGetMethod(
        "balanceOf",
        [walletAddress],
        "pair",
        false,
        pairAddress
      )
    );
    // console.log(`getLPBalance - Inputs:`, {pairAddress, walletAddress});
    //console.log(`getLPBalance - Output:`, userLiquidity);
    return res;
  } catch (error) {
    errorHelperContract(error, "call", "getLPBalance");
    return 0;
  }
};

/**
 * this function is used to fetch balance of native currency that user's wallet hold
 * @param walletAddress wallet address of the user
 * @returns an object with original balance and balance divided by token's decimal
 */

const getNativeBalance = async (walletAddress: string) => {
  try {
    let web3Object = new Web3(window?.ethereum);
    let web3: any = await callWeb3();
    let ethBalance: string = await web3?.eth?.getBalance(walletAddress);
    // const tokenData = dynamicContractDetails?.find((a) => a?.symbol == "ETH");
    // const calculatedBalance = await convertUsingTokenDecimals(
    //   tokenData??.address,
    //   ethBalance
    // );
    const calculatedBalance: number = Number(toCustomFixed(
      fromWeiConvert(ethBalance),
      //Number(fromWeiConvert(ethBalance)),
      4
    ));
    return { res: ethBalance, calculatedBalance };
  } catch (error) {
    errorHelperContract(error, "call", "getNativeBalance");
    return 0;
  }
};

/**
 * this function is used to fetch token balances that the user's wallet hold
 * @param data it is an object that contains various params required for this function
 * address is the token address of which balance is to be found
 * wallet Address of the user
 * @returns an object with original balance and balance divided by token's decimal
 */

const getTokenBalance = async ({
  tokenAddress,
  dispatch,
  walletAddress,
}: {
  tokenAddress: string;
  dispatch: any;
  walletAddress: string;
}) => {
  try {
    const tokenBalance: string | number = await dispatch(
      callContractGetMethod(
        "balanceOf",
        [walletAddress],
        "dynamic",
        false,
        tokenAddress
      )
    );
    //return Number(tokenBalance);
    
    const decimals: string | number = await dispatch(
      callContractGetMethod("decimals", [], "dynamic", false, tokenAddress)
    );
    const calculatedBalance: number = toCustomFixed(
      divideWithDecimal(tokenBalance, decimals),
      //divideWithDecimal(tokenBalance, Number(decimals)),
      4
    );
    // console.log(`Fetching balance for token address: ${tokenAddress}`);
    return { res: Number(tokenBalance), calculatedBalance };
  } catch (error) {
    errorHelperContract(error, "call", "getTokenBalance");
    return 0;
  }
};

export function capitalizeFirstLetter(inputString: any) {
  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}

/**
 * this function is used to fetch token balances that the user's wallet hold
 * @param data it is an object that contains various params required for this function
 * address is the token address of which balance is to be found
 * wallet Address of the user
 * @returns an object with original balance and balance divided by token's decimal
 */

const getTokenBalanceForNewUser = async ({
  tokenAddress,
  dispatch,
  walletAddress,
}: {
  tokenAddress: string;
  dispatch: any;
  walletAddress: string;
}) => {
  try {
    const tokenBalance: string | number = await dispatch(
      callContractGetMethod(
        "balanceOf",
        [walletAddress],
        "dynamic",
        false,
        tokenAddress
      )
    );
    return Number(tokenBalance);
  } catch (error) {
    errorHelperContract(error, "call", "getTokenBalance");
    return 0;
  }
};

/**
 * this function is used to obtain tokens that will be received when removing liquidity based on withdraw percentage
 * @param data it is an object that contains various params required for this function
 * pairAddress of the respective pool
 * withdraw value (100,75,50,25)
 * userLiquidity indicates how much liquidity user holds in that respective pool
 * @returns an object with tokens addresses in the pool and its corresponding receivable values
 */

const getMinimumTokensBalancesFromPair = async (data: {
  pairAddress: string;
  withdrawValue: number;
  userLiquidity: any;
  dispatch: any;
}) => {
  try {
    const { pairAddress, withdrawValue, userLiquidity, dispatch } = data;
    const { tokenA, tokenB }: { tokenA: string; tokenB: string } =
      await getTokensFromPair(pairAddress, dispatch);
    if (tokenA && tokenB) {
      const tokenABalance: number = Number(await dispatch(
        callContractGetMethod(
          "balanceOf",
          [pairAddress],
          "dynamic",
          false,
          tokenA
        )
      ));
      const tokenBBalance: number = Number(await dispatch(
        callContractGetMethod(
          "balanceOf",
          [pairAddress],
          "dynamic",
          false,
          tokenB
        )
      ));
      const rawTotalSupply = await dispatch(callContractGetMethod("totalSupply", [], "pair", false, pairAddress));
      // console.log("Raw totalSupply:", rawTotalSupply);
      const totalSupply: number = Number(rawTotalSupply);
      // console.log("Converted totalSupply:", totalSupply);
      // const totalSupply: number = await Number(dispatch(
      //   callContractGetMethod("totalSupply", [], "pair", false, pairAddress)
      // ));
      // console.log("userLiquidity:", userLiquidity);
      // console.log("withdrawValue:", withdrawValue);
      // console.log("tokenABalance:", tokenABalance);
      // console.log("tokenBBalance:", tokenBBalance);
      // console.log("totalSupply:", totalSupply);
      const token1Receive: number =
      //(userLiquidity * withdrawValue * tokenABalance) / (totalSupply * 100);
        (Number(userLiquidity) * Number(withdrawValue) * tokenABalance) / (totalSupply * 100);
      const token2Receive: number =
      //(userLiquidity * withdrawValue * tokenBBalance) / (totalSupply * 100);
        (Number(userLiquidity) * Number(withdrawValue) * tokenBBalance) / (totalSupply * 100);

      const result: minimumBalanceUser = {
        tokenA,
        tokenB,
        token1Receive,
        token2Receive,
      };
      // console.log(`getMinimumTokensBalancesFromPair - Inputs:`, {pairAddress, withdrawValue, userLiquidity});
      // console.log(`getMinimumTokensBalancesFromPair - Outputs:`, {tokenA, tokenB, token1Receive, token2Receive});
      return result;
    }
  } catch (error) {
    errorHelperContract(error, "call", "getMinimumTokensBalancesFromPair");
    return 0;
  }
};
/**
 * this function is used to fetch token allowance for a particular walletAddress
 * @param data it is an object that contains various params required for this function
 * tokenAddress is the token address of which allowance is to be found
 * wallet Address of the user
 * @returns allowance
 */

const getTokenAllowance = async (data: {
  tokenAddress: string | number;
  dispatch: any;
  walletAddress: string;
}) => {
  try {
    const { tokenAddress, dispatch, walletAddress } = data;
    const res: string | number = await dispatch(
      callContractGetMethod(
        "allowance",
        [walletAddress, routerAddress],
        "dynamic",
        false,
        tokenAddress
      )
    );
    return res;
  } catch (error) {
    errorHelperContract(error, "call", "getTokenBalance");
    return 0;
  }
};

const getTokenApproval = async (data: {
  tokenAddress: string | number;
  dispatch: any;
  walletAddress: string;
}) => {
  try {
    const { tokenAddress, dispatch, walletAddress } = data;
    const res: string | number = await dispatch(
      callContractSendMethod(
        "approve",
        [routerAddress, MAX_APPROVAL],
        walletAddress,
        "dynamic",
        undefined,
        tokenAddress
      )
    );
    return res;
  } catch (error) {
    errorHelperContract(error, "call", "getTokenBalance");
    return 0;
  }
};

/**
 * this function is used to get pair Address for two particular tokens
 * @param data it is an object that contains various params required for this function
 * tokenOneAddress is the token address of first token
 * tokenTwoAddress is the token address of second token
 * @returns Pair Address
 */

const getPairService = async (data: {
  tokenOneAddress: string | undefined;
  tokenTwoAddress: string | undefined;
  dispatch: any;
}) => {
  try {
    const { tokenOneAddress, tokenTwoAddress, dispatch } = data;
    // console.log('tokenOneAddress:', tokenOneAddress);
    // console.log('tokenTwoAddress:', tokenTwoAddress);
    // console.log('Factory:', factoryAddress);
    const res: string = await dispatch(
      callContractGetMethod(
        "getPair",
        [tokenOneAddress, tokenTwoAddress],
        "factory",
        false,
        factoryAddress
      )
    );
    // console.log(`getPairService - Inputs:`, {tokenOneAddress, tokenTwoAddress});
    // console.log(`getPairService - Output:`, res);
    return res;
  } catch (error) {
    errorHelperContract(error, "call", "getTokenBalance");
    return "0";
  }
};

/**
 * this function is used to get tokens that the pair Address is made up of
 * @param pairAddress LP tokens pair address
 * @param dispatch function for handling async request
 * @returns an object containing two tokens
 */

const getTokensFromPair = async (pairAddress: any, dispatch: any) => {
  try {
    const tokenA: string = await dispatch(
      callContractGetMethod("token0", [], "pair", false, pairAddress)
    );
    const tokenB: string = await dispatch(
      callContractGetMethod("token1", [], "pair", false, pairAddress)
    );
    if (tokenA && tokenB) {
      const result: { tokenA: string; tokenB: string } = { tokenA, tokenB };
      return result;
    } else {
      const result: any = { tokenA: zeroAddress, tokenB: zeroAddress };
      return result;
    }
  } catch (error) {
    errorHelperContract(error, "call", "getTokenBalance");
    return 0;
  }
};

/**
 * this function is used to get optimal value of second asset, given input value of first asset
 * @param data it is an object that contains various params required for this function
 * amountIn is the input value
 * tokenOneAddress is the token address of first token
 * tokenTwoAddress is the token address of second token
 * max is boolean value to check if user has choosen max token amount or not from its wallet
 * @returns an array of two values first one being the input value and second one being the optimal value
 */

const getAmountsOutfunction = async (data: GET_AMOUNTS_DATA) => {
  try {
    const { amountIn, tokenOneAddress, tokenTwoAddress, dispatch } = data;
    const path: string[] = [tokenOneAddress, tokenTwoAddress];
    const res: string[2] | undefined = await dispatch(
      callContractGetMethod(
        "getAmountsOut",
        [amountIn, path],
        "router",
        false,
        routerAddress
      )
    );
    return res;
  } catch (error) {
    errorHelperContract(error, "call", "getAmountsOutfunction");

    return 0;
  }
};

/**
 * this function is used to get optimal value of first asset, given input value of second asset
 * @param data it is an object that contains various params required for this function
 * amountIn is the input value
 * tokenOneAddress is the token address of first token
 * tokenTwoAddress is the token address of second token
 * max is boolean value to check if user has choosen max token amount or not from its wallet
 * @returns an array of two values first one being the optimal value and second one being the input value
 */

const getAmountsInfunction = async (data: {
  amountIn: string;
  tokenOneAddress: string;
  tokenTwoAddress: string;
  dispatch: any;
}) => {
  try {
    const { amountIn, tokenOneAddress, tokenTwoAddress, dispatch } = data;
    const path: string[] = [tokenOneAddress, tokenTwoAddress];
    const res: string[2] | undefined = await dispatch(
      callContractGetMethod(
        "getAmountsIn",
        [amountIn, path],
        "router",
        false,
        routerAddress
      )
    );
    return res;
  } catch (error) {
    errorHelperContract(error, "call", "getAmountsInfunction");
    return 0;
  }
};

/**
 * this function is used to get the reserves of tokens in the pool
 * @param data it is an object that contains various params required for this function
 * tokenOneAddress this is the address of token one
 * tokenTwoAddress this is the address of token two
 * @returns reserves of two tokens in the pool
 */

const getReservesFunction = async (data: {
  tokenOneAddress: string | undefined;
  tokenTwoAddress: string | undefined;
  dispatch: any;
}) => {
  try {
    const { tokenOneAddress, tokenTwoAddress, dispatch } = data;
    const pairAddress = await getPairService(data);
    const res: any = await dispatch(
      callContractGetMethod("getReserves", [], "pair", false, pairAddress)
    );
    if (pairAddress) {
      const decimalsTokenOne: number = await dispatch(
        callContractGetMethod("decimals", [], "dynamic", false, tokenOneAddress)
      );
      const decimalsTokenTwo: number = await dispatch(
        callContractGetMethod("decimals", [], "dynamic", false, tokenTwoAddress)
      );
      // console.log('Decimals:', decimalsTokenOne, decimalsTokenTwo);
      // console.log('Reserves:', res._reserve0, res._reserve1);
      const reserve0Adjusted = Number(res._reserve0) / 10 ** Number(decimalsTokenOne);
      const reserve1Adjusted = Number(res._reserve1) / 10 ** Number(decimalsTokenTwo);
      // console.log(`Adjusted Reserves: ${reserve0Adjusted}, ${reserve1Adjusted}`);
      return { _reserve0: reserve0Adjusted, _reserve1: reserve1Adjusted };
    } else {
      return { _reserve0: 0, _reserve1: 0 };
    }
  } catch (error) {
    errorHelperContract(error, "call", "getReservesFunction");
    return { _reserve0: 0, _reserve1: 0 };
  }
};

const convertUsingTokenDecimals = async (
  tokenAddress: string | undefined,
  amount: string | 0 | undefined,
  dispatch: any
) => {
  try {
    let decimals: number | undefined = tokenCollection?.find(
      (data: any) => data?.address?.toLowerCase() == tokenAddress?.toLowerCase()
    )?.decimals;
    // console.log('Token Decimals:', decimals);
    decimals = decimals ? decimals : 18;
    // console.log(`Converting amount: ${amount} with decimals: ${decimals}`);
    //let amountstake: string = tofixFunctionSliced(Number(amount) / 10 ** decimals);

    let amountstake: string = "";
    if (amount !== undefined) {
      const amountBig = new Big(amount);
      const divisorBig = new Big(10).pow(decimals);
      const resultBig = amountBig.div(divisorBig);
      amountstake = resultBig.toString();

      amountstake = amountstake?.includes("e-") ? parseFloat(amountstake).toFixed(decimals).toString() : amountstake?.toString();
    }

      //Number(amount) > 1000000
      //  ? tofixFunctionSliced(Number(amount) / 10 ** decimals)
      //  : tofixFunctionSliced(Number(amount) / 10 ** decimals);
    return amountstake;
  } catch (error) {
    errorHelperContract(error, "call", "getReservesFunction");
    return "0";
  }
};

/**
 * helper function to give approval or allowance of tokens to limit order contract from the user
 * @param data it is an object that contains various params required for this function
 * address of the user
 * address of the token in the first input field
 * input value that user has entered in the first input field
 * @returns boolean if the user has approval/allowance or not
 */

const approvalAndAllowanceHelperForLimitOrder = async (data: {
  walletAddress: string;
  tokenOneAddress: string;
  dispatch: any;
}) => {
  const { walletAddress, tokenOneAddress, dispatch } = data;
  try {
    const limitcontract: smartContractType | undefined =
      dynamicContractDetails.find((a) => a.symbol == "limitOrder");
    const res: string = await dispatch(
      callContractGetMethod(
        "allowance",
        [walletAddress, limitcontract?.address],
        "dynamic",
        false,
        tokenOneAddress
      )
    );
    if (Number(res) > 0) {
      return true;
    } else {
      const res: any = await dispatch(
        callContractSendMethod(
          "approve",
          [limitcontract?.address, MAX_APPROVAL],
          walletAddress,
          "dynamic",
          undefined,
          tokenOneAddress
        )
      );
      if (res?.status) {
        return true;
      } else {
        return false;
      }
    }
  } catch (error) {
    errorHelperContract(
      error,
      "call",
      "approvalAndAllowanceHelperForLimitOrder"
    );
    return 0;
  }
};

/**
 * common function for both approval and allowance of tokens
 * @param data it is an object that contains various params required for this function
 * token other than native token
 * dispatch function
 * address of tokenOne,
 * address of tokenTwo,
 * walletAddress of user,
 * input value that user has entered in the first input field,
 * input value that user has entered in the second input field,
 * boolean indicating whether allowance os for swap or liquidity,
 * @returns boolean describing the result of the call
 */
const getAllowanceAndApprovalHelper = async (data: AllowanceAndApproval) => {
  try {
    const {
      customToken,
      dispatch,
      tokenOneAddress,
      tokenTwoAddress,
      walletAddress,
      inputOne,
      inputTwo,
      swap,
    } = data;
    let allowanceResultA: string | number = "0";
    let allowanceResultB: string | number = "0";
    if (customToken) {
      allowanceResultB = await getTokenAllowance({
        tokenAddress: customToken,
        dispatch,
        walletAddress,
      });
      allowanceResultB = Number(fromWeiConvert(allowanceResultB).split(".")[0]);
      if (Number(allowanceResultB) > Number(inputOne?.inputValue)) return true;
      else {
        const approval: string | number = await getTokenApproval({
          tokenAddress: customToken,
          dispatch,
          walletAddress,
        });
        const result: boolean = approval ? true : false;
        return result;
      }
    } else {
      allowanceResultA = await getTokenAllowance({
        tokenAddress: tokenOneAddress,
        dispatch,
        walletAddress,
      });

      allowanceResultB = await getTokenAllowance({
        tokenAddress: tokenTwoAddress,
        dispatch,
        walletAddress,
      });

      allowanceResultA = Number(fromWeiConvert(allowanceResultA).split(".")[0]);
      allowanceResultB = Number(fromWeiConvert(allowanceResultB).split(".")[0]);

      if (
        (Number(allowanceResultA) > Number(inputOne?.inputValue) &&
          Number(allowanceResultB) > Number(inputTwo?.inputValue) &&
          !swap) ||
        (swap && Number(allowanceResultA) > Number(inputOne?.inputValue))
      ) {
        return true;
      } else if (
        (!Number(allowanceResultA) &&
          Number(allowanceResultB) > Number(inputTwo?.inputValue) &&
          !swap) ||
        (swap && !Number(allowanceResultA))
      ) {
        const approval: string | number = await getTokenApproval({
          tokenAddress: tokenOneAddress,
          dispatch,
          walletAddress,
        });
        const result: boolean = approval ? true : false;
        return result;
      } else if (
        Number(allowanceResultA) > Number(inputOne?.inputValue) &&
        !Number(allowanceResultB) &&
        !swap
      ) {
        const approval: string | number = await getTokenApproval({
          tokenAddress: tokenTwoAddress,
          dispatch,
          walletAddress,
        });
        const result = approval ? true : false;
        return result;
      } else {
        let approval1: any;
        let approval2: any;
        approval1 = await getTokenApproval({
          tokenAddress: tokenOneAddress,
          dispatch,
          walletAddress,
        });
        if (!swap) {
          approval2 = await getTokenApproval({
            tokenAddress: tokenTwoAddress,
            dispatch,
            walletAddress,
          });
        }
        const result: boolean =
          approval1 && approval2 && !swap
            ? true
            : approval1 && swap
            ? true
            : false;
        return result;
      }
    }
  } catch (error) {
    return false;
  }
};

export {
  getTotalSupply,
  getMinimumTokensBalancesFromPair,
  getLPBalance,
  getNativeBalance,
  getTokenBalance,
  getTokenAllowance,
  getTokensFromPair,
  getTokenApproval,
  getPairService,
  getAmountsOutfunction,
  getAllowanceAndApprovalHelper,
  getAmountsInfunction,
  getReservesFunction,
  convertUsingTokenDecimals,
  getTokenBalanceForNewUser,
  approvalAndAllowanceHelperForLimitOrder,
};

import React, { useEffect, useState } from "react";
import CommonCard from "../CommonCard/CommonCard";
import "./OrderBook.scss";
import {
  DownArrow,
  DownRedArrowForBook,
  UpArrow,
} from "../../../../Assets/Images/Icons/SvgIcons";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { callApiPostMethod } from "../../../../Redux/Actions/api.action";
import {
  convertUsingTokenDecimals,
  getPairService,
  getReservesFunction,
} from "../../../../Services/contractCallService";
import { cryptoDecimals } from "../../../../Services/common.service";
import { setOrderPlaced } from "../../../../Redux/Slices/user.slice";
import { getReservesHelper } from "../../../Pages/Private/EarnAMMPools/EarnAMMHelper";
import { ReserveHelper, TOKENS } from "../../../../interfaces/commonInterfaces";
import { ORDER_BOOK } from "../../../../Redux/Actions/apiResponseInterfaces";
import { useTranslation } from "react-i18next";

const OrderBook = ({ className }: { className?: string }) => {
  const dispatch: any = useDispatch();
  const { t } = useTranslation();
  const { walletAddress }: { walletAddress: string; } = useSelector((state: any) => state?.user);
  const {
    tokenOne,
    tokenTwo,
  }: { tokenOne: TOKENS | undefined; tokenTwo: TOKENS | undefined } =
    useSelector((state: any) => state?.tokens);
  const limitOrderPlaced: boolean = useSelector(
    (state: any) => state?.user?.placedOrderSuccess
  );
  const [sellOrderData, setSellOrderBookData] = useState<any>([]);
  const [buyOrderData, setBuyOrderBookData] = useState<any>([]);
  const [Loader, setLoader] = useState<boolean>(false);
  const [lastOrderPrice, setLastOrderPrice] = useState<{
    price: number;
    type: string;
  }>({
    price: 0,
    type: "",
  });
  const [currentPrice, setCurrentPrice] = useState<number>(0);

  useEffect(() => {
    getOBData();
    getReservesFirstTime();
  }, [tokenOne, tokenTwo]);

  useEffect(() => {
    if (limitOrderPlaced) getOBData();
  }, [limitOrderPlaced]);

  const getReservesFirstTime = async () => {
    const data: ReserveHelper = {
      tokenOneAddress: tokenOne?.address,
      tokenTwoAddress: tokenTwo?.address,
      input1: 1,
      input2: 0,
      selectedField: "field1",
      dispatch,
    };
    const reserveData: string | undefined = await getReservesHelper(data);
    const calculatedBalance: string | number | undefined =
      await convertUsingTokenDecimals(
        data?.tokenTwoAddress,
        reserveData,
        dispatch
      );
    setCurrentPrice(Number(calculatedBalance) || 0);
  };

  /**
   * function to fetch and calculate order book data
   */
  const getOBData = async () => {
    setLoader(true);
    dispatch(setOrderPlaced(false));

    const pairAddress: string = await getPairService({
      tokenOneAddress: tokenOne?.address,
      tokenTwoAddress: tokenTwo?.address,
      dispatch,
    });
    // const { _reserve0, _reserve1 } = await getReservesFunction({
    //   tokenOneAddress: tokenOne?.address,
    //   tokenTwoAddress: tokenTwo?.address,
    //   dispatch,
    // });
    // setCurrentPrice(Number(_reserve0) / Number(_reserve1));
    const result: ORDER_BOOK = await dispatch(
      callApiPostMethod(
        "ORDER_BOOK",
        { pairAddress: pairAddress?.toLowerCase() },
        false,
        false
      )
    );
    result?.latestTrade?.rate
      ? setLastOrderPrice({
          price: result?.latestTrade?.rate,
          type:
            result?.latestTrade?.assetIn == tokenOne?.address?.toLowerCase()
              ? "Buy"
              : "Sell",
        })
      : setLastOrderPrice({ price: 0, type: "Buy" });
    const sellOrder = await result?.data?.map((value: any, index: any) => {
      let type =
        value?.assetIn == tokenOne?.address?.toLowerCase() ? "Buy" : "";
      if (type == "Buy") {
        return;
      } else {
        // value.price =
        //   1 /
        //   (Number(value?.assetInOrderPrice) /
        //     Number(value?.assetOutOrderPrice));
        value.price = Number(value?.rate);
        value.size = Number(value?.amountsIn);
        // value.value =
        //   Number(value?.amountsIn) *
        //   (value?.assetInOrderPrice / value?.assetOutOrderPrice);
        value.value = Number(value?.amountsOut);
        return value;
      }
    });
    const buyOrder = await result?.data?.map((value: any) => {
      let type =
        value?.assetIn == tokenOne?.address?.toLowerCase() ? "Buy" : "";

      if (type == "") {
        return;
      } else {
        // value.price =
        //   Number(value?.assetInOrderPrice) / Number(value?.assetOutOrderPrice);
        value.price = Number(value?.rate);

        value.size = Number(value?.amountsOut);
        // console.log("value.price", value.price);
        // value.value =
        //   Number(value?.amountsOut) *
        //   (value?.assetInOrderPrice / value?.assetOutOrderPrice);
        value.value = Number(value?.amountsIn);

        return value;
      }
    });

    const newBuyOrderfil = await buyOrder?.filter((item: any) => {
      return item != undefined;
    });
    const newSellOrderfil = await sellOrder?.filter((item: any) => {
      return item != undefined;
    });

    setSellOrderBookData(newSellOrderfil);
    setBuyOrderBookData(newBuyOrderfil);
    setLoader(false);
  };
  return (
    <CommonCard
      walletNotConnected={walletAddress ? true : false}
      cardTitle={t('orderBookTitle')}
      className={`OrderBookCard ${className || ""}`}
      contentBg
      loader={Loader}
    >
      <ul>
        <li>
          <h3>{t('orderBookPrice')}</h3>
          <h3>{t('orderBookSize')}</h3>
          <h3>{t('orderBookSum')}</h3>
        </li>
        {(sellOrderData?.length == 0 && buyOrderData?.length == 0) ||
        tokenOne?.symbol == "ETH" ||
        tokenTwo?.symbol == "ETH" ? (
          <li className="order_book_no_record">
            <p>No Record Found</p>
          </li>
        ) : (
          <>
            {sellOrderData?.map((item) => (
              <li>
                <p className="redText">
                  {cryptoDecimals(item?.price / 10 ** 18)}
                </p>
                <p>{cryptoDecimals(item?.size / 10 ** 18)}</p>
                <p>{cryptoDecimals(item?.value / 10 ** 18)}</p>
              </li>
            ))}
            <li className="dataHeading">
              <h2
                className={
                  lastOrderPrice?.type == "Buy" ? "greenText" : "redText"
                }
              >
                {lastOrderPrice?.price / 10 ** 18}
              </h2>

              {lastOrderPrice?.type == "Buy" ? (
                <span className="arrow_icon up_arrow">
                  <UpArrow />
                </span>
              ) : (
                <span className="arrow_icon down_arrow">
                  <UpArrow />
                </span>
              )}
              <h4>{cryptoDecimals(currentPrice) || 0}</h4>
            </li>

            {buyOrderData?.map((item) => (
              <li>
                <p className="greenText">
                  {cryptoDecimals(item?.price / 10 ** 18) || 0}
                </p>
                <p>{cryptoDecimals(item?.size / 10 ** 18) || 0}</p>
                <p>
                  {cryptoDecimals(
                    item?.value / 10 ** (tokenOne?.symbol == "USDT" ? 6 : 18)
                  ) || 0}
                </p>
              </li>
            ))}
          </>
        )}
      </ul>
    </CommonCard>
  );
};

export default OrderBook;

import { API_END_POINTS, RESPONSES_TYPE } from "./interfaces/utilsInterface";

export const APIURL: API_END_POINTS = {
  USER_ONBOARDING: `/createUser`,
  GET_USER_ONBOARDING: "/getUser",
  GET_POOLS: "/getPools",
  GET_GRAPH: "/getPairLiquidityGraph",
  GET_TOKENS_PAIRS: "/getTokenPairs",
  GET_NEWS: "https://newsfeed.deribit.com/v1/news?limit=20",
  GET_RECENT_TRANSACTIONS: "/getRecentTransactions",
  MY_ASSETS: "/userBalance",
  CREATE_LIMIT_ORDER: "/createLimitOrder",
  CREATE_STOP_LOSS_ORDER: "/createStopLossOrder",
  TRADE_HISTORY: "/userTradeHistory",
  DASHBOARD_TILES_DATA: "/userDashboardTiles",
  DOLLAR_VALUE: "/tokenDollarValue",
  DOLLAR_VALUE_CHANGES: "/tokenDollarValueChanges",
  PORTFOLIO_GRAPH_DATA: "/userPortfolio",
  FETCH_USER_DATA: "/fetchUserData",
  ORDER_BOOK: "/orderBook",
  USER_ORDER_HISTORY: "/userOrderHistory",
  CANCEL_LIMIT_ORDER: "/cancelLimitOrder",
  CANCEL_STOPLOSS_ORDER: "/cancelStopLossOrder",
  USER_INVESTMENTS: "/userInvestments",
  HOT_POOLS: "/trending-pairs",
  TOP_LIQUIDITY_POOLS: "liquidity-pools",
  GET_HOME_BANNERS: "/home-banners",
  SHARIAH_TOKENS: "tokens",

  //TESTNET
  GET_USER_TESTNET_TRANSACTIONS: "/traders",
  GET_USER_GTH_TESTNET_TRANSACTIONS: "/getUserGthTransactions",
};

// API Base URL
export const API_HOST = process.env.REACT_APP_URL_SITE_URL;
export const API_ADMIN_HOST = process.env.REACT_APP_ADMIN_ADDRESS;
export const ROUTER_ADDRESS = process.env.REACT_APP_ROUTER_CONTRACT_ADDRESS;
export const FACTORY_ADDRESS = process.env.REACT_APP_FACTORY_CONTRACT_ADDRESS;

// export const VIDEO_URL: string =
//   "https://testnet-libfi.fra1.digitaloceanspaces.com/";

export const VIDEO_URL: string = process.env.REACT_APP_VIDEO_URL || "";

export const getRecentTransactionPairAddress: string =
  "0x41130397e445a559c823b27702df7d2735a8ed1e";
export const WEBSITE_URL: string = "http://localhost:3000";
// s3 setup
export const S3_BUCKET: string = process.env.REACT_APP_S3_BUCKET || "";
export const REGION: string = process.env.REACT_APP_REGION || "";
export const accessKeyId = process.env.REACT_APP_ACCESS_KEY_ID;
export const secretAccessKey = process.env.REACT_APP_SECRET_ACCESS_KEY;
export const endpoint: string = process.env.REACT_APP_ENDPOINT || "";

export const CHAIN_ID: any = 421614;
export const RPC_URL = process.env.REACT_APP_RPC_URL || 'https://sepolia-rollup.arbitrum.io/rpc'; 

export const NETWORK_NAME: string = "Arbitrum Sepolia";
export const NETWORK_SYMBOL: string = "ETH";
export const NETWORK_DECIMALS = process.env.REACT_APP_DECIMALS;
export const EXPLORER_LINK = process.env.REACT_APP_EXPLORER_URL;

export const TOKEN_ADDRESS =
  process.env.REACT_APP_LIBFI_ADDRESS;
export const USDT_ADDRESS =
  process.env.REACT_APP_USDT_ADDRESS;
export const ICO_ADDRESS: string = "0xdb0Bbdb4692bb7a1d334Da0972C590718A98c07f";
export const MAX_APPROVAL: string = "0xfffffffffffffffffffffff";

export const BICONOMY_API_KEY: string = "";

export const RESPONSES: RESPONSES_TYPE = {
  SUCCESS: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NOCONTENT: 204,
  BADREQUEST: 400,
  UN_AUTHORIZED: 401,
  INVALID_REQ: 422,
  FORBIDDEN: 403,
  NOTFOUND: 404,
  TIMEOUT: 408,
  TOOMANYREQ: 429,
  INTERNALSERVER: 500,
  BADGATEWAYS: 502,
  SERVICEUNAVILABLE: 503,
  GATEWAYTIMEOUT: 504,
  // ZERO_LIQUIDITY:"ZERO_LIQUIDITY",
  // FAILURE: "FAILURE"
};

export const hundred: number = 100;
export const to18decimal: number = 1e18;
export const MAX_ALLOWANCE: any = process.env.REACT_APP_MAX_ALLOWANCE;
export const RPCURL: any = process.env.REACT_APP_RPC_URL;
export const SUCCESS: string = "SUCCESS";
export const FAILURE: string = "FAILURE";
export const INVALID: string = "INVALID";
export const ZERO_LIQUIDITY: string = "ZERO_LIQUIDITY";

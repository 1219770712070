import React, { useEffect, useState } from "react";
import "./MyInvestment.scss";
import ButtonCustom from "../../../Common/Button/ButtonCustom";
import { WalletIcon } from "../../../../Assets/Images/Icons/SvgIcons";
import CustomTable from "../../../Common/Table/Table";
import libertyfinanceIcon from "../../../../Assets/Images/Icons/tokens/libertyfinance-white.png";
import ethIcon from "../../../../Assets/Images/Icons/tokens/Eth.svg";
import usdcIcon from "../../../../Assets/Images/Icons/tokens/usdcIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { callApiPostMethod } from "../../../../Redux/Actions/api.action";
import { getTokensFromPair } from "../../../../Services/contractCallService";
import { dynamicContractDetails } from "../../../../Services/dynamicContractDetails";
import EarnModal from "../../../Common/Modals/EarnModal/EarnModal";
import SettingModal from "../../../Common/Modals/SettingModal/SettingModal";
import { cryptoDecimals } from "../../../../Services/common.service";
import { DATA, POOLS } from "../../../../Redux/Actions/apiResponseInterfaces";
import { useTranslation } from "react-i18next";

const MyInvestment = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const { t } = useTranslation();
  const userwalletAddress = useSelector(
    (state: any) => state?.user?.walletAddress
  );
  const globalPoolData = useSelector((state: any) => state?.user?.poolData);
  const [investmentData, setInvestmentData] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);
  const [showWalletModal, setShowWalletModal] = useState(false);

  const [poolData, setPoolData] = useState<POOLS["data"][1]>(DATA);
  const [ModalShow, setModalShow] = useState<boolean>(false);

  const [loader, SetLoader] = useState<boolean>(false);

  useEffect(() => {
    if (userwalletAddress) getUserInvestments();
  }, [userwalletAddress]);

  const handleShow = (show: boolean, userSelectedPool: any) => {
    setShow(show);
    setPoolData(userSelectedPool);
  };

  const getUserInvestments = async () => {
    SetLoader(true);
    const apiResult: any = await dispatch(
      callApiPostMethod(
        "USER_INVESTMENTS",
        { userAddress: userwalletAddress },
        false,
        false
      )
    );
    if (apiResult) {
      const outputData = await JSON.parse(JSON.stringify(apiResult?.data))?.map(
        async (data: any) => {
          const tokenData = await globalPoolData?.find((value: any) => {
            if (
              data?.pairAddress.toLowerCase() ==
              value?.pairAddress.toLowerCase()
            ) {
              return value;
            }
          });
          data.poolInfo = tokenData;
          let tokens = await getTokensFromPair(data?.pairAddress, dispatch);
          const tokenAInfo = dynamicContractDetails?.find(
            (info: any) =>
              info?.address?.toLowerCase() == tokens?.tokenA?.toLowerCase()
          );
          const tokenBInfo = dynamicContractDetails?.find(
            (info: any) =>
              info?.address?.toLowerCase() == tokens?.tokenB?.toLowerCase()
          );
          data.firstTokenURI = tokenAInfo?.img;
          data.secondTokenURI = tokenBInfo?.img;
          data.token0Name = tokenAInfo?.symbol;
          data.token1Name = tokenBInfo?.symbol;
          data.pool = tokenAInfo?.symbol + "/" + tokenBInfo?.symbol;
          data.hourlyfees = 0;
          data.weeklyfees = 0;
          //   data.liquidity =
          //   tofixFunction(totalLiquidity / to18decimal) || "0.00";

          // calculating my postions from user liquidity

          return data;
        }
      );
      let newwww = await Promise.all(outputData);
      setInvestmentData(newwww);
      SetLoader(false);
    }
  };
  return (
    <div className="investment_Sec">
      {!userwalletAddress ? (
        <div className="wallet_not_connected">
          <p>
            {t('welcomeLIBFI')}
          </p>
          <div className="invest_btn">
            <ButtonCustom
              title={
                <>
                  <span className="me-2">
                    <WalletIcon />
                  </span>
                  {t('connectWallet')}
                </>
              }
              show={showWalletModal}
              onClick={() => setShowWalletModal(true)}
            />
          </div>
        </div>
      ) : (
        <>
          <CustomTable
            fields={[
              {
                key: "pool",
                label: t('myInvestmentPool')
              },
              {
                key: "myLiquidity",
                label: t('myInvestmentLiquidity')
              },
              {
                key: "24hFees",
                label: t('myInvestment24HFees')
              },
              {
                key: "7dFees",
                label: t('myInvestment7DFees')
              },
              {
                key: "status",
                label: t('myInvestmentAPR')
              },
              {
                key: "",
                label: ""
              }]}
            loader={loader}
          >
            {investmentData.length > 0 &&
              investmentData.map((item) => {
                return (
                  <tr>
                    <td>
                      <span className="poolIcons">
                        <img
                          className="poolIconOne"
                          src={item.firstTokenURI}
                          alt=""
                        />
                        <img
                          className="poolIconTwo"
                          src={item.secondTokenURI}
                          alt=""
                        />
                      </span>
                      <span>
                        <span>{item.token0Name}</span>
                        <span>/</span>
                        <span>{item.token1Name}</span>
                      </span>
                    </td>
                    <td className="text-decoration">
                      {cryptoDecimals(item.totalLiquidityValue / 10 ** 18) || 0}
                    </td>
                    <td className="text-decoration">{item.hourlyfees}</td>
                    <td>{item.weeklyfees}</td>
                    <td className="text-decoration">-</td>
                    <td>
                      <ButtonCustom
                        className="bordered-blue manage_btn"
                        title={t('myInvestmentManage')}
                        onClick={() => {
                          handleShow(true, item?.poolInfo);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
          </CustomTable>
          <EarnModal
            show={show}
            handleClose={() => setShow(false)}
            showTab={"earn"}
            handlePools={getUserInvestments}
            showSettingsModal={() => {
              setModalShow(true);
            }}
            selectedPool={poolData || ""}
          />
          <SettingModal
            show={ModalShow}
            handleClose={() => {
              setModalShow(false);
            }}
          />
        </>
      )}
    </div>
  );
};

export default MyInvestment;

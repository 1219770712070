import "./Header.scss";
import FlexBanner from '../../Pages/Private/FlexBanner/FlexBanner';
import profileImg from "../../../Assets/Images/profile-img.png";
import {
  HelpIcon,
  NotificationIcon,
  SmallLogo,
  DotsBounce,
} from "../../../Assets/Images/Icons/SvgIcons";
import { useDispatch, useSelector } from "react-redux";
import {
  reset,
  userDetails,
  walletAddress,
} from "../../../Redux/Slices/user.slice";
import { useNavigate, Link } from "react-router-dom";
import { CHAIN_ID, RESPONSES, VIDEO_URL } from "../../../Utils";
import { Dropdown } from "react-bootstrap";
import { custmizeAddress } from "../../../Services/common.service";
import Tooltip from "../Tooltip/Tooltip";
import { handleUserProgress, updateUserLanguage } from "../../../Redux/Actions/user.action";
import { useEffect, useState } from "react";
import GetWallet from "../Modals/GetWallet/GetWallet";
import ProfileModal from "../Modals/ProfileModal/ProfileModal";
import { GET_USER } from "../../../Redux/Actions/apiResponseInterfaces";
import { callApiPostMethod, callAdminApiGetMethod } from "../../../Redux/Actions/api.action";
import RecentTrades from "../Cards/RecentTrades/RecentTrades";
import { useTranslation } from "react-i18next";
import { changeLanguage, supportedLngs } from "../../../i18n";

const Header = ({
  showSidebar,
  setShowSidebar,
}: {
  showSidebar?: boolean;
  setShowSidebar?: any;
}) => {
  const dispatch: any = useDispatch();
  const userwalletAddress: string = useSelector(
    (state: any) => state?.user?.walletAddress
  );
  const userDetailsData: any = useSelector(
    (state: any) => state?.user?.userDetails
  );

  const netWorkChainid: string | number = useSelector(
    (state: any) => state?.user?.NetworkChainId
  );
  const walletType: string = useSelector(
    (state: any) => state?.user?.walletType
  );

  const [userImage, setUserImage] = useState<string>(profileImg);
  const [showConnect, setShowConnect] = useState<boolean>(false);
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false);

  const disconnectWallet = () => {
    dispatch(reset());
    dispatch(walletAddress(""));
  };

  useEffect(() => {
    if (userwalletAddress) {
      setShowConnect(false);
      getUserDetails();
    }
  }, [userwalletAddress]);

  useEffect(() => {
    if (userwalletAddress) {
      if (userDetailsData?.image != null && userDetailsData?.image.trim() != "") {
        const profilePic: string = VIDEO_URL + userDetailsData?.image;
        setUserImage(profilePic);
      } else {
        setUserImage(profileImg);
      }
      if (userDetailsData?.language) { 
        changeLanguage(userDetailsData.language);
      }
    } else {
      setUserImage(profileImg);
    }
  }, [userDetailsData, userwalletAddress]);

  useEffect(() => {
    if (netWorkChainid != CHAIN_ID && walletType == "walletConnect") {
      setShowConnect(true);
    } else {
      setShowConnect(false);
    }
  }, [netWorkChainid]);

  const getUserDetails = async () => {
    const result: GET_USER | undefined = await handleUserProgress({
      dispatch,
      walletAddress: userwalletAddress,
      from: "Header",
    });
    if (result) dispatch(userDetails(result?.data));
  };

  const updateImage = async () => {
    getUserDetails();
  };

  // TESTNET
  const [numberOfGTHTransactions, setNumberOfGTHTransactions] = useState(0);
  const [numberOfSwaps, setNumberOfSwaps] = useState(0);
  const [numberOfAdd, setNumberOfAdd] = useState(0);
  const [numberOfRedeem, setNumberOfRedeem] = useState(0);
  const [isLoadingTestnetStats, setIsLoadingTestnetStats] = useState(true);
  const [isLoadingGthTransactions, setIsLoadingGthTransactions] = useState(true);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (userwalletAddress) {
      handleUserTestnetTransactions();
      handleUserGTHTestnetTransactions();
    }
  }, [userwalletAddress]);

  const handleToggleClick = (isOpen) => {
    if (isOpen) {
      handleUserTestnetTransactions();
      handleUserGTHTestnetTransactions();
    }
  };

  const handleUserTestnetTransactions = async () => {
    try {
      setIsLoadingTestnetStats(true);
      const result = await dispatch(
        callAdminApiGetMethod(
          'GET_USER_TESTNET_TRANSACTIONS',
          [userwalletAddress], // params
          false, // showToaster
          false // showLoader
        )
      );
      setNumberOfSwaps(result.TotalSwaps);
      setNumberOfAdd(result.mintTransactions);
      setNumberOfRedeem(result.burnTransactions);
    } catch (error) {
      return;
    } finally {
      setIsLoadingTestnetStats(false);
    }
  };

  const handleUserGTHTestnetTransactions = async () => {
    try {
      setIsLoadingGthTransactions(true);
      const result = await dispatch(
        callApiPostMethod(
          'GET_USER_GTH_TESTNET_TRANSACTIONS',
          { Wallet: userwalletAddress },
          false, // showToaster
          false // showLoader
        )
      );
      if (result?.data?.ofTransactions) {
        setNumberOfGTHTransactions(result?.data?.ofTransactions);
      }
    } catch (error) {
      return;
    } finally {
      setIsLoadingGthTransactions(false);
    }
  };

  const handleChangeLanguage = async (language) => { 
    changeLanguage(language);
    await updateUserLanguage({
      walletAddress: userwalletAddress,
      language,
      dispatch,
    });
  }

  return (
    <>
      <header className="Header">
          <FlexBanner
            title={t('onboardTestnetWelcome')}
            //ctaLink="https://google.com"
            ctaLink="/auth/testnet/guide"
            ctaTitle={t('onboardTestnetHelp')}
            isCenter={true}
            crossIconSize={12}
            animationTime={1.5}
            delayToShowBanner={0}
            //wrapperStyle={{ backgroundColor: "lightblue" }}
            mainStyleTitle={{ color: "white" }}
            mainStyleLink={{ color: "lightblue" }}
            crossStyle={{ color: "red" }}
          />
        <div className="HeaderInner">
          <div className="HeaderLogo d-md-none">
            <Link
              to={!userwalletAddress ? "/" : "/auth/dashboard/libfi"}
            >
              <SmallLogo />
            </Link>
          </div>
          <ul>
            <li>
              <button className="HeaderActionBtn">
                <NotificationIcon />
              </button>
              <button className="HeaderActionBtn">
                <HelpIcon />
              </button>
            </li>
            <li>
            <Dropdown>
              <Dropdown.Toggle className="HeaderLanguageDDL">
                {i18n.language?.toUpperCase()}
              </Dropdown.Toggle>
                <Dropdown.Menu>
                  {
                    Object.keys(supportedLngs).map((lang) => (<Dropdown.Item onClick={() => handleChangeLanguage(lang)}>{supportedLngs[lang]}</Dropdown.Item>))
                  }                  
              </Dropdown.Menu>
            </Dropdown>
            </li>
            <li>
              <Dropdown onToggle={handleToggleClick}>
                <Dropdown.Toggle className="HeaderActionBtn">
                  <img src={userImage} alt="" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {userwalletAddress ? (
                    <Dropdown.Item onClick={() => setShowProfileModal(true)}>
                      {t('profileMenuUpdateProfile')}
                    </Dropdown.Item>
                  ) : null}
                  {userwalletAddress && (
                    <Tooltip
                      className="wallet_text_tooltip"
                      content={userwalletAddress}
                      icon={
                        <Dropdown.Item href="" className="wallet_text">
                          {userwalletAddress
                            ? `${custmizeAddress(userwalletAddress)}`
                            : ""}
                        </Dropdown.Item>
                      }
                    />
                  )}

                  {/* Testnet */}
                  {userwalletAddress && (
                    <>
                      <Dropdown.Item>
                        {(t('profileMenuSwapCount'))} {isLoadingTestnetStats ? <DotsBounce /> : `${numberOfSwaps}`} {(t('profileMenuCount'))}
                      </Dropdown.Item>
                      <Dropdown.Item>
                        {(t('profileMenuAddCount'))} {isLoadingTestnetStats ? <DotsBounce /> : `${numberOfAdd}`} {(t('profileMenuCount'))}
                      </Dropdown.Item>
                      <Dropdown.Item>
                        {(t('profileMenuRedeemCount'))} {isLoadingTestnetStats ? <DotsBounce /> : `${numberOfRedeem}`} {(t('profileMenuCount'))}
                      </Dropdown.Item>
                      {numberOfGTHTransactions > 0 && (
                        <Dropdown.Item>
                          {t('profileMenuGTHTransactionCount')} {isLoadingGthTransactions ? <DotsBounce /> : `${numberOfGTHTransactions}`}
                        </Dropdown.Item>
                      )}
                    </>
                  )}
                  {/* Testnet */}

                  <Dropdown.Item>
                    {userwalletAddress ? (
                      <span onClick={() => disconnectWallet()}>{t('profileMenuDisconnect')}</span>
                    ) : (
                      <span
                        // onClick={() => connectToWallet("Metamask", dispatch)}
                        onClick={() => setShowConnect(true)}
                      >
                        {t('connectWallet')}
                      </span>
                    )}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="d-inline-flex d-md-none">
              <button
                onClick={() => setShowSidebar(!showSidebar)}
                className={`hamburger_menu ${showSidebar ? "active" : ""}`}
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
            </li>
          </ul>
        </div>
      </header>
      <ProfileModal
        show={showProfileModal}
        handleClose={() => setShowProfileModal(false)}
        updateImage={updateImage}
      />
      {showConnect ? (
        <GetWallet
          show={showConnect}
          handleClose={() => {
            setShowConnect(false);
          }}
        />
      ) : null}
    </>
  );
};

export default Header;

import React, { useEffect, useState, useRef } from "react";
import "./EarnOverview.scss";
import { Col, Row } from "react-bootstrap";
import sampleImg from "../../../../Assets/Images/news-sample2.png";
import { RightArrow } from "../../../../Assets/Images/Icons/SvgIcons";
import CustomTable from "../../../Common/Table/Table";
import { DownArrow } from "../../../../Assets/Images/Icons/SvgIcons";
import LibertyFinanceIcon from "../../../../Assets/Images/Icons/tokens/libertyfinance-white.png";
import ArbitrumIcon from "../../../../Assets/Images/Icons/tokens/arb.png";
import ethIcon from "../../../../Assets/Images/Icons/tokens/Eth.svg";
import usdtIcon from "../../../../Assets/Images/Icons/tokens/usdticon.svg";
import usdcIcon from "../../../../Assets/Images/Icons/tokens/usdcIcon.svg";
import wbtcIcon from "../../../../Assets/Images/Icons/tokens/bitcoin.svg";
import ButtonCustom from "../../../Common/Button/ButtonCustom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { callApiPostMethod } from "../../../../Redux/Actions/api.action";
import { Dispatch } from "redux";
import { setUserSubscribed } from "../../../../Redux/Slices/user.slice";
import { useTranslation } from "react-i18next";

const EarnOverview = () => {
  const { t } = useTranslation();
  const data = [
    {
      id: 1,
      tokenName: "ETH",
      tokenSubname: "ETHEREUM",
      tokenIcon: ethIcon,
      aprStart: "0.27",
      aprEnds: "276.68",
      duration: t('earnOverviewMixed'),
      otherTokens: [
        {
          id: 1,
          tokenName: `${t('earnAMMPool')}`,
          tokenIcon: ethIcon,
          aprStart: "0.27",
          aprEnds: "276.68",
          duration: t('earnOverviewMixed'),
        },
        {
          id: 2,
          tokenName: `${t('earnAssetStaking')}`,
          tokenIcon: ethIcon,
          aprStart: "0.27",
          aprEnds: "276.68",
          duration: t('earnOverviewMixed'),
        },
      ],
    },
    {
      id: 2,
      tokenName: "LIBFI",
      tokenSubname: "LIBERTY",
      tokenIcon: LibertyFinanceIcon,
      aprStart: "0.27",
      aprEnds: "276.68",
      duration: t('earnOverviewMixed'),
      otherTokens: [
        {
          id: 1,
          tokenName: `${t('earnAMMPool')}`,
          tokenIcon: LibertyFinanceIcon,
          aprStart: "0.27",
          aprEnds: "276.68",
          duration: t('earnOverviewMixed'),
        },
        {
          id: 2,
          tokenName: `${t('earnAssetStaking')}`,
          tokenIcon: LibertyFinanceIcon,
          aprStart: "0.27",
          aprEnds: "276.68",
          duration: t('earnOverviewMixed'),
        },
      ],
    },
    {
      id: 3,
      tokenName: "Arbitrum",
      tokenSubname: "ARB",
      tokenIcon: ArbitrumIcon,
      aprStart: "0.27",
      aprEnds: "276.68",
      duration: t('earnOverviewMixed'),
      otherTokens: [
        {
          id: 1,
          tokenName: `${t('earnAMMPool')}`,
          tokenIcon: ArbitrumIcon,
          aprStart: "0.27",
          aprEnds: "276.68",
          duration: t('earnOverviewMixed'),
        },
        {
          id: 2,
          tokenName: `${t('earnAssetStaking')}`,
          tokenIcon: ArbitrumIcon,
          aprStart: "0.27",
          aprEnds: "276.68",
          duration: t('earnOverviewMixed'),
        },
      ],
    },
    {
      id: 4,
      tokenName: "USDT",
      tokenSubname: "USDT",
      tokenIcon: usdtIcon,
      aprStart: "0.27",
      aprEnds: "276.68",
      duration: t('earnOverviewMixed'),
      otherTokens: [
        {
          id: 1,
          tokenName: `${t('earnAMMPool')}`,
          tokenIcon: usdtIcon,
          aprStart: "0.27",
          aprEnds: "276.68",
          duration: t('earnOverviewMixed'),
        },
        {
          id: 2,
          tokenName: `${t('earnAssetStaking')}`,
          tokenIcon: usdtIcon,
          aprStart: "0.27",
          aprEnds: "276.68",
          duration: t('earnOverviewMixed'),
        },
      ],
    },
    {
      id: 5,
      tokenName: "WETH",
      tokenSubname: "WETH",
      tokenIcon: ethIcon,
      aprStart: "0.27",
      aprEnds: "276.68",
      duration: t('earnOverviewMixed'),
      otherTokens: [
        {
          id: 1,
          tokenName: `${t('earnAMMPool')}`,
          tokenIcon: ethIcon,
          aprStart: "0.27",
          aprEnds: "276.68",
          duration: t('earnOverviewMixed'),
        },
        {
          id: 2,
          tokenName: `${t('earnAssetStaking')}`,
          tokenIcon: ethIcon,
          aprStart: "0.27",
          aprEnds: "276.68",
          duration: t('earnOverviewMixed'),
        },
      ],
    },
  ];

  const [active, setActive] = useState<number | null>(null);
  const navigate = useNavigate();
  const dispatch: Dispatch<any> = useDispatch();
  const handleSubscribe = async (assetName: any, poolOrStaking: any) => {
    if (poolOrStaking?.tokenName == `${t('earnAMMPool')}`) {
      navigate("/auth/amm-pools", {
        state: {
          assetName: assetName?.tokenName,
        },
      });
      dispatch(setUserSubscribed(true));
    } else {
      // navigate("/auth/asset-staking/all", {
      //   state: {
      //     assetName: assetName?.tokenName,
      //   },
      // });
      // dispatch(setUserSubscribed(true));
    }
  };
  window?.addEventListener("popstate", function (event) {
    navigate("/auth/dashboard/libfi");
  });

  // Check The Table and Add The Vertical Line Accordingly
  const [isLastColumnVisible, setIsLastColumnVisible] = useState(true);

  const tableRef = React.useCallback(node => {
    if (node !== null) {
      const lastColumn = node.querySelector('th:last-child');
      const handleResize = () => {
        if (lastColumn) {
          const rect = lastColumn.getBoundingClientRect();
          setIsLastColumnVisible(rect.right <= window.innerWidth);
          //console.log("Last Column Width:", rect.width, "Window Width:", window.innerWidth, "Is Visible:", rect.right <= window.innerWidth);
        }
      };
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  useEffect(() => {
    if (!isLastColumnVisible) {
      document.documentElement.style.setProperty('--VerticalLine', 'linear-gradient(90deg, rgba(242, 242, 242, 0) 0%, rgb(18, 18, 18) 100%)');
    } else {
      document.documentElement.style.setProperty('--VerticalLine', 'none');
    }
  }, [isLastColumnVisible]);

  return (
    <div className="EarnOverview">
      <div className="EarnOverviewHead">
        <Row>
          <Col md={6}>
            <div
              onClick={() => navigate("/auth/amm-pools")}
              className="EarnOverviewBox"
            >
              <img src={sampleImg} alt="" />
              <div className="me-3">
                <h4>{t('liquidityPools')}</h4>
                <p>{t('liquidityPoolsSubHeading')}</p>
              </div>
              <span className="rightArrow">
                <RightArrow />
              </span>
            </div>
          </Col>
          <Col md={6}>
            <div
              // onClick={() => navigate("/auth/asset-staking/all")}
              className="EarnOverviewBox"
            >
              <img src={sampleImg} alt="" />
              <div className="me-3">
                <h4>{t('amanaVaults')}</h4>
                <p>{t('amanaVaultsSubHeading')}</p>
              </div>
              <span className="rightArrow">
                <RightArrow />
              </span>
            </div>
          </Col>
        </Row>
      </div>
      <div>
        <h3 className="totalTokensTitle">{t('earnTotalInvestment')}</h3>
        <div className="TotalInvestmentTokens">
          <CustomTable fields={[
            {
              key: "token",
              label: t('earnTotalToken')
            },
            {
              key: "apr",
              label: t('earnTotalAPR')
            },
            {
              key: "duration",
              label: t('earnTotalDuration')
            },
            {
              key: "",
              label: ""
            }]} scrollingBar={true} tableRef={tableRef} >
            {data?.map((item) => (
              <React.Fragment key={item?.id}>
                <tr>
                  <td>
                    <span>
                      <img className="tokenIcon" src={item?.tokenIcon} alt="" />
                      <h5>
                        {item?.tokenName}{" "}
                        {item?.tokenSubname && <span>{item?.tokenSubname}</span>}
                      </h5>
                    </span>
                  </td>
                  <td>
                    <span>
                      <span>{item?.aprStart}</span>
                      <span>%</span>
                    </span>
                    <span className="m-1">-</span>
                    <span>
                      <span>{item?.aprEnds}</span>
                      <span>%</span>
                    </span>
                  </td>
                  <td>{item?.duration}</td>
                  <td
                    onClick={() =>
                      item?.id === active
                        ? setActive(null)
                        : setActive(item?.id)
                    }
                  >
                    {t('earnTotalSelect')}{" "}
                    <span
                      className={`downArrow ${
                        item?.id === active ? "active" : ""
                      }`}
                    >
                      <DownArrow />
                    </span>
                  </td>
                </tr>
                {item?.id === active &&
                  item?.otherTokens?.map((otherItems) => (
                    <tr className="OtherTokensRow" key={otherItems?.id}>
                      <td>
                        <img
                          className="tokenIcon"
                          src={otherItems?.tokenIcon}
                          alt=""
                        />
                        <h5>{otherItems?.tokenName}</h5>
                      </td>
                      <td>
                        <span>
                          <span>{otherItems?.aprStart}</span>
                          <span>%</span>
                        </span>
                        <span className="m-1">-</span>
                        <span>
                          <span>{otherItems?.aprEnds}</span>
                          <span>%</span>
                        </span>
                      </td>
                      <td>{otherItems?.duration}</td>
                      <td>
                        <ButtonCustom
                          className="bordered-blue"
                          title={t("earnOverviewEarnButton")}
                          onClick={() => {
                            handleSubscribe(item, otherItems);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </React.Fragment>
            ))}
          </CustomTable>
        </div>
        {/* <p className="loadMoreText">Load more</p> */}
      </div>
    </div>
  );
};

export default EarnOverview;

import React from 'react'
import './ComingSoon.scss';
import { useTranslation } from 'react-i18next';
// import { ClockIcon } from '../../../Assets/Images/Icons/SvgIcons';
const ComingSoon = () => {
    const { t } = useTranslation();
    return (
        <div className='coming_soon'>
            {/* <ClockIcon /> */}
            <h2>{t('comingSoon')}</h2>
        </div>
    )
}

export default ComingSoon

  import React, { useEffect, useState } from "react";
  import "./AddProfile.scss";
  import { Container } from "react-bootstrap";
  import CommonHeading from "../../../Common/Heading/Heading";
  import {
    CameraIcon,
    CloseIcon,
    EditIcon,
    UploadIcon,
  } from "../../../../Assets/Images/Icons/SvgIcons";

  import { useFormik } from "formik";
  import ButtonCustom from "../../../Common/Button/ButtonCustom";
  import { useDispatch, useSelector } from "react-redux";
  import { useLocation, useNavigate } from "react-router-dom";
  import { Dispatch } from "@reduxjs/toolkit";
  import { custmizeAddress } from "../../../../Services/common.service";
  import {
    createUserProgress,
    metamaskDisconnect,
  } from "../../../../Redux/Actions/user.action";
  import { loginSchema } from "../../../FormikSchemas/addProfileSchema";
  import InputCustom from "../../../Common/FormInputs/Input/Input";
  import { imageUpload } from "./uploadImageFile";
  import { setUserOnboardingData } from "../../../../Redux/Slices/user.slice";
  import { DATA_INSERTED, GET_USER } from "../../../../Redux/Actions/apiResponseInterfaces";
  import {
    handleUserProgress,
  } from "../../../../Redux/Actions/user.action";
  import { userDetails } from "../../../../Redux/Slices/user.slice";
  import { VIDEO_URL } from "../../../../Utils";
  import profileImg from "../../../../Assets/Images/profile-img.png";
  import { result } from "lodash";
import { useTranslation } from "react-i18next";

  const AddProfile = () => {
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const userwalletAddress: string = useSelector(
      (state: any) => state?.user?.walletAddress
    );
    const userDetailData: any = useSelector(
      (state: any) => state?.user?.userDetails
    );
    const [error, setError] = useState<string>("");

    const [imageUrl, setImageUrl] = useState<string>("");
    const [imagePath, setImagePath] = useState<string>("");

    useEffect(() => {
      window?.addEventListener("popstate", function (event) {
        navigate(location?.pathname);
      });
    }, [location]);

    useEffect(() => {
      metamaskDisconnect(dispatch);
    });

    const formik = useFormik({
      initialValues: {
        name: userDetailData?.name,
        email: userDetailData?.emailAddress,
      },
      // name and email validations
      validationSchema: () => loginSchema(t),
      onSubmit(values) {
        // append()
      },
    });

    const handleFileSelect = async (event: any) => {
      try {
        setError("");
        const file = event.target.files[0];
        const maxSize = 4 * 1024 * 1024; // Maximum file size in bytes (1MB in this example)
        const maxDimension = 1000; // Maximum allowed dimension (width or height) in pixels

        const allowedFormats = [
          "image/jpeg",
          "image/png",
          "image/gif",
          "image/bmp",
          "image/tiff",
          "image/svg+xml",
          "image/webp",
        ]; // Allowed image formats

        // Profile image type and size validations
        if (!allowedFormats.includes(file.type)) {
          setError(t('updateProfileInvalidImageFormatError'));
          return;
        }
        if (file?.size > maxSize) {
          setError((t('updateProfileFileSizeExceedError')).replace('{{size}}', '4MB'));
          return;
        }

        const image = new Image();
        image.onload = async () => {
          //const { width, height } = image;

          // Profile image dimensions validation
          //if (width > maxDimension || height > maxDimension) {
          //  setError(
          //    `Image dimensions exceed the maximum allowed values. Maximum width: ${maxDimension}px, Maximum height: ${maxDimension}px.`
          //  );
          //  return;
          //}

          // Set the image path and URL
          const result: any = await imageUpload(file,userwalletAddress + "_" + file.name);
          setImagePath(result?.request?.params?.Key);
          const imageUrl = URL.createObjectURL(file);
          setImageUrl(imageUrl);
        };

        image.src = URL.createObjectURL(file);
      } catch (err) {
        console.log(err);
      }
    };

    const handleDeleteImage = () => {
      setImageUrl("");
      setImagePath("");
      setError("");
    };
    const handleProfileSubmit = async () => {
      const result: DATA_INSERTED | false = await createUserProgress({
        value: 75,
        label: "terms-conditions",
        address: userwalletAddress,
        email: formik?.values?.email,
        name: formik?.values?.name,
        image: imagePath,
        dispatch,
        language: i18n.language,
        shariah: false
      });
      if (result) {
        dispatch(
          setUserOnboardingData({ progress: 75, path: "/terms-conditions" })
        );
        navigate("/terms-conditions");
      }
    };
    const handleSkip = async () => {
      dispatch(
        setUserOnboardingData({ progress: 75, path: "/terms-conditions" })
      );
      navigate("/terms-conditions");
    };


    // To manage the case where the user clicks on the back button when it is on the Terms & Conditions
    useEffect(() => {
      if (userwalletAddress) getUserDetails();
    }, []);
    
    const getUserDetails = async () => {
      const result: GET_USER | undefined = await handleUserProgress({
        dispatch,
        walletAddress: userwalletAddress,
        from: "Profile",
      });
      if (result) {
        if (result?.data != null && result?.data != undefined) {

          formik.setFieldValue("name", result.data.name);
          formik.setFieldValue("email", result.data.emailAddress);
          
          if (result.data.image) {
            setImageUrl(VIDEO_URL + result.data.image);
            setImagePath(result.data.image);
          }

        }
      }
    };



    return (
      <>
        <section className="AddProfile">
          <Container>
            <CommonHeading
              heading={
                userwalletAddress
                  ? `${t('onboardWalletGreeting')} ${custmizeAddress(userwalletAddress)}!`
                  : ""
              }
              subText={t('onboardWalletDesc')}
              centered
              className="AddProfileHeading"
            />
            <form>
              {!imageUrl ? (
                <div className="inputFileBox">
                  <label className="inputFile">
                    <input
                      type="file"
                      onChange={handleFileSelect}
                      accept="image/*"
                    />
                    <>
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt={t('updateProfileSelectedFile')}
                          className="profile_img"
                        />
                      ) : (
                        <>
                          <CameraIcon />
                          <span>{t('onboardAddAvatar')}</span>
                          {error && <p className="error_msg text-center">{error}</p>}
                        </>
                      )}
                    </>
                  </label>
                </div>
              ) : (
                <button type="button" className="inputFilePreview">
                  <img src={imageUrl} alt="" />
                  <div className="inputFilePreviewIcons">
                    <span onClick={handleDeleteImage}>
                      <CloseIcon />
                    </span>
                    <span>
                      <input
                        type="file"
                        onChange={handleFileSelect}
                        accept="image/*"
                      />
                      <EditIcon />
                    </span>
                  </div>
                </button>
              )}
              <InputCustom
                name="name"
                value={formik?.values?.name}
                onChange={(e: any) => formik?.handleChange(e)}
                maxLength={20}
                error={formik?.errors?.name ? formik?.errors?.name : null}
                label={
                  <>
                    {t('onboardProfileName')}
                  </>
                }
                className="inputField"
              />
              <InputCustom
                name="email"
                type="email"
                value={formik?.values?.email}
                onChange={(e: any) => {
                  formik?.handleChange(e);
                }}
                label={
                  <>
                    {t('onboardEmail')}
                  </>
                }
                isInvalid={formik?.touched?.email && !!formik?.errors?.email}
                className="inputField mb-0"
                error={formik?.errors?.email ? formik?.errors?.email : null}
              />
              <p className="usedText">
                {t('onboardEmailDesc')}
              </p>
              <ButtonCustom
                title={t('onboardSaveProfile')}
                fluid
                onClick={() => handleProfileSubmit()}
                className="buttonSpacing"
                disabled= { Boolean(formik?.errors?.name) || Boolean(formik?.errors?.email) }
              />
              <ButtonCustom
                title={t('onboardSkipStep')}
                fluid
                //className="secondary skipBtn"
                onClick={() => handleSkip()}
                // navigate="/terms-conditions"
              />
            </form>
          </Container>
        </section>
      </>
    );
  };

  export default AddProfile;

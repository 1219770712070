import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SubHeader from "../../../Common/SubHeader/SubHeader";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t } = useTranslation();
  const navigationLinks = [
    {
      id: 1,
      label: `${t('homeMyLibFi')}`,
      to: "/auth/dashboard/libfi",
    },
    {
      id: 2,
      label: `${t('homeMyPortfolio')}`,
      to: "/auth/dashboard/portfolio",
    },
    {
      id: 3,
      label: `${t('homeMyTradeHistory')}`,
      to: "/auth/dashboard/trade-history",
    },
  ];
  return (
    <>
      <SubHeader heading={t('homeDashboard')} navigationLinks={navigationLinks} />
      <Outlet />
    </>
  );
};

export default Dashboard;

import { callContractGetMethod } from "../../../../Redux/Actions/contract.action";
import {
  getLPBalance,
  getMinimumTokensBalancesFromPair,
  getPairService,
} from "../../../../Services/contractCallService";
import {
  FAILURE,
  INVALID,
  SUCCESS,
  hundred,
  to18decimal,
} from "../../../../Utils";
import moment from "moment";
import { calculateDeadlineHelper } from "./EarnAMMHelper";
import { storeInstance } from "../../../../Services/axios.service";
import { getRemoveLiquiditySignature } from "../../../../Services/getSignature";
import {
  localeStringFunction,
  removeLiquidityEthWithPermitService,
  removeLiquidityWithPermitService,
  removeLiquidityWithPermitServiceUSDT
} from "../../../../Services/contractSendService";
import { slicedValue } from "../../../../Services/common.service";
import { TOKEN_DETAILS } from "../../../../interfaces/commonInterfaces";
import { commonRemoveLiquidity } from "../../../../interfaces/contractCallInterfaces";

type removeLiquidityInfo = {
  tokenDetails: TOKEN_DETAILS;
  walletAddress: string;
  withdrawValue: number;
  dispatch: Function;
};
export const getUserInformationForRemovingLiquidity = async (
  data: removeLiquidityInfo
) => {
  const { tokenDetails, walletAddress, withdrawValue, dispatch } = data;

  const native: boolean =
    tokenDetails?.isTokenOneNative || tokenDetails?.isTokenTwoNative
      ? true
      : false;
  const nativeToken: string = tokenDetails?.isTokenOneNative
    ? tokenDetails?.tokenOneAddress
    : tokenDetails?.tokenTwoAddress;
  const customToken: string = tokenDetails?.isTokenOneNative
    ? tokenDetails?.tokenTwoAddress
    : tokenDetails?.tokenOneAddress;

  const pairAddress: string = await getPairService({
    tokenOneAddress: native ? nativeToken : tokenDetails?.tokenOneAddress,
    tokenTwoAddress: native ? customToken : tokenDetails?.tokenTwoAddress,
    dispatch,
  });
  const userLiquidity: any = await getLPBalance({
    pairAddress,
    walletAddress,
    dispatch,
  });
  let tokenAReceiveable: any;
  let tokenBReceiveable: any;
  let token1: any;
  let token2: any;
  let lpTokensToBurn: any;
  const { tokenA, tokenB, token1Receive, token2Receive }: any =
    await getMinimumTokensBalancesFromPair({
      pairAddress,
      withdrawValue,
      userLiquidity,
      dispatch,
    });
  const tokenADecimals: number = await dispatch(
    callContractGetMethod(
      "decimals",
      [],
      "dynamic",
      false,
      tokenDetails?.tokenOneAddress
    )
  );
  const tokenBDecimals: number = await dispatch(
    callContractGetMethod(
      "decimals",
      [],
      "dynamic",
      false,
      tokenDetails?.tokenTwoAddress
    )
  );
  if (tokenA == tokenDetails?.tokenOneAddress) {
    tokenAReceiveable = token1Receive / 10 ** Number(tokenADecimals);
    token1 = tokenDetails?.isTokenOneNative;
    tokenBReceiveable = token2Receive / 10 ** Number(tokenBDecimals);
    token2 = tokenDetails?.isTokenTwoNative;
  } else {
    tokenAReceiveable = token2Receive / 10 ** Number(tokenBDecimals);
    token1 = tokenDetails?.isTokenOneNative;
    tokenBReceiveable = token1Receive / 10 ** Number(tokenADecimals);
    token2 = tokenDetails?.isTokenTwoNative;
  }
  lpTokensToBurn = (Number(userLiquidity) * Number(withdrawValue)) / (hundred * to18decimal);
  const info = {
    tokenAReceiveable,
    tokenBReceiveable,
    token1,
    token2,
    lpTokensToBurn,
    isNative: native ? true : false,
    pairAddress,
    userLiquidity,
    customToken: native ? customToken : 0,
    nativeToken: native ? nativeToken : 0,
  };
  return info;
};

/**
 * helper function to execute remove liquidity vai signatures for both native and custom tokens
 * @param data it is an object that contains various params required for this function
 * hook to update values in redux
 * user withdraw value that he/she like to remove
 * fetching deadline and slippage from redux
 * object containing necessary information
 * user wallet address
 * minimum tokens that user will receive upon removing liquidity
 * memoized variable for token related details
 * @returns an object containing info related to remove liquidity
 */

type removeLiq = {
  dispatch: Function;
  withdrawValue: number;
  info: any;
  walletAddress: string;
  minimumReceiveTokens: {
    token1Receive: any;
    token2Receive: any;
    token1?: string;
    token2?: string;
  };
  tokenDetails: TOKEN_DETAILS;
  setModalData: Function;
  tokenA: string;
  tokenB: string;
  userLiquiditytoRemove: string;
  t: (key: string) => string
};

//const USDT_ADDRESS = process.env.REACT_APP_USDT_ADDRESS;
//const WETH_ADDRESS = process.env.REACT_APP_WETH_ADDRESS;

export const executeRemoveLiquidity = async (data: removeLiq) => {
  const {
    dispatch,
    withdrawValue,
    info,
    walletAddress,
    minimumReceiveTokens,
    tokenDetails,
    setModalData,
    tokenA,
    tokenB,
    userLiquiditytoRemove,
    t
  } = data;
  /////////////////////////      To Do in Case of slippage and dealdine /////////////////////////////
  const { slippage }: { slippage: number } = storeInstance?.getState()?.user;
  let deadline;
  try {
    // dispatch(setShowLoader(true));
    const tokenADecimals = await dispatch(
      callContractGetMethod(
        "decimals",
        [],
        "dynamic",
        false,
        tokenDetails?.tokenOneAddress
      )
    );
    const tokenBDecimals = await dispatch(
      callContractGetMethod(
        "decimals",
        [],
        "dynamic",
        false,
        tokenDetails?.tokenTwoAddress
      )
    );

    let deadLine = deadline ? deadline : await calculateDeadlineHelper();
    //let currentTime = new Date();
    //let deadLinetime = moment(currentTime).unix();
    //if (deadLine <= deadLinetime) {
    //  deadLine = await calculateDeadlineHelper();
    //}

    const native: boolean = info?.isNative ? true : false;

    setModalData({
      heading: t('ammPoolsSignHeading'),
      bodyText: (t('ammPoolsSignRedeenTrxText'))
        .replace('{{token1}}', tokenA || "")
        .replace('{{token2}}', tokenB || ""),
      status: "pending",
      txHash: null,
    });
    const signature: any = await getRemoveLiquiditySignature({
      walletAddress,
      pairAddress: info?.pairAddress,
      liquidity:
        withdrawValue != hundred ? userLiquiditytoRemove : info?.userLiquidity,
      deadLine,
      dispatch,
    });

    if (signature) {

      {/*
      const userMinimumAmount: any = {
        tokenA: native
          ? minimumReceiveTokens?.token1
            ? localeStringFunction(
                Number(minimumReceiveTokens?.token2Receive) * 10 ** Number(tokenBDecimals)
              )
            : localeStringFunction(
                Number(minimumReceiveTokens?.token1Receive) * 10 ** Number(tokenADecimals)
              )
          : localeStringFunction(
              Number(minimumReceiveTokens?.token1Receive) * 10 ** Number(tokenADecimals)
            ),
        tokenB: native
          ? minimumReceiveTokens?.token2
            ? localeStringFunction(
                Number(minimumReceiveTokens?.token2Receive) * 10 ** Number(tokenBDecimals)
              )
            : localeStringFunction(
                Number(minimumReceiveTokens?.token1Receive) * 10 ** Number(tokenADecimals)
              )
          : localeStringFunction(
              Number(minimumReceiveTokens?.token2Receive) * 10 ** Number(tokenBDecimals)
            ),
      };
    */}
      
    //const userMinimumAmount: any = {
    //  tokenA : localeStringFunction(Number(minimumReceiveTokens?.token1Receive) * 10 ** Number(tokenADecimals)),
    //  tokenB : localeStringFunction(Number(minimumReceiveTokens?.token2Receive) * 10 ** Number(tokenBDecimals)),
    //};

    //const tokenAIsUSDT = tokenDetails?.tokenOneName === "USDT";
    //const tokenBIsWETH = tokenDetails?.tokenTwoAddress === WETH_ADDRESS;

    let tokenAmin = localeStringFunction(
      Number(minimumReceiveTokens?.token1Receive) * 10 ** Number(tokenADecimals)
    );
    let tokenBmin = localeStringFunction(
      Number(minimumReceiveTokens?.token2Receive) * 10 ** Number(tokenBDecimals)
    );

    //if (tokenAIsUSDT) {
    //  [tokenAmin, tokenBmin] = [tokenBmin, tokenAmin];
    //}
    //  console.log(tokenAmin,tokenBmin)
    //  console.log(tokenDetails,tokenAIsUSDT,userMinimumAmount);
      const data: commonRemoveLiquidity = {
        liquidity: userLiquiditytoRemove,
        customTokenAddress: native ? info?.customToken : 0,
        tokenOneAddress: tokenDetails?.tokenOneAddress,
        tokenTwoAddress: tokenDetails?.tokenTwoAddress,
        tokenAmin: slicedValue(tokenAmin),
        tokenBmin: slicedValue(tokenBmin),
        approveMax: false,
        signature,
        deadLine,
        walletAddress,
        slippageTolerance: slippage,
        dispatch,
      };

      setModalData({
        heading: t('ammPoolsSignHeading'),
        bodyText: (t('ammPoolsSignSuccessText'))
          .replace('{{token1}}', tokenA || "")
          .replace('{{token2}}', tokenB || ""),
        status: "success",
        txHash: null,
      });
      //setTimeout(() => {}, 2000000); //dirty
      setModalData({
        heading: t('ammPoolsRemoveLiquidityHeading'),
        bodyText: (t('ammPoolsTrxConfirmText'))
          .replace('{{token1}}', tokenA || "")
          .replace('{{token2}}', tokenB || ""),
        status: "pending",
        txHash: null,
      });
      const result = native
        ? await removeLiquidityEthWithPermitService(data)
        //: (tokenA == "USDT" ? await removeLiquidityWithPermitServiceUSDT(data) : await removeLiquidityWithPermitService(data));
         : await removeLiquidityWithPermitService(data);
      if (result?.status) {
        setModalData({
          heading: t('ammPoolsRemoveLiquidityHeading'),
          bodyText: (t('ammPoolsTrxSuccessText'))
            .replace('{{token1}}', tokenA || "")
            .replace('{{token2}}', tokenB || ""),
          status: "success",
          txHash: result?.transactionHash,
        });
        // dispatch(setShowLoader(false));
        return SUCCESS;
      } else {
        setModalData({
          heading: t('ammPoolsRemoveLiquidityHeading'),
          bodyText: (t('ammPoolsRemoveLiquidityFailedText'))
            .replace('{{token1}}', tokenA || "")
            .replace('{{token2}}', tokenB || ""),
          status: "error",
          txHash: null,
        });
        // dispatch(setShowLoader(false));
        return FAILURE;
      }
    } else {
      // dispatch(setShowLoader(false));
      return INVALID;
    }
  } catch (error) {
    console.log("error in removeLiquidity", error);
  }
};

import React, { useEffect, useMemo, useState } from "react";
import TokenSelect from "../../FormInputs/TokenSelect/TokenSelect";
import "./StopOrders.scss";
import { InfoIcon, WalletIcon } from "../../../../Assets/Images/Icons/SvgIcons";
import CustomSelect from "../../FormInputs/Select/Select";
import ButtonCustom from "../../Button/ButtonCustom";
import { connectToWallet } from "../../../../Redux/Actions/user.action";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import {
  dynamicContractDetails,
  tokenCollection,
} from "../../../../Services/dynamicContractDetails";
import {useFetchTokenBalance} from "../../../../hooks/usefetchTokenBalance";
import {
  setOrderExpiredDays,
  settokenOne,
  settokenTwo,
} from "../../../../Redux/Slices/tokenSlice";
import { getReservesHelper } from "../../../Pages/Private/EarnAMMPools/EarnAMMHelper";
import { convertUsingTokenDecimals } from "../../../../Services/contractCallService";
import {
  cryptoDecimals,
  multiplyBigDigitsWithDecimals,
  tofixFunctionSliced,
} from "../../../../Services/common.service";
import { performLimitOrderOrStopOrderExecution } from "./LimitOrderHelper";
import TransactionModal from "../../Modals/TransactionDone/TransactionModal";
import Tooltip from "../../Tooltip/Tooltip";
import { ReserveHelper, TOKENS } from "../../../../interfaces/commonInterfaces";
import { useTranslation } from "react-i18next";
const Stoporders = () => {
  const dispatch: any = useDispatch();
  const userwalletAddress: string = useSelector(
    (state: any) => state?.user?.walletAddress
  );
  const tokenInfo: TOKENS[] = useSelector(
    (state: any) => state?.tokens?.tokenList
  );
  const { tokenOne, tokenTwo, timeList }: any = useSelector(
    (state: any) => state?.tokens
  );
  const userDropdownObject: any = useSelector(
    (state: any) => state?.tokens?.orderExpiredDays
  );

  const [stopPrice, setStopPrice] = useState<number>(0);
  const [show, setShow] = useState<boolean>(false);
  const [userValidstopPrice, setuserValidStopPrice] = useState<string | 0>("");
  const [stopPriceCheck, setStopPriceCheck] = useState<boolean>(false);
  const [ifUsertypedAmount, setifUsertypedAmount] = useState<boolean>(false);
  const [inputOne, setinputOne] = useState<any>({
    convertedValue: "",
    inputValue: "",
  });
  const [defaultSelectedTokens, setDefaultSelectedTokens] = useState<any>({
    token1: {},
    token2: {},
  });
  const [tokensList, setTokensList] = useState<any>([]);

  const [inputTwo, setinputTwo] = useState<any>({
    convertedValue: "",
    inputValue: "",
  });
  const [selectedOption, setSelectedOption] = useState<any>({
    optionOne: "",
    optionTwo: "",
  });
  const [modalData, setModalData] = useState<any>({
    heading: "",
    bodyText: "",
    status: "",
    txHash: "",
  });
  const { t } = useTranslation();

  useEffect(() => {
    tokenOptionsFunction();
    // dispatch(setShowLoader(false));
  }, [tokenInfo, tokenOne, tokenTwo]);

  useEffect(() => {
    if (inputOne?.inputValue == "") getReservesFirstTime();
  }, [inputOne]);

  const getReservesFirstTime = async () => {
    const data: ReserveHelper = {
      tokenOneAddress: tokenOne?.address,
      tokenTwoAddress: tokenTwo?.address,
      input1: 1,
      input2: 0,
      selectedField: "field1",
      dispatch,
    };
    const reserveData = await getReservesHelper(data);
    const calculatedBalance = await convertUsingTokenDecimals(
      data?.tokenTwoAddress,
      reserveData,
      dispatch
    );
    setuserValidStopPrice(calculatedBalance);
  };

  useEffect(() => {
    setLimitTokens();
  }, [tokenOne, tokenTwo]);

  const setLimitTokens = async () => {
    if (tokenOne?.symbol == "ETH") {
      const newTokenOne = tokenCollection.find((item: any) => {
        return item.symbol != tokenTwo.symbol && item.symbol != tokenOne.symbol;
      });
      dispatch(settokenOne(newTokenOne));
    } else if (tokenTwo?.symbol == "ETH") {
      const newTokenTwo = tokenCollection.find((item: any) => {
        return item.symbol != tokenOne.symbol && item.symbol != tokenTwo.symbol;
      });
      dispatch(settokenTwo(newTokenTwo));
    }
  };

  /**
   * memoized function to get details of both tokens
   */
  const tokenDetails: any = useMemo(() => {
    const token1 = tokenOne?.address;
    const token2 = tokenTwo?.address;
    const tokenData = dynamicContractDetails.find((a) => a.symbol == "ETH");
    return {
      tokenOneAddress: token1,
      tokenTwoAddress: token2,
      tokenOneName: tokenOne.symbol,
      tokenTwoName: tokenTwo.symbol,
      isTokenOneNative: tokenData?.address == token1 ? true : false,
      isTokenTwoNative: tokenData?.address == token2 ? true : false,
    };
  }, [tokenOne, tokenTwo]);

  /**
   * custom hook to fetch token Balance of the user
   */
  const { tokenBalance, fetchData } = useFetchTokenBalance({
    dispatch,
    tokenDetails,
  });

  /**
   * memoized function to get details of selected tokens by the user
   */
  const tokensFullInfo = useMemo(() => {
    return tokensList?.map((data: any) => {
      if (
        data?.address?.toLowerCase() == tokenOne?.address?.toLowerCase() ||
        data?.address?.toLowerCase() == tokenTwo?.address?.toLowerCase()
      ) {
        data.isSelected = true;
      } else {
        data.isSelected = false;
      }
      return data;
    });
  }, [tokensList]);

  /**
   * function to update when user selects a token
   * @param item object containing information about the selected token by the user
   * @param selectedField field in which user has selected the token
   */
  const SelectTokens = async (item: any, selectedField: any) => {
    emptyValues();
    if (selectedField == "field1") {
      setSelectedOption({
        optionOne: item,
        optionTwo: selectedOption?.optionTwo,
      });
      dispatch(settokenOne(item));
    } else if (selectedField == "field2") {
      setSelectedOption({
        optionOne: selectedOption?.optionOne,
        optionTwo: item,
      });
      dispatch(settokenTwo(item));
    }
  };

  /**
   * function to manipulate the list of tokens
   */
  const tokenOptionsFunction = async () => {
    const tokenList = JSON.parse(JSON.stringify(tokenInfo));
    const filteredList = tokenList.filter((item: any) => {
      return item?.symbol != "ETH";
    });
    const result = filteredList?.map((data: any) => {
      data.label = (
        <div className="token_option">
          <img src={data?.img} alt="token-icon" /> {data?.symbol}
        </div>
      );
      return data;
    });

    setDefaultSelectedTokens({
      token1: result?.find((data: any) => data?.symbol == tokenOne?.symbol),
      token2: result?.find((data: any) => data?.symbol == tokenTwo?.symbol),
    });
    setTokensList(result);
  };

  /**
   * function to handle dispplaying of tokens and its calculation when user clicks on switch button
   */
  const handleSwitch = async () => {
    // dispatch(setgraphtokenInterchangeState(!graphData));
    const tokenList = JSON.parse(JSON.stringify(tokenInfo));
    const result = tokenList?.map((data: any) => {
      data.label = (
        <>
          <img src={data?.img} alt="token-icon" /> {data?.symbol}
        </>
      );
      return data;
    });
    const token1 = result?.find(
      (data: any) => data?.symbol == tokenOne?.symbol
    );
    const token2 = result?.find(
      (data: any) => data?.symbol == tokenTwo?.symbol
    );

    dispatch(settokenOne(token2));
    dispatch(settokenTwo(token1));

    setinputOne({
      convertedValue: inputTwo?.convertedValue,
      inputValue: inputTwo?.inputValue,
    });
    const reservesData = {
      tokenOneAddress: tokenOne?.address,
      tokenTwoAddress: tokenTwo?.address,
      input1: 0,
      input2: inputTwo?.inputValue,
      selectedField: "field2",
      max: false,
      dispatch,
    };
    const reservesResult = await getReservesHelper(reservesData);
    const calculatedBalance = await convertUsingTokenDecimals(
      tokenOne?.address,
      reservesResult,
      dispatch
    );
    if (Number(calculatedBalance)) {
      setinputTwo({
        convertedValue: reservesResult,
        inputValue: calculatedBalance,
      });
    }

    setStopPrice(
      tofixFunctionSliced(
        Number(reservesResult ?? 0) /
          Number(inputTwo?.inputValue * 10 ** tokenTwo?.decimals)
      ) || 0
    );
  };

  /**
   * function to handle both inputs fields
   * @param e value that the user has entered
   * @param field field in which user has entered
   */
  const handleChange = async (e: any, field: string) => {
    let values: any;
    const maxLength = 26;
    const regexHere = /^(\d+)?([.]?\d{0,6})?$/;
    const isInputValid = regexHere.test(e);
    if (isInputValid) {
      values = e;
    } else {
      return;
    }
    if (e?.length <= maxLength) {
      values = e;
    } else if (e?.length > maxLength) {
      return;
    }

    if (
      values?.length > 1 &&
      Array.from(values)[0] == "0" &&
      Array.from(values)[1] != "."
    ) {
      values = values?.slice(1);
    }

    if (values?.toString().charAt(0) == ".") {
      values = "0" + values;
    }

    // let decimals = 18;
    // if (values?.toString()?.length > 8) return;
    setifUsertypedAmount(true);
    let fieldCondition: boolean = field == "field1" ? true : false;
    if (!values && !values?.toString()?.includes(".") && fieldCondition) {
      setinputOne({
        convertedValue: "",
        inputValue: "",
      });
      setinputTwo({
        convertedValue: "",
        inputValue: "",
      });
      // setTimeout(() => {
      //   emptyValues(1);
      // }, 100);
    } else {
      let inputValue = values;
      const tokenInfo = fieldCondition ? tokenOne : tokenTwo;
      let inputSetter = fieldCondition ? setinputOne : setinputTwo;
      inputSetter({
        convertedValue: inputValue * 10 ** tokenInfo?.decimals,
        inputValue: inputValue,
      });
      if (fieldCondition) {
        const reservesData = {
          tokenOneAddress: tokenOne?.address,
          tokenTwoAddress: tokenTwo?.address,
          input1: inputValue,
          input2: 0,
          selectedField: "field1",
          max: false,
          dispatch,
        };
        const reserveData: any = await getReservesHelper(reservesData);
        const calculatedBalance = await convertUsingTokenDecimals(
          reservesData?.tokenTwoAddress,
          reserveData,
          dispatch
        );
        if (Number(calculatedBalance) > 0) {
          setinputTwo({
            convertedValue: reserveData,
            inputValue: calculatedBalance,
          });
          setStopPrice(
            tofixFunctionSliced(
              Number(reserveData ?? 0) /
                Number(inputValue * 10 ** tokenTwo?.decimals)
            ) || 0
          );
        }
      }
      setStopPriceCheck(false);
      // else {
      //   setStopPrice(
      //     tofixFunctionSliced(
      //       Number(inputValue * 10 ** tokenTwo?.decimals ?? 0) /
      //         Number(inputOne?.convertedValue)
      //     ) || 0
      //   );
      // }
    }
  };

  /**
   * function to handle input field of limit rate
   * @param e value entered in the field
   */
  const handleStopPrice = async (e) => {
    let values: any;
    const maxLength = 26;
    const regexHere = /^(\d+)?([.]?\d{0,6})?$/;
    const isInputValid = regexHere.test(e);
    if (isInputValid) {
      values = e;
    } else {
      return;
    }
    if (e?.length <= maxLength) {
      values = e;
    } else if (e?.length > maxLength) {
      return;
    }

    if (
      values?.length > 1 &&
      Array.from(values)[0] == "0" &&
      Array.from(values)[1] != "."
    ) {
      values = values?.slice(1);
    }

    if (values?.toString().charAt(0) == ".") {
      values = "0" + values;
    }
    setifUsertypedAmount(false);
    if (!values && !values?.toString()?.includes(".")) {
      emptyValues(1);
    } else {
      setuserValidStopPrice(values);
      if (Number(e) <= Number(stopPrice)) {
        setinputTwo({
          convertedValue: multiplyBigDigitsWithDecimals(
            e,
            tokenTwo?.symbol == "USDT"
              ? (inputOne?.inputValue * 10 ** 6).toString()
              : tokenOne?.symbol == "USDT"
              ? (inputOne?.inputValue * 10 ** 18).toString()
              : inputOne?.convertedValue.toString()
          ),
          inputValue: multiplyBigDigitsWithDecimals(e, inputOne?.inputValue),
        });
        setStopPriceCheck(false);
      } else {
        setinputTwo({
          convertedValue: multiplyBigDigitsWithDecimals(
            e,
            inputOne?.convertedValue.toString()
          ),
          inputValue: multiplyBigDigitsWithDecimals(e, inputOne?.inputValue),
        });
        setStopPriceCheck(true);
      }
    }
  };

  const handleClose = async () => {
    setShow(false);
    await emptyValues();
  };

  const handleOpen = () => setShow(true);

  /**
   * function to handle time value when user selects on the time duration list
   * @param data object containing info about the selected time
   */
  const handleTimeSelect = (data: any) => {
    dispatch(setOrderExpiredDays(data));
  };

  /**
   * function to perform limit order execution
   */
  const executeStopOrder = async () => {
    const currentTime = Math.floor(Date.now() / 1000);
    if (
      Number(tokenBalance?.token1BalanceConverted) <
      Number(inputOne?.convertedValue)
    ) {
      return;
    } else {
      handleOpen();
      const result = await performLimitOrderOrStopOrderExecution(
        tokenDetails,
        inputOne,
        inputTwo,
        userValidstopPrice || stopPrice,
        Number(currentTime) + Number(userDropdownObject?.convertedTime),
        dispatch,
        setModalData,
        "StopLoss",
        t
      );
      if (result) {
        fetchData();
      }
    }
  };

  /**
   * function to empty input fields
   * @param type value to check if only limit value should be emptied or not
   */

  const emptyValues = async (type?: number) => {
    setuserValidStopPrice("");
    // setStopPrice("");
    if (!type) {
      setinputOne({
        convertedValue: "",
        inputValue: "",
      });
    }
    setinputTwo({
      convertedValue: "",
      inputValue: "",
    });
  };
  const isFormValid =
    inputOne?.inputValue !== "" &&
    inputTwo.inputValue !== "" &&
    inputOne?.inputValue?.split(".")[1] !== "" &&
    inputTwo?.inputValue?.split(".")[1] !== "";

  const insuuficientBalance =
    Number(tokenBalance?.token1BalanceConverted) <
      Number(inputOne?.convertedValue) && userwalletAddress != "";
  return (
    <div className="StopOrders" style={{ pointerEvents: 'none' }}>
      <div className='coming_soon'  style={{ top: '50px', pointerEvents: 'none' }}>
            {/* <ClockIcon /> */}
            <h2>{t('comingSoon')}</h2>
        </div>
      <div className="StopOrdersBox">
        <div>
          <input
            className="amountInput"
            type="text"
            id="sell"
            placeholder="0.0"
            value={inputOne?.inputValue}
            onChange={(e) => {
              e.preventDefault();
              handleChange(e?.target?.value, "field1");
            }}
            autoComplete="off"
            maxLength={8}
          />
        </div>
        <div className="text-end">
          <p className="StopCardBoxTxt">
            {t('tradeStopOrdersBalance')}:{" "}
            <span>
              {userwalletAddress
                ? cryptoDecimals(tokenBalance?.token1Balance)
                : 0}
            </span>
          </p>
          <TokenSelect
            className="ms-auto"
            options={tokensFullInfo}
            dropIcon
            defaultValue={defaultSelectedTokens?.token1}
            selectedField="field1"
            selectedOption={selectedOption?.optionOne}
            SelectTokens={SelectTokens}
          />
        </div>
      </div>
      <div className="StopOrdersBox">
        <div>
          <input
            className="amountInput"
            type="text"
            id="buy"
            placeholder="0.0"
            disabled={true}
            value={inputTwo?.inputValue}
            // onChange={(e) => {
            //   e.preventDefault();
            //   handleChange(e?.target?.value, "field2");
            // }}
            autoComplete="off"
            // maxLength={8}
          />
        </div>
        <div className="text-end">
          <p className="StopCardBoxTxt">
            {t('tradeStopOrdersBalance')}:{" "}
            <span>
              {userwalletAddress
                ? cryptoDecimals(tokenBalance?.token2Balance)
                : 0}
            </span>
          </p>
          <TokenSelect
            className="ms-auto"
            options={tokensFullInfo}
            dropIcon
            defaultValue={defaultSelectedTokens?.token2}
            selectedField="field2"
            selectedOption={selectedOption?.optionTwo}
            SelectTokens={SelectTokens}
          />
        </div>
      </div>
      <div className="StopOrdersMore">
        <div className={stopPriceCheck ? "StopCardMoreDanger" : ""}>
          <p>
            {(t('tradeStopPrice')).replace('{{asset}}', tokenOne?.symbol)}
            <Tooltip
              content={
                stopPriceCheck
                  ? t('tradeStopOrdersPriceHigher')
                  : t('tradeStopOrdersPlaceOrder')
              }
            />
          </p>
          {/* <h4>3.12</h4> */}
          <input
            className="amountInput"
            placeholder="0.0"
            onChange={(e) => {
              e.preventDefault();
              handleStopPrice(e.target.value);
            }}
            value={
              ifUsertypedAmount && inputOne.inputValue
                ? stopPrice
                : userValidstopPrice
            }
            maxLength={10}
          />
        </div>
        <div>
          <p className="justify-content-end">
            {t('tradeStopOrdersExpiry')}
            <span>
              <InfoIcon />
            </span>
          </p>
          <CustomSelect
            className="ms-auto"
            options={timeList}
            defaultValue={
              !userDropdownObject ? timeList[5] : userDropdownObject
            }
            onChange={(e: any) => {
              handleTimeSelect(e);
            }}
          />
        </div>
      </div>
      {userwalletAddress ? (
        <ButtonCustom
          fluid
          title={
            <>
              <span className="me-2">
                <WalletIcon />
              </span>
              {insuuficientBalance
                ? (t('tradeStopOrdersInsufficient')).replace('{{asset}}', tokenOne?.symbox)
                : // : stopPriceCheck
                  // ? "Price is higher than the current market Price"
                  t('tradeStopOrdersPlaceOrder')}
            </>
          }
          onClick={() => executeStopOrder()}
          disabled={
            (ifUsertypedAmount && inputOne.convertedValue == 0) ||
            (ifUsertypedAmount && inputTwo.convertedValue == 0) ||
            !isFormValid ||
            !userwalletAddress ||
            insuuficientBalance ||
            stopPriceCheck
          }
        />
      ) : (
        <ButtonCustom
          onClick={() => connectToWallet("Metamask", dispatch)}
          fluid
          title={
            <>
              <span className="me-2">
                <WalletIcon />
              </span>
              {t('connectWallet')}
            </>
          }
        />
      )}
      <>
        {show ? (
          <TransactionModal
            show={show}
            modalData={modalData}
            handleClose={handleClose}
            handleFunction={executeStopOrder}
          />
        ) : null}
      </>
    </div>
  );
};

export default Stoporders;

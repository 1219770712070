import React, { Dispatch, ReactNode } from "react";
import "./SubHeader.scss";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveTabs } from "../../../Redux/Slices/user.slice";
import ButtonCustom from "../Button/ButtonCustom";
import { LeftArrow } from "../../../Assets/Images/Icons/SvgIcons";
import { useTranslation } from "react-i18next";

type propTypes = {
  backBtn?: boolean;
  heading?: string;
  navigationLinks?: {
    id: number;
    label?: string | ReactNode;
    to?: string;
    alterLocation?: string[];
    key?: any;
  }[];
  className?: string;
};

const SubHeader = (props: propTypes) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch: Dispatch<any> = useDispatch();

  const handleTabs = (item: any) => {
    dispatch(saveTabs(item?.label?.props?.children[0]));
  };

  const assetName = location?.state?.pageName;

  return (
    <div className={`SubHeader ${props?.className || ""}`}>
      <div className="SubHeaderInner">
        {props?.heading && (
          <h3>
            {props?.backBtn && (
              <button
                className="left_arrow"
                onClick={() =>
                  navigate(
                    assetName == "MyAssets"
                      ? "/auth/dashboard/portfolio"
                      : assetName == "Professional"
                      ? "/auth/trade/professional/market"
                      : "/auth/dashboard/libfi"
                  )
                }
              >
                <LeftArrow />
              </button>
            )}
            {props?.heading}:
          </h3>
        )}
        {props?.navigationLinks && (
          <ul>
            
            {props?.navigationLinks?.map((item) => (
              <li
                key={item?.id}
                title={
                  item?.label == t('tradeNavProfessional') || item?.label == t('tradeNavAdvanced')
                    ? t('comingSoon')
                    : ""
                }
              >
                <NavLink
                  // className={
                  //   // item?.alterLocation?.filter(
                  //   //   (item) => item === location.pathname
                  //   // ).length
                  //   location.pathname == item?.to ? "active" : ""
                  // }
                  
                  // className={     
                  //  item?.alterLocation?.filter(
                  //    (item) => item === location.pathname
                  //  ).length
                  //    ? "active"
                  //    : ""
                  //  } 

                  to={item?.to || ""}
                  onClick={(e: any) =>
                    // item?.label == "Professional" ||
                    item?.label === t('tradeNavAdvanced') ||
                    item?.key === "limit" ||
                    item?.key === "stop" ||
                    // item?.label == "My Investments" ||
                    // item?.label == "My Portfolio" ||
                    item?.label == t('homeMyTradeHistory')
                      ? // item?.label == "My Portfolio"
                        // item?.key == "Limit"
                        // item?.key == "Stop"
                        e.preventDefault()
                      : handleTabs(item)
                  }
                >
                  {item?.label}
                </NavLink>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SubHeader;

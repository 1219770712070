import { useEffect, useState, useCallback } from "react";
import {
  getNativeBalance,
  getTokenBalance,
} from "../Services/contractCallService";
import { storeInstance } from "../Services/axios.service";
import { TOKEN_DETAILS, TOKEN_DETAILS_SINGLE } from "../interfaces/commonInterfaces";

export const useFetchTokenBalance = ({
  dispatch,
  tokenDetails,
}: {
  dispatch: any;
  tokenDetails: TOKEN_DETAILS;
}) => {
  const [fetchingBalance, setFetchingBalance] = useState<boolean>(false);
  const walletAddress: string = storeInstance?.getState()?.user?.walletAddress;

  const [tokenBalance, settokenBalance] = useState<{
    token1Balance: number;
    token1BalanceConverted: number;
    token2Balance: number;
    token2BalanceConverted: number;
  }>({
    token1Balance: 0,
    token1BalanceConverted: 0,
    token2Balance: 0,
    token2BalanceConverted: 0,
  });

  const fetchData = useCallback(async () => {
    // console.log(`Fetching token balances for wallet: ${walletAddress}`);
    // console.log(`Token details:`, tokenDetails);

    setFetchingBalance(true); // Set fetchingBalance to true when fetching starts

    if (walletAddress) {
      const {
        isTokenOneNative,
        isTokenTwoNative,
        tokenOneAddress,
        tokenTwoAddress,
      } = tokenDetails;

      const token1Details: TOKEN_DETAILS_SINGLE | undefined = { tokenAddress: tokenOneAddress, isTokenNative: isTokenOneNative };
      const token2Details: TOKEN_DETAILS_SINGLE | undefined = { tokenAddress: tokenTwoAddress, isTokenNative: isTokenTwoNative };
      const token1Balance = await fetchTokenBalance({ dispatch, tokenDetails: token1Details });
      const token2Balance = await fetchTokenBalance({ dispatch, tokenDetails: token2Details });

      settokenBalance({
        token1Balance: token1Balance?.tokenBalance || 0,
        token2Balance: token2Balance?.tokenBalance || 0,
        token1BalanceConverted: token1Balance?.tokenBalanceConverted,
        token2BalanceConverted: token2Balance?.tokenBalanceConverted,
      });
      setFetchingBalance(false);
    }
  }, [walletAddress, tokenDetails, dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { tokenBalance, fetchData, fetchingBalance };
};

export const fetchTokenBalance = async ({
  dispatch,
  tokenDetails
}: {
  dispatch: any,
  tokenDetails: TOKEN_DETAILS_SINGLE
}) => {
  const walletAddress: string = storeInstance?.getState()?.user?.walletAddress;
  if (walletAddress) {
    const {
      isTokenNative,
      tokenAddress,
    } = tokenDetails;

    let res: any;

    const WETH_ADDRESS = "0x22a4c090bd7744aa94be47fe368240de5c000920";

    if (isTokenNative || tokenAddress === WETH_ADDRESS) {
      res = await getNativeBalance(walletAddress);
    } else {
      res = await getTokenBalance({
        tokenAddress: tokenAddress,
        dispatch,
        walletAddress,
      });
    }
    return {
      tokenBalance: res?.calculatedBalance || 0,
      tokenBalanceConverted: res?.res
    };
  }
};
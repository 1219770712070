// import { sendTransactionLogsData } from "../../../../Services/apiServices/ApiServicesModel";
import { dynamicContractDetails } from "./dynamicContractDetails";
import { callContractSendMethod } from "../Redux/Actions/contract.action";
import { errorHelperContract, slicedValue } from "./common.service";
import { commonSwapData } from "../interfaces/contractCallInterfaces";
let hundred: number = 100;
const localeStringFunction = (value: number | any) => {
  return value?.toLocaleString("fullwide", {
    useGrouping: !1,
  });
};
const routerAddress: string | undefined = dynamicContractDetails?.find(
  (data) => data.symbol == "router"
)?.address;

const swapTokensForExactETH = async (data: commonSwapData) => {
  const {
    walletAddress,
    amountOut,
    amountInMax,
    path,
    to,
    deadLine,
    slippageTolerance,
    dispatch,
  } = data;
  try {
    let amountInMaxWithSlippageTolerance: string =
      (amountInMax / hundred) * slippageTolerance == 0
        ? amountInMax
        : localeStringFunction(
            slicedValue(
              localeStringFunction((amountInMax / hundred) * slippageTolerance)
            ) + amountInMax
          );

    return dispatch(
      callContractSendMethod(
        "swapTokensForExactETH",
        [amountOut, amountInMaxWithSlippageTolerance, path, to, deadLine],
        walletAddress,
        "router",
        undefined,
        routerAddress
      )
    );
  } catch (error) {
    errorHelperContract(error, "send", "swapTokensForExactETH");
  }
};
const swapExactTokensForETH = async (data: commonSwapData) => {
  const {
    walletAddress,
    amountIn,
    amountOutMin,
    path,
    to,
    deadLine,
    slippageTolerance,
    dispatch,
  } = data;
  try {
    let amountOutMinWithSlippageTolerance: string =
      (amountOutMin / hundred) * slippageTolerance == 0
        ? amountOutMin
        : localeStringFunction(
            amountOutMin -
              slicedValue(
                localeStringFunction(
                  (amountOutMin / hundred) * slippageTolerance
                )
              )
          );

    return dispatch(
      callContractSendMethod(
        "swapExactTokensForETH",
        [amountIn, amountOutMinWithSlippageTolerance, path, to, deadLine],
        walletAddress,
        "router",
        undefined,
        routerAddress
      )
    );
  } catch (error: any) {
    if (error?.code !== 4001) {
      let taxPercentage: number = 50;
      try {
        let amountOutMinWithSlippageTolerance: string = localeStringFunction(
          amountOutMin -
            slicedValue(
              localeStringFunction(amountOutMin / hundred) * taxPercentage
            )
        );

        return dispatch(
          callContractSendMethod(
            "swapExactTokensForETHSupportingFeeOnTransferTokens",
            [amountIn, amountOutMinWithSlippageTolerance, path, to, deadLine],
            walletAddress,
            "router",
            undefined,
            routerAddress
          )
        );
      } catch (error) {
        // errorHelperContract(
        //   error,
        //   "send",
        //   "swapExactTokensForETHSupportingFeeOnTransferTokens"
        // );
      }
    } else {
      errorHelperContract(error, "send", "swapExactTokensForETH");
    }
  }
};
const swapExactTokensForToken = async (data: commonSwapData) => {
  const {
    walletAddress,
    amountIn,
    amountOutMin,
    path,
    to,
    deadLine,
    slippageTolerance,
    dispatch,
  } = data;
  try {
    let amountOutMinWithSlippageTolerance: string =
      (amountOutMin / hundred) * slippageTolerance == 0
        ? amountOutMin
        : localeStringFunction(
            amountOutMin -
              slicedValue(
                localeStringFunction(
                  (amountOutMin / hundred) * slippageTolerance
                )
              )
          );

    return dispatch(
      callContractSendMethod(
        "swapExactTokensForTokens",
        [amountIn, amountOutMinWithSlippageTolerance, path, to, deadLine],
        walletAddress,
        "router",
        undefined,
        routerAddress
      )
    );
  } catch (error: any) {
    if (error?.code !== 4001) {
      let taxPercentage: number = 50;
      try {
        let amountOutMinWithSlippageTolerance: string = localeStringFunction(
          amountOutMin -
            slicedValue(
              localeStringFunction((amountOutMin / hundred) * taxPercentage)
            )
        );

        return dispatch(
          callContractSendMethod(
            "swapExactTokensForTokensSupportingFeeOnTransferTokens",
            [amountIn, amountOutMinWithSlippageTolerance, path, to, deadLine],
            walletAddress,
            "router",
            undefined,
            routerAddress
          )
        );
      } catch (error) {
        errorHelperContract(
          error,
          "send",
          "swapExactTokensForTokensSupportingFeeOnTransferTokens"
        );
      }
    } else {
      errorHelperContract(error, "send", "swapExactTokensForTokens");
    }
  }
};

//CHECK IT
const swapExactETHForToken = async (data: commonSwapData) => {
  const {
    walletAddress,
    amountIn,
    amountOutMin,
    path,
    to,
    deadLine,
    slippageTolerance,
    dispatch,
  } = data;
  try {
    let amountOutMinWithSlippageTolerance: string =
      (amountOutMin / hundred) * slippageTolerance == 0
        ? amountOutMin
        : localeStringFunction(
            amountOutMin -
              slicedValue(
                localeStringFunction(
                  (amountOutMin / hundred) * slippageTolerance
                )
              )
          );
    return dispatch(
      callContractSendMethod(
        "swapExactETHForTokens",
        [amountOutMinWithSlippageTolerance, path, to, deadLine],
        walletAddress,
        "router",
        amountIn,
        routerAddress
      )
    );
  } catch (error: any) {
    if (error?.code !== 4001) {
      let taxPercentage: number = 50;
      try {
        let amountOutMinWithSlippageTolerance: string = localeStringFunction(
          amountOutMin -
            slicedValue(
              localeStringFunction((amountOutMin / hundred) * taxPercentage)
            )
        );

        return dispatch(
          callContractSendMethod(
            "swapExactETHForTokensSupportingFeeOnTransferTokens",
            [amountOutMinWithSlippageTolerance, path, to, deadLine],
            walletAddress,
            "router",
            amountIn,
            routerAddress
          )
        );
      } catch (error) {
        // errorHelperContract(
        //   error,
        //   "send",
        //   "swapExactETHForTokensSupportingFeeOnTransferTokens"
        // );
      }
    }
    errorHelperContract(error, "send", "swapExactETHForTokens");
  }
};

const swapETHForExactToken = async (data: commonSwapData) => {
  const {
    walletAddress,
    amountOut,
    amountInMax,
    dispatch,
    path,
    to,
    slippageTolerance,
    deadLine,
  } = data;
  //amount Out second field
  try {
    let amountOutMaxWithSlippageTolerance: string =
      (amountOut / hundred) * slippageTolerance == 0
        ? amountOut
        : slicedValue(
            localeStringFunction(
              (amountOut / hundred) * slippageTolerance + amountOut
            )
          );

    return dispatch(
      callContractSendMethod(
        "swapETHForExactTokens",
        [amountOutMaxWithSlippageTolerance, path, to, deadLine],
        walletAddress,
        "router",
        amountInMax,
        routerAddress
      )
    );
  } catch (error) {
    errorHelperContract(error, "send", "swapETHForExactToken");
  }
};
const swapTokensForExactToken = async (data: commonSwapData) => {
  const {
    walletAddress,
    amountOut,
    amountInMax,
    path,
    to,
    deadLine,
    slippageTolerance,
    dispatch,
  } = data;
  try {
    let amountInMaxWithSlippageTolerance: string =
      (amountInMax / hundred) * slippageTolerance == 0
        ? amountInMax
        : localeStringFunction(
            slicedValue(
              localeStringFunction((amountInMax / hundred) * slippageTolerance)
            ) + amountInMax
          );

    return dispatch(
      callContractSendMethod(
        "swapTokensForExactTokens",
        [amountOut, amountInMaxWithSlippageTolerance, path, to, deadLine],
        walletAddress,
        "router",
        undefined,
        routerAddress
      )
    );
  } catch (error) {
    errorHelperContract(error, "send", "swapTokensForExactTokens");
  }
};

export {
  swapTokensForExactETH,
  swapExactTokensForETH,
  swapExactTokensForToken,
  swapTokensForExactToken,
  swapExactETHForToken,
  swapETHForExactToken,
};

import AWS from "aws-sdk";
import { REGION, S3_BUCKET, endpoint, accessKeyId, secretAccessKey } from "../../../../Utils";
 
AWS.config.update({
  accessKeyId: accessKeyId,
  secretAccessKey: secretAccessKey,
  region: REGION,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
  endpoint: endpoint,
});

function getFileExtension(mimeType) {
  // Extract the substring after the last '/'
  const slashIndex = mimeType.lastIndexOf('/');
  if (slashIndex !== -1) {
      const extension = mimeType.substring(slashIndex + 1);
      return extension;
  } else {
      return null; // Cannot determine extension
  }
}

export const imageUpload = async (imageFile: any, userwalletAddress: string) => {
  try {
    // Remove spaces from the imageFile
    const cleanedWalletAddress = userwalletAddress.replace(/\s/g, '');

    const params: any = {
      ACL: "public-read",
      Body: imageFile,
      Bucket: S3_BUCKET,
      Key: cleanedWalletAddress,
    };
    return myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt, data) => {})
      .send((err) => {
        if (err) console.log(err);
      });
  } catch (error) {
    console.error("Error uploading file:", error);
  }
};

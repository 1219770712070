import { ReactNode } from "react";
import "./Button.scss";
import { useNavigate } from "react-router-dom";
import GetWallet from "../Modals/GetWallet/GetWallet";

type propType = {
  onClick?: any;
  type?: "button" | "submit" | "reset";
  keys?: any;
  className?: string;
  fluid?: boolean;
  transparent?: boolean;
  disabled?: boolean;
  title?: string | ReactNode;
  btnIcon?: string;
  onlyIcon?: ReactNode | string;
  centered?: boolean;
  navigate?: any;
  onlyIconClass?: string;
  show?: boolean | undefined;
  children?: string | ReactNode;
  setShow?: any;
};
// (e: MouseEvent<HTMLElement>) => any;
/**COMMON BUTTON WITH DYNAMIC PROPS */
const ButtonCustom = (props: propType) => {
  const navigate = useNavigate();

  // Function to handle navigation
  const handleNavigation = () => {
    if (props.navigate) {
      // Check if it's an absolute URL
      if (props.navigate.startsWith("http://") || props.navigate.startsWith("https://")) {
        window.open(props.navigate, "_blank");
      } else {
        navigate(props.navigate);
      }
    } else if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <>
      <button
        onClick={
          //props.navigate ? () => navigate(props?.navigate) : props?.onClick
          handleNavigation
        }
        type={props?.type || "button"}
        className={`ButtonCustom ${props?.centered ? "centered" : ""} ${
          props?.className || ""
        } ${props?.fluid ? "w-100" : ""} ${
          props?.transparent ? "transparent" : ""
        }`}
        disabled={props?.disabled}
      >
        {props?.children || props?.title}
        {props?.btnIcon && <img src={props?.btnIcon} alt="icon" className="" />}
        {props?.onlyIcon && (
          <span className={`${props?.onlyIconClass} ${"onlyIconClass"}`}>
            {props?.onlyIcon}
          </span>
        )}
      </button>
      <GetWallet show={props?.show} handleClose={() => props?.setShow(false)} />
    </>
  );
};

export default ButtonCustom;

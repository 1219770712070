import React, { ReactNode, useState } from "react";
import "./CommonCard.scss";
import {
  RightArrowCirlce,
  WalletIcon,
} from "../../../../Assets/Images/Icons/SvgIcons";
import ButtonCustom from "../../Button/ButtonCustom";
import Shimmer from "../../Shimmer/Shimmer";
import { useTranslation } from "react-i18next";

type propTypes = {
  cardTitle?: string;
  viewAllNavigate?: string;
  className?: string;
  children?: ReactNode;
  contentBg?: boolean;
  contentClassName?: string;
  noHeaderSpacing?: boolean;
  walletNotConnected?: boolean;
  loader?: boolean;
  scrollingBar?: boolean; 
};

const CommonCard = (props: propTypes) => {
  const [show, setShow] = useState<boolean>(false);
  const {
    cardTitle,
    className,
    children,
    contentClassName,
    contentBg,
    noHeaderSpacing,
    walletNotConnected,
    loader,
    scrollingBar,
  } = props;
  const { t } = useTranslation();

  return (
    <div className={scrollingBar ? "CommonCard-container scrolling" : "CommonCard-container"}>
      <div
        className={`Card ${noHeaderSpacing ? "headerNoSpacing" : ""} ${
          className || ""
        }`}
      >
        <div className="CardHeader">
          <h3>{cardTitle}</h3>
        </div>

        <div
          className={`position-relative ${contentClassName || ""} ${
            contentBg ? "contentBg" : ""
          }`}
        >
          {loader ? <Shimmer height={440} width={349} /> : children}
          {walletNotConnected ? null : (
            <>
              <div className="wallet_not_connected">
                <ButtonCustom
                  title={
                    <>
                      <WalletIcon /> {t('connectWallet')}
                    </>
                  }
                  show={show}
                  setShow={setShow}
                  onClick={() => setShow(true)}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

CommonCard.defaultProps = {
  walletNotConnected: false,
};

export default CommonCard;

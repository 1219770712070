import React, { useEffect, useState } from "react";
import { IdleTimerProvider } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, redirect } from "react-router-dom";
import Swal from "sweetalert2";
import {
  logoutUser,
  reset,
  setUserOnboardingData,
  setUserPersonalisedLock,
  userDetails,
} from "../../../Redux/Slices/user.slice";
import Header from "../../Common/Header/Header";
import Sidebar from "../../Common/Sidebar/Sidebar";
import "./AuthLayout.scss";
import { Container } from "react-bootstrap";
import {
  approveNetwork,
  handleUserProgress,
  removeStorage,
} from "../../../Redux/Actions/user.action";
import { useLocation, useNavigate } from "react-router-dom";
import { callApiPostMethod } from "../../../Redux/Actions/api.action";
import { RESPONSES } from "../../../Utils";
import { Dispatch } from "redux";
import { GET_USER } from "../../../Redux/Actions/apiResponseInterfaces";

const AuthLayout = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const userwalletAddress: any = useSelector(
    (state: any) => state?.user?.walletAddress
  );

  const shariah: any = useSelector(
    (state: any) => state?.user?.userDetails?.shariah || false
  );

  const onIdle = () => {
    removeStorage();
    localStorage.clear();
    // Swal.fire({
    //   icon: "info",
    //   title: "Session Expired",
    //   text: "Your session is expired, You have to login again to continue",
    //   showCancelButton: false,
    //   confirmButtonText: "Ok",
    // }).then(() => {
    // });
    dispatch(logoutUser());
    dispatch(reset());
  };
  const verifyUserProgress = async () => {
    const result: GET_USER | undefined = await handleUserProgress({
      dispatch,
      walletAddress: userwalletAddress,
      from: "AuthL",
    });
    if (result?.walletStatus == "active") {
      dispatch(userDetails(result.data));
      dispatch(setUserPersonalisedLock(result?.data?.lock));
    }
    if (result?.walletStatus == "inactive") {
      dispatch(setUserOnboardingData({ progress: 50, path: "/add-profile" }));
      navigate("/add-profile");
    } else if (
      result?.walletStatus == "active" &&
      result?.data?.walletAddress &&
      !result?.data?.value
    ) {
      dispatch(setUserOnboardingData({ progress: 50, path: "/add-profile" }));
      navigate("/add-profile");
    } else if (
      location?.pathname != "/auth/earn/overview" &&
      location?.pathname != "/auth/amm-pools" &&
      location?.pathname != "/auth/trade/simple/market" &&
      location?.pathname != "/auth/trade/professional/market" &&
      location?.pathname != "/auth/dashboard/portfolio" &&
      location?.pathname != "/auth/trade/simple/limit" &&
      location?.pathname != "/auth/dashboard/trade-history" &&
      location?.pathname != "/auth/trade/simple/stop-orders" &&
      location?.pathname != "/auth/trade/professional/limit" &&
      location?.pathname != "/auth/trade/professional/stop-orders" &&
      location?.pathname != "/auth/earn/my-investments" &&
      result?.data?.value
    ) {
      dispatch(
        setUserOnboardingData({
          progress: result?.data?.value,
          path: result?.data?.label,
        })
      );
      navigate(`/${result?.data?.label}`);
    }
  };

  useEffect(() => {
    if (userwalletAddress?.length) {
      verifyUserProgress();
      setTimeout(() => {
        verifyUserProgress();
      }, 2000);
    }
  }, [userwalletAddress]);

  const [lock, setLock] = useState(true);
  const [lockVisitor, setLockVisitor] = useState(true);
  const [showSidebar, setShowSidebar] = useState(false);
  const userLockValue: any = useSelector((state: any) => state?.user?.lock);

  useEffect(() => {
    if (userwalletAddress) {
      setLock(userLockValue == "false" ? false : true);
      setLockVisitor(userLockValue == "false" ? false : true);
    } else {
      setLock(lockVisitor);
    }
  }, [userLockValue]);

  window.onresize = () => {
    if (document.body.clientWidth < 767) {
      setLock(false);
    } else if (userwalletAddress) {
      setLock(lockVisitor);
      setLockVisitor(lockVisitor);
    } else if (!userwalletAddress) {
      setLock(lockVisitor);
    }
  };

  useEffect(() => {
    if (document.body.clientWidth < 767) {
      setLock(false);
    } else if (userwalletAddress) {
      setLock(lockVisitor);
      setLockVisitor(lockVisitor);
    } else if (!userwalletAddress) {
      setLock(lockVisitor);
    }
  }, []);

  // useEffect(() => {
  //   if (userwalletAddress) updateUserLockStatus();
  // }, [lock]);
  
  return (
    <>
      <IdleTimerProvider
        timeout={1000 * 60 * 60 * 4}
        crossTab={true}
        onIdle={onIdle}
      >
        <main
          className={`AuthLayout ${showSidebar ? "activeSidebar" : ""} ${lock ? "active" : ""} ${shariah ? "shariahBackground" : ""}`}
        >
          <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
          <div
            onClick={() => setShowSidebar(false)}
            className={`d-md-none d-block ${
              showSidebar ? "show" : ""
            } sidebar_backdrop`}
          />
          <Sidebar lock={lock} setLock={setLock} setShowSidebar={setShowSidebar} setLockVisitor={setLockVisitor} />
          <div className="AuthLayoutBox">
            <div className="AuthLayoutInner">
              <Outlet />
            </div>
          </div>
        </main>
      </IdleTimerProvider>
    </>
  );
};

export default AuthLayout;

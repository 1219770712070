import React, { useEffect, useState, Component } from "react";
import Switch from "react-switch";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import {
  CameraIcon,
  CloseIcon,
  UploadIcon,
} from "../../../../Assets/Images/Icons/SvgIcons";
import "./ProfileModal.scss";
import InputCustom from "../../FormInputs/Input/Input";
import ButtonCustom from "../../Button/ButtonCustom";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { loginSchema } from "../../../FormikSchemas/addProfileSchema";
import { imageUpload } from "../../../Pages/Public/AddProfile/uploadImageFile";
import {
  handleUserProgress,
  updateUserModal,
} from "../../../../Redux/Actions/user.action";
import { RESPONSES, VIDEO_URL } from "../../../../Utils";
import profileImg from "../../../../Assets/Images/profile-img.png";
import { userDetails, walletAddress } from "../../../../Redux/Slices/user.slice";
import { GET_USER } from "../../../../Redux/Actions/apiResponseInterfaces";
import { useTranslation } from "react-i18next";
import toaster from "../../Toast";
const ProfileModal = ({
  show,
  handleClose,
  updateImage,
}: {
  show: boolean;
  handleClose: Function;
  updateImage: Function;
}) => {
  const dispatch: any = useDispatch();
  const { t } = useTranslation();
  const [imageUrl, setImageUrl] = useState<any>("");
  const [userPreviewDetails, setUserPreviewDetails] = useState<any>();
  const [imagePreview, setImagePreview] = useState<string>("");
  const [isReset, setIsReset] = useState<boolean>(false);
  const [error, setError] = useState<String>("");

  const userwalletAddress = useSelector(
    (state: any) => state?.user?.walletAddress
  );
  const userDetailsData: any = useSelector(
    (state: any) => state?.user?.userDetails
  );

  const getUserDetails = async () => {
    const result: GET_USER | undefined = await handleUserProgress({
      dispatch,
      walletAddress: userwalletAddress,
      from: "Profile",
    });
    if (result) {
      setUserPreviewDetails(result?.data);
      dispatch(userDetails(result?.data));
    }
  };

  useEffect(() => {
    if (userwalletAddress) getUserDetails();
  }, [show]);

  useEffect(() => {
    if (userwalletAddress && userDetailsData?.image) {
      const profilePic = VIDEO_URL + userDetailsData?.image;
      setImagePreview(profilePic);
    } else {
      setImagePreview(profileImg);
    }
    if (userPreviewDetails) {
      formik.values.name = userPreviewDetails?.name;
      formik.values.email = userPreviewDetails?.emailAddress;
    }
  }, [userPreviewDetails, userwalletAddress]);

  const formik = useFormik({
    initialValues: {
      name: userPreviewDetails?.name,
      email: userPreviewDetails?.emailAddress,
    },
    // name and email validations
    validationSchema: () => loginSchema(t),
    onSubmit(values) {},
  });

  const handleFileSelect = async (event: any) => {
    try {
      setImageUrl(event);
      let output = URL.createObjectURL(event?.target?.files[0]);
      setImagePreview(output);
      setIsReset(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteImage = () => {
    setImageUrl("");
    setImagePreview(profileImg);
    setError("");
    setIsReset(true);
  };

  // Use to transform DefaultAvatar to File type
  async function createFileFromImportedImage(importedImage) {
    try {
      const response = await fetch(importedImage);
      const blob = await response.blob();
      const filename = "DefaultAvatar";
      return new File([blob], filename, { type: blob.type });
    } catch (error) {
      console.error("Error creating file from imported image:", error);
      throw error;
    }
  }


  const handleProfileSubmit = async (e) => {
    //e.preventDefault();
    
      if (imageUrl || isReset) {
        setError("");

        let file: File;

        if (isReset) {
          file = await createFileFromImportedImage(profileImg);
          setIsReset(false);
        } else {
          file = imageUrl?.target?.files[0];
        }

        // profile image type and maximum size validations
        //const file: any = imageUrl?.target?.files[0];
        console.log(file)
        const maxSize = 4 * 1024 * 1024; // Maximum file size in bytes (1MB in this example)
        const maxDimension = 1000; // Maximum allowed dimension (width or height) in pixels
        const allowedFormats = [
          "image/jpeg",
          "image/png",
          "image/gif",
          "image/bmp",
          "image/tiff",
          "image/svg+xml",
          "image/webp",
        ]; // Allowed image formats

        // profile image type validations file format match
        if (!allowedFormats.includes(file.type)) {
          setError(t('updateProfileInvalidImageFormatError'));
          return;
        }

        if (file.size > maxSize) {
          setError((t('updateProfileFileSizeExceedError')).replace('{{size}}', '4MB'));
          return;
        }

        const image = new Image();
        // image.onload = async () => {
        //   const { width, height } = image;

        //   // Profile image dimensions validation
        //   if (width > maxDimension || height > maxDimension) {
        //     setError(
        //       `Image dimensions exceed the maximum allowed values. Maximum width: ${maxDimension}px, Maximum height: ${maxDimension}px.`
        //     );
        //     return;
        //   }
        // };
        const Imageresult: any = await imageUpload(file,userwalletAddress + "_" + file.name);
        let newImagePath: any = Imageresult?.request?.params?.Key;
        // setImagePath(newImagePath);
    
        // Set the image URL directly
        //const imageUrlNew = URL.createObjectURL(file);
        const result = await updateUserModal({
          address: userwalletAddress,
          image: newImagePath,
          name: formik?.values?.name,
          email: formik?.values?.email,
          shariah: shariahState,
          dispatch,
        });
        if (result?.status == RESPONSES?.SUCCESS) {
          toaster.success(t('updateProfileSuccessText'));
        }
        handleClose();
        updateImage(newImagePath);
      } else {
      // ========================>>>>>>>>>>>>>>>>>>
      const result = await updateUserModal({
        address: userwalletAddress,
        name: formik?.values?.name,
        email: formik?.values?.email,
        shariah: shariahState,
        dispatch,
      });
      if (result?.status == RESPONSES?.SUCCESS) {
        toaster.success(t('updateProfileSuccessText'));
      }
        handleClose();
      }
  };


  const [shariahState, setShariahState] = useState(false);

  useEffect(() => {
    if (userPreviewDetails) setShariahState(userPreviewDetails?.shariah);
  }, [userPreviewDetails]);

  const handleChange = nextChecked => {
      setShariahState(nextChecked);
      console.log("Shariah Experience:", nextChecked);
  };


  return (
    <Modal
      centered
      className="profile_modal"
      show={show}
      onHide={() => {
        handleDeleteImage();
        formik.values.name = "";
        formik.values.email = "";
        handleClose();
      }}
    >
      <Modal.Header>
        <Modal.Title>{t('updateProfileTitle')}</Modal.Title>
        <button
          className="modal_close_btn"
          onClick={() => {
            handleDeleteImage();
            formik.values.name = "";
            formik.values.email = "";
            handleClose();
          }}
        >
          <CloseIcon />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="inputFilePreview">
          <div className="inputFilePreviewInner">
            <span className="upload_icon">
              <input
                type="file"
                onChange={(e) => handleFileSelect(e)}
                accept="image/*"
                id="imgInp"
              />
              <UploadIcon />
            </span>
            <span className="cancel_icon" onClick={handleDeleteImage}>
              <CloseIcon />
            </span>
          </div>
          <img src={imagePreview} alt={t('updateProfileSelectedFile')} className="profile_img" />
          {error && <p className="error_msg text-center">{error}</p>}
        </div>
        <InputCustom
          placeholder={t('updateProfileEnterNamePlaceholder')}
          label={
            <>
              {t('onboardProfileName')}
            </>
          }
          name="name"
          value={formik?.values?.name}
          onChange={(e: any) => {
            e.preventDefault();
            formik?.handleChange(e);
          }}
          maxLength={20}
          error={formik?.errors?.name ? formik?.errors?.name : null}
          spellcheck="false"
        />
        <InputCustom
          placeholder={t('updateProfileEnterEmailPlaceholder')}
          label={
            <>
              {t('onboardEmail')}
            </>
          }
          name="email"
          type="email"
          value={formik?.values?.email}
          onChange={(e: any) => {
            e.preventDefault();
            formik?.handleChange(e);
          }}
          isInvalid={formik?.touched?.email && !!formik?.errors?.email}
          error={formik?.errors?.email ? formik?.errors?.email : null}
          spellcheck="false"
        />

        <div className="shariah-experience-container">
                <Switch
                  onChange={handleChange}
                  checked={shariahState}
                  onColor="#86d3ff"
                  onHandleColor="#2693e6"
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={20}
                  width={48}
                  className="react-switch"
                  id="material-switch"
                />
              <p>
                {t('updateProfileShariahExperience')}
              </p>
        </div>


        <div className="d-flex align-items">
          <ButtonCustom
            title={t('updateProfileCancelButton')}
            className="bordered-blue"
            fluid
            onClick={() => {
              handleClose();
            }}
          />
          <ButtonCustom
            title={t('updateProfileUpdateButton')}
            fluid
            className="bordered-blue"
            disabled ={ Boolean(formik?.errors?.name) || Boolean(formik?.errors?.email) } 
            onClick={(e) => {
              handleProfileSubmit(e);
            }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ProfileModal;

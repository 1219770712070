import {
  Link,
  Outlet,
  redirect,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./MainLayout.scss";
import logo from "../../../Assets/Images/logo/logo.svg";
import ProgressLine from "../../Common/ProgressLine/ProgressLine";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  reset,
  setUserOnboardingData,
  userDetails,
} from "../../../Redux/Slices/user.slice";
import {
  approveNetwork,
  handleUserProgress,
} from "../../../Redux/Actions/user.action";
import { GET_USER } from "../../../Redux/Actions/apiResponseInterfaces";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { changeLanguage, supportedLngs } from "../../../i18n";

const MainLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const userwalletAddress: any = useSelector(
    (state: any) => state?.user?.walletAddress
  );
  const userSteps: any = useSelector(
    (state: any) => state?.user?.userOnboardingData
  );
  const routes = [
    "/",
    "/setup",
    "/add-profile",
    "/terms-conditions",
    "/auth/dashboard/libfi",
  ];

  const verifyUserProgress = async () => {
    const result: GET_USER | undefined = await handleUserProgress({
      dispatch,
      walletAddress: userwalletAddress,
      from: "MainL",
    });
    if (result) {
      dispatch(userDetails(result?.data));
    }
    if (result?.walletStatus == "inactive") {
      dispatch(setUserOnboardingData({ progress: 50, path: "/add-profile" }));
      navigate("/add-profile");
    } else {
      if (
        result?.walletStatus == "active" &&
        result?.data?.walletAddress &&
        !result?.data?.value
      ) {
        dispatch(setUserOnboardingData({ progress: 50, path: "/add-profile" }));
        navigate("/add-profile");
      } else if (result != undefined) {
        dispatch(
          setUserOnboardingData({
            progress: result?.data?.value,
            path: result?.data?.label,
          })
        );
        navigate(`/${result?.data?.label}`);
      }
    }
  };

  useEffect(() => {
    if (userwalletAddress?.length) {
      verifyUserProgress();
    } else if (!userwalletAddress?.length && location?.pathname != "/") {
      navigate("/");
    }
  }, [userwalletAddress]);

  return (
    <>
      <main className="MainLayout">
        <header className="MainLayoutHeader">
          {!userwalletAddress ? (
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          ) : (
            <img src={logo} alt="logo" />
          )}
          <ul>
            <li>
            <Dropdown>
              <Dropdown.Toggle className="HeaderLanguageDDL">
                {i18n.language?.toUpperCase()}
              </Dropdown.Toggle>
                <Dropdown.Menu>
                  {
                    Object.keys(supportedLngs).map((lang) => (<Dropdown.Item onClick={() => changeLanguage(lang)}>{supportedLngs[lang]}</Dropdown.Item>))
                  }                  
              </Dropdown.Menu>
            </Dropdown>
            </li>
            </ul>
        </header>
        <div>
          <Outlet />
        </div>
        <ProgressLine done={userSteps?.progress} />
      </main>
    </>
  );
};

export default MainLayout;

import React from "react";
import "./UnderConstruction.scss";
import { Container } from "react-bootstrap";
import ButtonCustom from "../../Common/Button/ButtonCustom";
import Lottie from "lottie-react";
import animation from "../../../Assets/animations/under-construction.json";
import { useTranslation } from "react-i18next";
const UnderConstruction = () => {
  const { t } = useTranslation();
  return (
    <section className="under_construction text-center">
      <Container>
        <Lottie className="lottie_animation" animationData={animation} />
        <h2>
          {t('underConstructionHeading1')} <br /> {t('underConstructionHeading2')}
        </h2>
        <h3>{t('underConstructionLine1')}</h3>
        <ButtonCustom title={t('underConstructionHomeButton')} />
      </Container>
    </section>
  );
};

export default UnderConstruction;

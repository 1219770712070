import {
  commonSwapData,
  commonSwapTokens,
} from "../interfaces/contractCallInterfaces";
import {
  swapExactETHForToken,
  swapExactTokensForETH,
  swapExactTokensForToken,
  swapETHForExactToken,
  swapTokensForExactETH,
  swapTokensForExactToken,
} from "./taxTokensHelper";

const swapTokensOrExactTokensWithTokens = async (data: commonSwapTokens) => {
  const {
    input1,
    input2,
    walletAddress,
    tokenOneAddress,
    tokenTwoAddress,
    selectedField,
    deadLine,
    slippageTolerance,
    dispatch,
  } = data;
  console.log("swapTokensOrExactTokensWithTokens - received data:", data);
  let path: string[] = [tokenOneAddress, tokenTwoAddress];
  if (selectedField == "field1") {
    const data: commonSwapData = {
      walletAddress,
      amountIn: input1,
      amountOutMin: input2,
      path,
      to: walletAddress,
      deadLine,
      slippageTolerance,
      dispatch,
    };
  //   const res: any = await swapExactTokensForToken(data);
  //   if (res?.status) {
  //     return res;
  //   }
  // } else {
  //   const data: commonSwapData = {
  //     walletAddress,
  //     amountOut: input2,
  //     amountInMax: input1,
  //     path,
  //     to: walletAddress,
  //     deadLine,
  //     slippageTolerance,
  //     dispatch,
  //   };

    try {
      const res: any = await swapExactTokensForToken(data);
      console.log("swapExactTokensForToken - result:", res);
      if (res?.status) {
        return res;
      }
    } catch (error) {
      console.error("swapExactTokensForToken - error:", error);
    }
  } else {
    const data: commonSwapData = {
      walletAddress,
      amountOut: input2,
      amountInMax: input1,
      path,
      to: walletAddress,
      deadLine,
      slippageTolerance,
      dispatch,
    };
    try {
      const res: any = await swapTokensForExactToken(data);
      console.log("swapTokensForExactToken - result:", res);
      if (res?.status) {
        return res;
      }
    } catch (error) {
      console.error("swapTokensForExactToken - error:", error);
    }
  }
  console.error("swapTokensOrExactTokensWithTokens - swap failed");
};

//     const res: any = await swapTokensForExactToken(data);
//     if (res?.status) {
//       return res;
//     }
//   }
// };

const swapTokensOrExactTokensWithETH = async (data: commonSwapTokens) => {
  const {
    input1,
    input2,
    walletAddress,
    tokenOneAddress,
    tokenTwoAddress,
    selectedField,
    deadLine,
    slippageTolerance,
    dispatch,
  } = data;
  let path: string[] = [tokenOneAddress, tokenTwoAddress];
  if (selectedField == "field1") {
    const data: commonSwapData = {
      walletAddress,
      amountIn: input1,
      amountOutMin: input2,
      path,
      to: walletAddress,
      deadLine,
      slippageTolerance,
      dispatch,
    };

    const res: any = await swapExactTokensForETH(data);
    if (res?.status) {
      return res;
    }
  } else {
    const data: commonSwapData = {
      walletAddress,
      amountOut: input2,
      amountInMax: input1,
      path,
      to: walletAddress,
      deadLine,
      slippageTolerance,
      dispatch,
    };

    const res = await swapTokensForExactETH(data);
    if (res?.status) {
      return res;
    }
  }
};

const swapETHOrExactETHWithTokens = async (data: commonSwapTokens) => {
  const {
    input1,
    input2,
    walletAddress,
    tokenOneAddress,
    tokenTwoAddress,
    selectedField,
    deadLine,
    slippageTolerance,
    dispatch,
  } = data;
  let path: string[] = [tokenOneAddress, tokenTwoAddress];
  if (selectedField == "field1") {
    const data: commonSwapData = {
      walletAddress,
      amountIn: input1,
      amountOutMin: input2,
      path,
      to: walletAddress,
      deadLine,
      slippageTolerance,
      dispatch,
    };
    const res = await swapExactETHForToken(data);
    if (res?.status) {
      return res;
    }
  } else {
    const data: commonSwapData = {
      walletAddress,
      amountOut: input2,
      amountInMax: input1,
      path,
      to: walletAddress,
      deadLine,
      slippageTolerance,
      dispatch,
    };
    const res = await swapETHForExactToken(data);
    if (res?.status) {
      return res;
    }
  }
};

export {
  swapTokensOrExactTokensWithTokens,
  swapTokensOrExactTokensWithETH,
  swapETHOrExactETHWithTokens,
};

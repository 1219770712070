import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import { callContractGetMethod } from "./Redux/Actions/contract.action";
// import { RequireAuth } from "./Routes/Guard/AuthGuard";
import { WithoutAuth } from "./Routes/Guard/NoGuard";
import { Dispatch } from "redux";
import { USDT_ADDRESS } from "./Utils";
import { usdtDecimals, bnbDecimals } from "./Redux/Slices/ico.slice";
import { ErrorBoundary } from "./Components/Common/ErrorBoundary/Errorboundary";
import Loader from "./Components/Common/Loader/Loader";
import MainLayout from "./Components/Layouts/MainLayout/MainLayout";
import ErrorPage from "./Components/Pages/ErrorPage/ErrorPage";
import AuthLayout from "./Components/Layouts/AuthLayout/AuthLayout";
import { connectmetamask, removeStorage } from "./Redux/Actions/user.action";
import WelcomePage from "./Components/Pages/Public/WelcomePage/WelcomePage";
import SetupPage from "./Components/Pages/Public/SetupPage/SetupPage";
import AddProfile from "./Components/Pages/Public/AddProfile/AddProfile";
import ChooseXp from "./Components/Pages/Public/ChooseXp/ChooseXp";
import TermsConditions from "./Components/Pages/Public/TermsConditions/TermsConditions";
import MyLibfiPage from "./Components/Pages/Private/MyLibfiPage/MyLibfiPage";
import Dashboard from "./Components/Pages/Private/Dashboard/Dashboard";
import PortfolioPage from "./Components/Pages/Private/PortfolioPage/PortfolioPage";
import TradeHistory from "./Components/Pages/Private/TradeHistory/TradeHistory";
import TradePage from "./Components/Pages/Private/TradePage/TradePage";
import TradeSimple from "./Components/Pages/Private/TradeSimple/TradeSimple";
import TradeAdvanced from "./Components/Pages/Private/TradeAdvanced/TradeAdvanced";
import TradeProfessional from "./Components/Pages/Private/TradeProfessional/TradeProfessional";
import Market from "./Components/Common/Cards/PlaceOrders/Market";
import Limit from "./Components/Common/Cards/PlaceOrders/Limit";
import Stoporders from "./Components/Common/Cards/PlaceOrders/Stoporders";
import { RequireAuth } from "./Routes/Guard/AuthGuard";
import EarnPage from "./Components/Pages/Private/EarnPage/EarnPage";
import EarnOverview from "./Components/Pages/Private/EarnOverview/EarnOverview";
import MyInvestment from "./Components/Pages/Private/MyInvestment/MyInvestment";
import EarnAMMPools from "./Components/Pages/Private/EarnAMMPools/EarnAMMPools";
import EarnAssetStaking from "./Components/Pages/Private/EarnAssetStaking/EarnAssetStaking";
import AssetStakingcards from "./Components/Pages/Private/AssetStakingcards/AssetStakingcards";
import AssetStakingToken from "./Components/Pages/Private/AssetStakingToken/AssetStakingToken";
import ShariahOverview from "./Components/Pages/Private/Shariah/ShariahOverview";
import TestnetGuide from "./Components/Pages/Private/TestnetGuide/TestnetGuide";
import ShariahTokenPage from "./Components/Pages/Private/Shariah/ShariahTokenPage";
import packageJson from "../package.json";
import { reset, walletAddress, walletType } from "./Redux/Slices/user.slice";
import { CHAIN_ID } from "./Utils";
import UnderConstruction from "./Components/Pages/UnderConstruction/UnderConstruction";
const { ethereum } = window;
const Application: React.FC = ({ toseteruse }: any) => {
  useEffect(() => {
    if (ethereum) {
      //console.log("ethereum12333221", ethereum, CHAIN_ID);
      // if (Number(ethereum?.networkVersion) != CHAIN_ID) {
      //   console.log(alert("123"));
      //   dispatch(walletType(""));
      //   dispatch(walletAddress(""));
      // }

      //ethereum?.on("networkChanged", async function (chainId: string[]) { // networkChanged is deprecated
      //  console.log("accounts", chainId);
      //  if (CHAIN_ID != chainId) {
      //  }
      ethereum?.on("chainChanged", async function (chainId: string[]) {
        console.log("accounts", chainId);
        if (CHAIN_ID != chainId) {
        }
      });
    }
  });

  const manageCache = async () => {
    try {
      const latest_version: string = packageJson?.version;
      const old_libfi_version: string | null =
        localStorage?.getItem("libfi_version");
      console.log(
        "latest_version==>",
        latest_version,
        "old_version==>",
        old_libfi_version
      );
      if (old_libfi_version && latest_version != old_libfi_version) {
        // localStorage.clear();
        dispatch(reset());
        removeStorage();
        // window.location.reload();
      }
      if (!old_libfi_version) {
        localStorage?.setItem("libfi_version", latest_version);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    manageCache();
  }, []);

  /**CREATE DISPATCH OBJECT */
  const dispatch: Dispatch<any> = useDispatch();

  const theme = useSelector((state: any) => state?.theme?.theme);

  useEffect(() => {
    document.body.className = `theme-${theme}`;
  }, [theme]);
  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainLayout />,
      errorElement: <ErrorBoundary />,
      children: [
        {
          index: true,
          element: (
            // <WithoutAuth>
            <WelcomePage />
            // </WithoutAuth>
          ),
        },
        {
          path: "setup",
          element: <SetupPage />,
        },
        {
          path: "add-profile",
          element: <AddProfile />,
        },
        // {
        //   path: "choose-experience",
        //   element: <ChooseXp />,
        // },
        {
          path: "terms-conditions",
          element: <TermsConditions />,
        },
        {
          path: "*",
          element: <ErrorPage />,
        },
      ],
    },
    {
      path: "/auth",
      element: <AuthLayout />,
      errorElement: <ErrorBoundary />,
      children: [
        {
          path: "under-construction",
          element: <UnderConstruction />,
        },
        {
          path: "dashboard",
          element: (
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          ),
          children: [
            {
              path: "libfi",
              element: (
                <RequireAuth>
                  <MyLibfiPage />
                </RequireAuth>
              ),
            },
            {
              path: "portfolio",
              element: (
                // <RequireAuth>
                <PortfolioPage />
                // </RequireAuth>
              ),
            },
            {
              path: "trade-history",
              element: (
                // <RequireAuth>
                <TradeHistory />
                // </RequireAuth>
              ),
            },
          ],
        },
        {
          path: "trade",
          element: (
            <RequireAuth>
              <TradePage />
            </RequireAuth>
          ),
          children: [
            {
              path: "simple",
              element: <TradeSimple />,
              children: [
                {
                  path: "market/:from?/:to?/:type?/:page?",
                  element: (
                    <RequireAuth>
                      <Market />
                    </RequireAuth>
                  ),
                },
                {
                  path: "limit",
                  element: (
                    <RequireAuth>
                      <Limit />
                    </RequireAuth>
                  ),
                },
                {
                  path: "stop-orders",
                  element: (
                    <RequireAuth>
                      <Stoporders />
                    </RequireAuth>
                  ),
                },
              ],
            },
            {
              path: "professional",
              element: <TradeProfessional />,
              children: [
                {
                  path: "market",
                  element: (
                    <RequireAuth>
                      <Market />
                    </RequireAuth>
                  ),
                },
                {
                  path: "limit",
                  element: (
                    <RequireAuth>
                      <Limit />,
                    </RequireAuth>
                  ),
                },
                {
                  path: "stop-orders",
                  element: <Stoporders />,
                },
              ],
            },
            {
              path: "advanced",
              element: <TradeAdvanced />,
            },
          ],
        },
        {
          path: "earn",
          element: <EarnPage />,
          children: [
            {
              path: "overview",
              element: <EarnOverview />,
            },
            {
              path: "my-investments",
              element: <MyInvestment />,
            },
          ],
        },
        {
          path: "amm-pools/:from?/:to?/:type?/:page?",
          element: (
            <RequireAuth>
              <EarnAMMPools />
            </RequireAuth>
          ),
        },
        {
          path: "asset-staking",
          element: <EarnAssetStaking />,
          children: [
            {
              path: "all",
              element: <AssetStakingcards />,
            },
            {
              path: "stake",
              element: <AssetStakingToken />,
            },
          ],
        },
        {
          path: "shariah",
          children: [
            {
              path: "tokens",
              element: <ShariahOverview />,
            },
            {
              path: "analysis",
              element: <ShariahTokenPage />,
            },
          ],
        },
        {
          path: "testnet",
          children: [
            {
              path: "guide",
              element: <TestnetGuide />,
            },
          ],
        },
      ],
    },
  ]);

  return (
    <>
      <RouterProvider router={router} fallbackElement={<Loader />} />
    </>
  );
};

export default Application;

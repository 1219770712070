import React, { useEffect, useState } from "react";
import "./Welcome.scss";
import CommonHeading from "../../../Common/Heading/Heading";
//import videoPoster from "../../../../Assets/Images/liberty_demo_thumnail.png";
import videoPoster from "../../../../Assets/Images/liberty_finance_thumbnail.svg";
import ButtonCustom from "../../../Common/Button/ButtonCustom";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setUserOnboardingData,
} from "../../../../Redux/Slices/user.slice";
import { PlayIcon } from "../../../../Assets/Images/Icons/SvgIcons";
import { useTranslation } from 'react-i18next';

// const VIDEO_URL =
//   "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/TearsOfSteel.mp4";

// liberty_fi_demo_video.mp4
const WelcomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [imageVisible, setImageVisible] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleProgressFunction = async () => {
    dispatch(setUserOnboardingData({ progress: 25, path: "/setup" }));
    navigate("/setup");
  };

  const handleVideoEnded = () => {
    setImageVisible(!imageVisible);
  };

  useEffect(() => { 
    dispatch(setUserOnboardingData({ progress: 0, path: "/" }));
  }, []);

  return (
    <section className="WelcomePage">
      <CommonHeading heading={t("welcome")} centered />
      <div className="BannerVideo">
        <ReactPlayer
          canPlay
          className="mobile-responsive"
          url={require(`../../../../Assets/Videos/${t('welcomeVideoName')}`)}
          light={
            <img className="Thumbnail" src={videoPoster} alt="Thumbnail" />
          }
          pip={true}
          controls
          playIcon={
            <button className="playIcon">
              <PlayIcon />
            </button>
          }
          playing={true}
          config={{
            file: {
              attributes: {
                controlsList: "nodownload",
              },
            },
          }}
          onEnded={handleVideoEnded}
          {...(imageVisible && (
            <img src={videoPoster} alt="After Video Image" />
          ))}
        />
      </div>
      <ButtonCustom
        title={ t("continueButton")}
        className="continueBtn"
        centered
        onClick={() => handleProgressFunction()}
      />
    </section>
  );
};

export default WelcomePage;

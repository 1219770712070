import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import DetailsCard from "../../../Common/Cards/DetailsCard/DetailsCard";
import "../TradeHistory/TradeHistory.scss";
import { useDispatch, useSelector } from "react-redux";
import { callApiPostMethod } from "../../../../Redux/Actions/api.action";
import { Dispatch } from "redux";
import { cryptoDecimals, formatNumberWithMagnitudeSuffix } from "../../../../Services/common.service";
import { FETCH_USER_DATA } from "../../../../Redux/Actions/apiResponseInterfaces";
import { useTranslation } from "react-i18next";
import { MY_ASSETS } from "../../../../interfaces/commonInterfaces";

type CommonTilesProps = {
  page: String;
  assets?: MY_ASSETS[];
  loader?: boolean;
}
const CommonTiles = ({ page, assets, loader }: CommonTilesProps) => {
  const dispatch: any = useDispatch();
  const { t } = useTranslation();

  const userwalletAddress: string = useSelector(
    (state: any) => state?.user?.walletAddress
  ); 

  const [weeklyGainLoss, setWeeklyGainLoss] = useState<string | number | null>(0);
  const [tradesIn7Days, setTradesIn7Days] = useState<string | number>("0");
  const [recentTransactions, setRecentTransactions] = useState<string | number>(
    "0"
  );

  useEffect(() => {
    getTilesData();
  }, [userwalletAddress]);

  useEffect(() => {
    if (assets && assets.length > 0) {
      setWeeklyGainLoss(assets.reduce((total: number, asset: MY_ASSETS) => total + asset.value, 0));
    } else {
      setWeeklyGainLoss(0);
    }
  }, [assets]);

  const getTilesData = async () => {
    const result: FETCH_USER_DATA = await dispatch(
      callApiPostMethod(
        "FETCH_USER_DATA",
        {
          userAddress: userwalletAddress
        },
        false,
        false
      )
    );
    if (result?.status == 200) {
      const last7DaysTransactionCount = result?.data?.last7DaysTransactionCount || 0;
      setTradesIn7Days(last7DaysTransactionCount);
      setRecentTransactions(0);
    } else {
      setTradesIn7Days(0);
      setRecentTransactions(0);
    }
  };

  return (
    <div
      className={
        page == "portfolio"
          ? "PortfolioPageBanner"
          : "TradeHistoryPageBanner"
      }
    >
      <Row>
        <Col md={4} xs={6}>
          <DetailsCard
            loader={loader}
            heading={t('portfolioTotalAssets')}
            value={document.body.dir === "rtl" ? `${formatNumberWithMagnitudeSuffix(weeklyGainLoss)} $` : `$ ${formatNumberWithMagnitudeSuffix(weeklyGainLoss)}`}
          />
        </Col>
        <Col md={4} xs={6}>
          <DetailsCard
            heading={t('portfolioTradesExecuted')}
            value={tradesIn7Days.toString()}
            type="darkBlue"
          />
        </Col>
        <Col md={4} xs={6}>
          <DetailsCard
            heading={t('portfolioRecentTransactions')}
            value={recentTransactions.toString()}
            type="yellow"
          />
        </Col>
      </Row>
    </div>
  );
};

export default CommonTiles;

import React, { useEffect, useState } from "react";
import "./TradeProfessional.scss";
import { Col, Row } from "react-bootstrap";
import CommonCard from "../../../Common/Cards/CommonCard/CommonCard";
import RecentTrades from "../../../Common/Cards/RecentTrades/RecentTrades";
import OrderBook from "../../../Common/Cards/OrderBook/OrderBook";
import { Outlet } from "react-router-dom";
import SubHeader from "../../../Common/SubHeader/SubHeader";
import ExchangeGraph from "../../../Common/Cards/ExchangeGraph/ExchangeGraph";
import SettingModal from "../../../Common/Modals/SettingModal/SettingModal";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "../../../Common/Tooltip/Tooltip";
import {
  CancelOrderIcon,
  SettingsIcon,
  WarningIcon,
} from "../../../../Assets/Images/Icons/SvgIcons";
import ButtonCustom from "../../../Common/Button/ButtonCustom";
import { callApiPostMethod } from "../../../../Redux/Actions/api.action";
import {
  dynamicContractDetails,
  tokenCollection,
} from "../../../../Services/dynamicContractDetails";
import { cryptoDecimals } from "../../../../Services/common.service";
import CustomTable from "../../../Common/Table/Table";
import { setOrderPlaced } from "../../../../Redux/Slices/user.slice";
import TransactionDone from "../../../Common/Modals/TransactionDone/TransactionDone";
import { TOKENS } from "../../../../interfaces/commonInterfaces";
import {
  CANCEL_ORDER,
  ORDER_HISTORY,
} from "../../../../Redux/Actions/apiResponseInterfaces";
import {
  capitalizeFirstLetter,
  getTokenBalanceForNewUser,
} from "../../../../Services/contractCallService";
import { useTranslation } from "react-i18next";

const TradeProfessional = () => {
  const dispatch: any = useDispatch();
  const { t } = useTranslation();
  const {
    walletAddress,
    placedOrderSuccess,
    getRecentTrade,
  }: {
    walletAddress: string;
    placedOrderSuccess: boolean;
    getRecentTrade: boolean;
  } = useSelector((state: any) => state?.user);
  const { tokenTwo }: { tokenTwo: TOKENS } = useSelector(
    (state: any) => state?.tokens
  );
  const [show, setShow] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<{
    show: boolean;
    item: string;
    status: string;
  }>({
    show: false,
    item: "Empty",
    status: "",
  });

  const [orderHistory, setOrderHistory] = useState<string[]>([]);
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    if (getRecentTrade) getOrderHistory();
  }, [getRecentTrade]);
  const handleClose = () => setShowModal({ show: false, item: "", status: "" });
  const navigationLinks = [
    {
      id: 1,
      label: (
        <>
          <Tooltip icon={t('tradeTabMarket')} heading="" content={t('tradeTabMarketDescription')} />
        </>
      ),
      to: "/auth/trade/professional/market",
    },
    {
      id: 2,
      label: (
        <>
          <Tooltip icon={t('tradeTabLimit')} heading="" content={t('tradeTabLimitDescription')} />
        </>
      ),
      key: "limit",
      to: "/auth/trade/professional/limit",
    },
    {
      id: 3,
      label: (
        <>
          <Tooltip heading="" icon={t('tradeTabStop')} content={t('tradeTabStopDescription')} />
        </>
      ),
      key: "stop",
      to: "/auth/trade/professional/stop-orders",
    },
  ];
  useEffect(() => {
    if (walletAddress) {
      getOrderHistory();
    }
  }, [walletAddress]);

  useEffect(() => {
    if (placedOrderSuccess) getOrderHistory();
  }, [placedOrderSuccess]);

  /**
   * function to cancel limit or stop order of a user
   * @param data order info which user wants to cancel
   */
  const cancelOrder = async (data: any) => {
    let result: CANCEL_ORDER;
    if (data?.type == "limitOrder") {
      result = await dispatch(
        callApiPostMethod(
          "CANCEL_LIMIT_ORDER",
          { userAddress: walletAddress?.toLowerCase(), salt: data?.salt },
          false,
          false
        )
      );
    } else {
      result = await dispatch(
        callApiPostMethod(
          "CANCEL_STOPLOSS_ORDER",
          { userAddress: walletAddress?.toLowerCase(), salt: data?.salt },
          false,
          false
        )
      );
    }
    if (result?.status == 200) {
      handleClose();
      dispatch(setOrderPlaced(true));
    }
  };

  /**
   * function to fetch all limit and stop loss order of a particular user and their status
   */
  const getOrderHistory = async () => {
    setLoader(true);
    dispatch(setOrderPlaced(false));
    let balance: any;
    const updatedList = await tokenCollection.filter((value) => {
      return value.symbol != "WETH" && value.symbol != "ETH";
    });
    const tokensBalance = await Promise.all(
      updatedList.map(async (item: any) => {
        balance = await getTokenBalanceForNewUser({
          tokenAddress: item?.address,
          dispatch,
          walletAddress,
        });
        return {
          name: item?.symbol,
          token: item?.address?.toLowerCase(),
          balance,
        };
      })
    );
    const result: ORDER_HISTORY = await dispatch(
      callApiPostMethod(
        "USER_ORDER_HISTORY",
        { userAddress: walletAddress?.toLowerCase() },
        false,
        false
      )
    );
    const USDTInfo: TOKENS | undefined = dynamicContractDetails.find(
      (value: any) => {
        return value?.symbol == "USDT";
      }
    );
    const updatedUserHistoryData = result?.data?.map((value: any) => {
      tokensBalance.find((balanceInfo: any) => {
        if (value?.assetIn.toLowerCase() == balanceInfo?.token) {
          value.showWarning =
            Number(value?.amountsIn) < Number(balanceInfo?.balance)
              ? false
              : true;
        }
      });
      value.assetInName = tokenCollection?.find(
        (item) => item?.address?.toLowerCase() == value?.assetIn
      )?.symbol;
      value.assetOutName = tokenCollection?.find(
        (item) => item?.address?.toLowerCase() == value?.assetOut
      )?.symbol;
      value.mp =
        tokenTwo?.symbol == "USDT" &&
        (value?.assetIn == USDTInfo?.address ||
          value?.assetOut == USDTInfo?.address)
          ? Number(value?.marketPrice) / 10 ** 6
          : Number(value?.marketPrice) / 10 ** 18;
      value.assetInDecimal =
        value?.assetIn == USDTInfo?.address?.toLowerCase() ? 6 : 18;
      value.assetOutDecimal =
        value?.assetOut == USDTInfo?.address?.toLowerCase() ? 6 : 18;
      return value;
    });
    setOrderHistory(updatedUserHistoryData);
    setLoader(false);
  };
  return (
    <>
      <section className="TradeProfessionalPage">
        <ExchangeGraph />
        <div className="cardsRow">
          <Row>
            <Col lg={4}>
              
              <CommonCard
                noHeaderSpacing
                cardTitle={t('tradeOrderForm')}
                className="PlaceOrdersCard orderFormCard"
                walletNotConnected={walletAddress ? true : false}
              >
                <div className='coming_soon'  style={{ top: '50px', pointerEvents: 'none' }}>
            {/* <ClockIcon /> */}
            <h2>{t('comingSoon')}</h2>
            </div>
                <button className="setting_btn" onClick={() => setShow(true)}>
                  <SettingsIcon />
                </button>
                <SubHeader navigationLinks={navigationLinks} />
                <Outlet />
              </CommonCard>
              <SettingModal show={show} handleClose={() => setShow(false)} />

              {/* <CommonCard
                cardTitle={t('tradeOrderForm')}
                contentBg
                className="orderFormCard"
              >
                <SubHeader
                  navigationLinks={[
                    {
                      id: 1,
                      label: `${t('tradeTabMarket')}`,
                      to: "/auth/trade/professional/market",
                    },
                    {
                      id: 2,
                      label: `${t('tradeTabLimit')}`,
                      to: "/auth/trade/professional/limit",
                    },
                    {
                      id: 3,
                      label: `${t('tradeTabStop')}`,
                      to: "/auth/trade/professional/stop-orders",
                    },
                  ]}
                />
                <Outlet />
              </CommonCard> */}
            </Col>
            <Col lg={4}>
              <RecentTrades showTime={false} />
            </Col>
            {/* <Col lg={4} className="d-flex"> */}
            <Col lg={4}>
              <OrderBook className="professional_order_book" />
            </Col>
          </Row>
        </div>
        <Row>
          <Col xs={12}>
            <CommonCard
              noHeaderSpacing
              walletNotConnected={!walletAddress ? false : true}
              cardTitle={t('tradeMyPositions')}
              // className="PlaceOrdersCard"
              className="my_positions_card"
            >
              <CustomTable
                fields={[
                  {
                    key: "asset",
                    label: t('tradeAssets')
                  },
                  {
                    key: "amountOut",
                    label: t('tradeAmountOut')
                  },
                  {
                    key: "amountIn",
                    label: t('tradeAmountIn')
                  },
                  {
                    key: "mp",
                    label: t('tradeMP')
                  },
                  {
                    key: "op",
                    label: t('tradeOP')
                  },
                  {
                    key: "status",
                    label: t('tradeStatus')
                  },
                  {
                    key: "cancel",
                    label: t('tradeCancel')
                  }
                ]}
                loader={loader}
                // shimmerHeight={24}
              >
                {orderHistory?.map((item: any) => (
                  <tr
                    className={
                      item?.showWarning && item?.status != "cancelled"
                        ? "showWarning"
                        : ""
                    }
                  >
                    <td>
                      {item?.assetInName}/{item?.assetOutName}
                      &nbsp;&nbsp;
                      {item?.showWarning && item?.status != "cancelled" ? (
                        <Tooltip
                          className="mt-2"
                          icon={<WarningIcon />}
                          content={t('tradeInsufficientWalletError')}
                        />
                      ) : null}
                    </td>
                    <td>
                      {cryptoDecimals(
                        item?.amountsOut / 10 ** item?.assetOutDecimal
                      )}
                    </td>
                    <td>{item?.amountsIn / 10 ** item?.assetInDecimal}</td>
                    <td>{cryptoDecimals(item?.mp)}</td>
                    <td>{item?.rate / 10 ** 18}</td>
                    <td>
                      <span>{capitalizeFirstLetter(item?.status)}</span>
                      {/* <ButtonCustom
                        className="tradeBtn bordered-blue"
                        title={item?.status}
                        // onClick={() => setShowModal({ show: true, item: item })}
                        disabled={true}
                      /> */}
                    </td>
                    <td
                      onClick={() => {
                        item?.status != "created"
                          ? setShowModal({
                              show: true,
                              item: "Empty",
                              status: item?.status,
                            })
                          : setShowModal({
                              show: true,
                              item: item,
                              status: "",
                            });
                      }}
                    >
                      <CancelOrderIcon />
                    </td>
                  </tr>
                ))}

                <TransactionDone
                  show={showModal}
                  handleClose={handleClose}
                  handleFunction={cancelOrder}
                />
              </CustomTable>
            </CommonCard>
          </Col>
        </Row>

        {/* <MyPositions
          walletAddress={walletAddress}
          loader={loader}
          orderHistory={orderHistory}
        /> */}
      </section>
    </>
  );
};

export default TradeProfessional;

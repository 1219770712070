import React, { ReactNode, useState } from "react";
import "./Table.scss";
import { Table } from "react-bootstrap";
import Shimmer from "../Shimmer/Shimmer";
import {
  DownSortIcon,
  UpSortIcon,
} from "../../../Assets/Images/Icons/SvgIcons";
import { callApiPostMethod } from "../../../Redux/Actions/api.action";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { useTranslation } from "react-i18next";

type propTypes = {
  className?: string;
  children?: any;
  fields?: fieldType[];
  loader?: boolean;
  shimmerHeight?: number;
  sortIcons?: boolean;
  updateSortedData?: any;
  scrollingBar?: boolean; 
  tableRef?: any;
  fixedHeader?: boolean;
};

type fieldType = {
  key?: string,
  label?: string
}

const CustomTable = (props: propTypes) => {
  const {
    className,
    children,
    fields,
    sortIcons,
    loader,
    shimmerHeight,
    updateSortedData,
    scrollingBar,
    tableRef,
    fixedHeader
  } = props;
  const { t } = useTranslation();
  // const updatedField = fields?.includes("Asset") ? "Value" : "My Pool Shares";
  const updatedField = fields?.some(field => field.key === "asset") ? "Value" : "My Pool Shares";
  const [sortType, setSortType] = useState("up");
  const [fieldItem, setFieldItem] = useState(updatedField);
  const handleSortedData = async (field: any, upOrDown: string) => {
    setFieldItem(field);
    updateSortedData(field, upOrDown);
    setSortType(upOrDown);
  };
  return (
    <div className={scrollingBar ? "custom-table-container scrolling" : "custom-table-container"}>
      <Table responsive className={`${className || ""} CustomTable ${fixedHeader ? 'sticky-header' : ''}`} ref={tableRef}> 
        {fields && (
          <thead>
            <tr>
              {fields?.map((item) => (
                <th key={item.key}>
                  <div className="d-flex align-items-center">
                    {item.label}{" "}
                    {sortIcons &&
                      item.key != "" &&
                      item.key != "pool" &&
                      item.key != "asset" && (
                        <div className="sort_icons">
                          <span
                            className={
                              sortType == "up" && item.key == fieldItem
                                ? "active"
                                : ""
                            }
                            onClick={() => handleSortedData(item.key, "up")}
                          >
                            <UpSortIcon />
                          </span>
                          <span
                            className={
                              sortType == "down" && item.key == fieldItem
                                ? "active"
                                : ""
                            }
                            onClick={() => handleSortedData(item.key, "down")}
                          >
                            <DownSortIcon />
                          </span>
                        </div>
                      )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
        )}
        {loader ? (
          <tbody>
            {Array.from({ length: 5 }).map((item, index) => {
              return (
                <tr key={index}>
                  {fields?.map((item) => (
                    <td key={item.key}>
                      <Shimmer height={shimmerHeight || 24} fluid />
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            {children?.length > 0 ? (
              children
            ) : (
              <tr>
                <td className="text-center" colSpan={fields?.length}>
                  {t('customTableNoRecordFound')}
                </td>
              </tr>
            )}
          </tbody>
        )}
      </Table>
    </div>
  );
};
export default CustomTable;

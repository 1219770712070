import { FC } from "react";
import { Typography } from "@mui/material";
import "./LiquidityPoolGraphTooltip.scss";
import moment from "moment";
import { formatNumberWithMagnitudeSuffix, toCustomRoundedFixed } from "../../../Services/common.service";
import { useTranslation } from "react-i18next";

interface LiquidityPoolGraphTooltipProps {
  active?: boolean;
  payload?: {
    payload: any;
  }[];
  sliderDataForToolTip?: any;
}

const LiquidityPoolGraphTooltip: FC<LiquidityPoolGraphTooltipProps> = ({
  active,
  payload
}) => {
  const { t, i18n } = useTranslation();

  moment.locale(i18n.language);

  const tooltipVisible = active && payload && payload.length;
  if (!tooltipVisible) {
    return null;
  }
  const dataPoint = payload[0].payload;

  return (
    <div className={`custom-tooltip`}>
      <Typography className="custom-tooltip-sections">
        <div className="font-main">
          <span className="mx-1">
            <span>$</span>
            <span>{formatNumberWithMagnitudeSuffix(dataPoint?.value)}</span>
          </span>
          <span className={`mx-1 ${dataPoint?.valueChange === 0 ? '' : dataPoint?.valueChange > 0 ? 'positive' : 'negative'}`}>
            <span>{dataPoint?.valueChange === 0 ? "" : dataPoint?.valueChange > 0 ? "+" : ""}</span>
            <span>{toCustomRoundedFixed(dataPoint?.valueChange || 0, 2)}</span>
            <span>%</span>
          </span>
        </div>
      </Typography>
      <Typography className="custom-tooltip-sections">
        <div className="d-flex align-items-center">
          <span className="box_view"></span>
          <span className="font_fm">
            <span>{dataPoint?.pair0In1Out?.split("/")[0]}</span>
            <span className="mx-1">:</span>
            <span>{formatNumberWithMagnitudeSuffix(dataPoint?.reserve0)}</span>
          </span>
        </div>

        <div className="d-flex align-items-center">
          <span className="box_view"></span>
          <span className="font_fm">
            <span>{dataPoint?.pair0In1Out?.split("/")[1]}</span>
            <span className="mx-1">:</span>
            <span>{formatNumberWithMagnitudeSuffix(dataPoint?.reserve1)}</span>
          </span>
        </div>
      </Typography>
      <Typography className="custom-tooltip-sections">
        {moment.utc(Number(dataPoint?.timestamp)*1000).format("MMM Do hh:mm A UTC")}
      </Typography>
    </div>
  );
};

export default LiquidityPoolGraphTooltip;

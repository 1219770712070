import React, { UIEvent, useEffect, useState } from "react";
import "./TermsConditions.scss";
import CommonHeading from "../../../Common/Heading/Heading";
import { Container } from "react-bootstrap";
import ButtonCustom from "../../../Common/Button/ButtonCustom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUserOnboardingData } from "../../../../Redux/Slices/user.slice";
import {
  approveNetwork,
  connectmetamask,
  createUserProgress,
} from "../../../../Redux/Actions/user.action";
import { getTnCSignature } from "../../../../Services/getSignature";
import { DATA_INSERTED } from "../../../../Redux/Actions/apiResponseInterfaces";
import { useTranslation } from "react-i18next";

const TermsConditions = () => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const data = [
    {
      id: 1,
      title: t('termsAcceptanceOfTermsHeading'),
      content: [t('termsAcceptanceOfTermsDescription')],
    },
    {
      id: 2,
      title: t('termsDescriptionOfPlatformHeading'),
      content: [t('termsDescriptionOfPlatformDescription')],
    },
    {
      id: 3,
      title: t('termsUserResponsibilityHeading'),
      content: [t('termsUserResponsibilityDescription')],
    },
    {
      id: 4,
      title: t('termsShariahComplainceHeading'),
      content: [t('termsShariahComplainceDescription')],
    },
    {
      id: 5,
      title: t('termsStakingProgramAndPoolsHeading'),
      content: [t('termsStakingProgramAndPoolsDescription')],
    },
    {
      id: 6,
      title: t('termsLimitationOfLiabilityHeading'),
      content: [t('termsLimitationOfLiabilityDescription1'), t('termsLimitationOfLiabilityDescription2')],
    },
    {
      id: 7,
      title: t('termsNoResponsibilityHeading'),
      content: [t('termsNoResponsibilityDescription')],
    },
    {
      id: 8,
      title: t('termsAmendmentsOfTermsHeading'),
      content: [t('termsAmendmentsOfTermsDescription')],
    },
    {
      id: 9,
      title: t('termsGoverningLawsHeading'),
      content: [t('termsGoverningLawsDescription1')],
    },
  ];

  const userwalletAddress: string = useSelector(
    (state: any) => state?.user?.walletAddress
  );
  const termsncond: boolean = useSelector(
    (state: any) => state?.user?.userDetails?.termsncond
  );
  const [reachedBottom, setReachedBottom] = useState<boolean>(false);

  const handleTermsCondions = async () => {
    try {
      await dispatch(connectmetamask());
      const signature = await getTnCSignature(userwalletAddress);
      
      if (!signature) throw new Error(t('termsSignatureNotObtainedError'));
  
      const result = await createUserProgress({
        value: 100,
        label: "auth/dashboard/libfi",
        address: userwalletAddress,
        termsncond: true,
        signature: signature,
        dispatch,
        language: i18n.language,
        shariah: false
      });
  
      if (!result) throw new Error(t('termsUserProgressUpdateFailedError'));
  
      dispatch(setUserOnboardingData({
        progress: 100,
        path: "/auth/dashboard/libfi",
      }));
      navigate("/auth/dashboard/libfi");
    } catch (error) {
      console.error("Error in handleTermsConditions:", error);
    }
  };  

  const handleScroll = (e: UIEvent<HTMLUListElement>) => {
    const isBottom = e.currentTarget.scrollHeight - (e.currentTarget.scrollTop + e.currentTarget.clientHeight) <= 5;
    setReachedBottom(isBottom);
  };  
  return (
    <section className="TermsConditions">
      <Container>
        <CommonHeading
          smallHeading
          centered
          heading={t('termsTitle')}
        />
        <ul onScroll={handleScroll}>
          {data?.map((item) => (
            <li key={item?.id}>
              <h4>{item?.title}</h4>
              {item.content.map((para) => (
                <p>{para}</p>
              ))}
            </li>
          ))}
        </ul>
        <div>
          <ButtonCustom
            title={termsncond ? t('continueButton') : t('termsAccept')}
            className="acceptBtn"
            disabled={!reachedBottom}
            onClick={() => handleTermsCondions()}
          />
        </div>
      </Container>
    </section>
  );
};

export default TermsConditions;

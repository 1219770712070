import React, { useContext, createContext, useRef, useEffect, useState } from "react";
import "./EarnAMMPools.scss";
import CommonCard from "../../../Common/Cards/CommonCard/CommonCard";
import ButtonCustom from "../../../Common/Button/ButtonCustom";
import CustomTable from "../../../Common/Table/Table";
import {
  LeftArrow,
  SearchIconTwo,
} from "../../../../Assets/Images/Icons/SvgIcons";
import Select from "react-select";
import InputCustom from "../../../Common/FormInputs/Input/Input";
import { useLocation, useNavigate } from "react-router-dom";
import EarnModal from "../../../Common/Modals/EarnModal/EarnModal";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { callApiPostMethod } from "../../../../Redux/Actions/api.action";
import {
  setGlobalPoolData,
  setUserSubscribed,
} from "../../../../Redux/Slices/user.slice";
import SettingModal from "../../../Common/Modals/SettingModal/SettingModal";
import {
  cryptoDecimals,
  fromWei,
  toCustomFixed,
} from "../../../../Services/common.service";
import { zeroAddress } from "./EarnAMMHelper";
import {
  getLPBalance,
  getTotalSupply,
} from "../../../../Services/contractCallService";
import { hundred } from "../../../../Utils";
import Tooltip from "../../../Common/Tooltip/Tooltip";
import { dynamicContractDetails } from "../../../../Services/dynamicContractDetails";
import { fromWeiConvert, formatNumberWithMagnitudeSuffix } from "../../../../Services/common.service";
import {
  DOLLAR_VAL,
  POOLS,
} from "../../../../Redux/Actions/apiResponseInterfaces";
import {
  COMMON_DATA,
  ReserveHelper,
  TOKENS,
  TOKEN_DETAILS,
} from "../../../../interfaces/commonInterfaces";
import {
  CloseIcon,
  DownArrowCircle,
  QuestionMarkBlack,
  SettingsIcon,
  SwapIcon,
  WalletIcon,
  TableViewIcon,
  PanelViewIcon,
  PanelSwitchIcon,
} from "../../../../Assets/Images/Icons/SvgIcons";

import {
  getUserInformationForRemovingLiquidity,
} from "../../../Pages/Private/EarnAMMPools/RedeemAMMHelper";
import {
  getReservesHelper,
  getTotalReservesOfTokensInPool,
} from "../../../Pages/Private/EarnAMMPools/EarnAMMHelper";
import { callContractGetMethod } from "../../../../Redux/Actions/contract.action";
import { isSharedArrayBuffer } from "util/types";
import { useTranslation } from "react-i18next";
import { loader } from "../../../../Redux/Slices/loader.slice";
import { getPoolData } from "../../../../hooks/useFetchPoolData";

const EarnAMMPools = () => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { walletAddress } = useSelector((state: any) => state?.user);
  const globalPoolData = useSelector((state: any) => state?.user?.poolData);
  const [ModalShow, setModalShow] = useState(false);
  const isSubscribed = useSelector((state: any) => state?.user?.subscribed);
  const [show, setShow] = useState<boolean>(false);
  const [showTab, setShowTab] = useState<string>("earn");
  const [poolData, setPoolData] = useState<any>({});

  const [searchQuery, setSearchQuery] = useState<String>("");
  const [searchResults, setSearchResults] = useState<POOLS["data"]>([]);
  const [Loader, setLoader] = useState<boolean>(false);
  const [notLoaded, setNotLoaded] = useState<boolean>(false);
  const [sorting, setSorting] = useState<{ field: String; upOrDown: String }>({
    field: 'myPoolShares',
    upOrDown: "up",
  });
  const [isRefreshing, setIsRefreshing] = useState(false);
  const assetName = location?.state?.assetName;

  useEffect(() => {
    if (walletAddress) handlePools();
  }, [walletAddress, sorting]);



  useEffect(() => {
    console.log("Loader : " + Loader)
    console.log("notLoaded : " + notLoaded)
    console.log("searchResults.length : " + searchResults.length)
  }, [Loader, notLoaded, searchResults]);


  /**
  * This useEffect hook allows refreshing data every 30s 
  * if no action has been performed by the user
  **/
  useEffect(() => {
    // Run the following functions every 30000 milliseconds = 30 seconds if the user wallet is connected
    const interval = setInterval(() => {
      const runRefreshFunctions = async () => {
        if (walletAddress) {
  
          setIsRefreshing(true); 

          await handlePools(); 

          setIsRefreshing(false); 

        }
      };
  
      runRefreshFunctions();
    }, 30000);
  
    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    console.log("loader: " + loader)
  }, [loader]);

  useEffect(() => {
    if (isSubscribed) {
      handleSearchWhenSubscribed();
    }
  }, [isSubscribed]);

  /**
   * function to set the to be searched value when user clicks on earn button of investment tokens
   */
  const handleSearchWhenSubscribed = () => {
    dispatch(setUserSubscribed(false));
    setSearchQuery(assetName);
  };

  /**
   * function to show different pools and modifying it based on our calculation
   */
  const handlePools = async () => {
    setLoader(true);
    if (sorting) {
      let result: POOLS;
      const value: string | null =
        sorting.field === 'lockedLPTokens'
          ? "totalLPAmount"
          : sorting.field === 'volume'
            ? "volume"
            : null;
      const poolData = await getPoolData({
        dispatch,
        walletAddress,
        sortBy: value || "",
        reverse: sorting.upOrDown === "up"
      });

      dispatch(setGlobalPoolData(poolData));      
      if (!poolData || poolData.length === 0) { 
        setNotLoaded(true);
      } else {
        if (assetName?.length) {
          await handleSearch(poolData, assetName);
        } else { 
          setSearchResults(poolData);
        }
      }
      setLoader(false);
    }
  }

  /**
   * function to filter the pools based on the searched value
   * @param data total pools in the platform
   * @param searchStringData pool name to be searched
   */

  const handleSearch = async (data: POOLS["data"], searchStringData: any) => {
    setSearchQuery(searchStringData);
    const filteredResults: POOLS["data"] = data?.filter(
      (item: any) =>
        item?.token0Name
          ?.toLowerCase()
          ?.includes(searchStringData?.toLowerCase()) ||
        item?.token1Name
          ?.toLowerCase()
          ?.includes(searchStringData?.toLowerCase())
    );
    setSearchResults(filteredResults);

  };

  /**
   * function to show respective modal
   * @param show boolean value to show modal or not
   * @param tabToShow which modal to show earn or redeem
   * @param userSelectedPool which pool to show
   */
  const handleShow = (
    show: boolean,
    tabToShow: string,
    userSelectedPool: POOLS["data"]
  ) => {
    setShow(show);
    setShowTab(tabToShow);
    setPoolData(userSelectedPool);
  };

  const updateSortedData = (data: string, sortType: string) => {
    setSorting({ field: data, upOrDown: sortType });
  };

  // Manage switch table view / panel view
  const [buttonPanelClicked, setButtonPanelClicked] = useState(false);

  const handleClickPanelButton = () => {
    setButtonPanelClicked(true);
  };

  const handleClickTableButton = () => {
    setButtonPanelClicked(false);
  };

  // Manage display for panel
  const [displayFirstValue, setDisplayFirstValue] = useState(true);
  const handleClick = () => {
    setDisplayFirstValue(prevValue => !prevValue);
  };

  const [showUpdatedContent, setShowUpdatedContent] = useState(Array(searchResults.length).fill(false));

  const handleButtonClick = (index) => {
    const newShowUpdatedContent = [...showUpdatedContent];
    newShowUpdatedContent[index] = !newShowUpdatedContent[index];
    setShowUpdatedContent(newShowUpdatedContent);
  };

  const calculateTVL = (item) => {
    const tvl = (Number(item.reserve1) * Number(item.dollarValue0)) + (Number(item.reserve2) * Number(item.dollarValue1));
    return tvl;
  };

  const calculateMyLiquidity = (item) => {
    const res = Number(item.share) * (Number(calculateTVL(item)) / 100);
    return res;
  };


  //Dropdown - Customization
  const options = [
    { value: "volume", label: (t('ammPoolsSortByField')).replace('{{fieldName}}', "Volume") },
    { value: "tvl", label: (t('ammPoolsSortByField')).replace('{{fieldName}}', "TVL") },
    { value: "apr", label: (t('ammPoolsSortByField')).replace('{{fieldName}}', "APR") }
  ]

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      borderRadius: '16px',
      border: '1.5px solid #E5F7FF',
      boxShadow: "none",
      boxSizing: 'border - box',
      wordWrap: 'break-word',
      color: 'white',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#181431",
      color: 'white',
      width: `${menuWidth}px`,
      marginTop: 2,
      borderRadius: '16px',
      border: '1.5px solid #FFFFFF',
      borderShadow: 'none',
    }),
    menuList: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        borderRadius: '16px',
        backgroundColor: isSelected ? "blue" : isFocused ? "rgb(65, 105, 239)" : undefined
      };
    },
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
    }),
    dropdownIndicator: base => ({
      ...base,
      color: 'white'
    })
  };

  // Dropdown - Ensure all items of the menu are on one line
  const menuWidth = options.reduce((maxWidth, option) => {
    const labelWidth = option.label.length * 8;
    return labelWidth > maxWidth ? labelWidth : maxWidth;
  }, 0);

  // Dropdown - Keep track of the selected option
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  // Compute Percentage of The First Token in a Pool
  function calculateToken1Percentage(NumberOfToken1, NumberOfToken2) {
    // Calculate total value of both tokens in the pool
    const totalValueInPool = parseFloat(NumberOfToken1) + parseFloat(NumberOfToken2);

    // Calculate percentage of first token
    const percentageOfFirstToken = (parseFloat(NumberOfToken1) / totalValueInPool) * 100;
    const percentageOfSecondToken = 100 - percentageOfFirstToken;

    // Return the percentages of the tokens
    return {
      percentageOfFirstToken: toCustomFixed(percentageOfFirstToken, 2),
      percentageOfSecondToken: toCustomFixed(percentageOfSecondToken, 2)
    };
  }

  // Ordering
  const sortResults = (criteria) => {
    return [...searchResults].sort((a, b) => {
      if (criteria === 'volume') {
        return Number(b.volumeInUSD) - Number(a.volumeInUSD);
      } else if (criteria === 'tvl') {
        return calculateTVL(b) - calculateTVL(a);
      } //else if (criteria === 'apr') {
      //return b.apr - a.apr;
      //}
      return 0;
    });
  };



  const sortedResults = selectedOption ? sortResults(selectedOption.value) : searchResults;
  const combinedResults = [...new Set(sortedResults.concat(searchResults))];
  window?.addEventListener("popstate", function (event) {
    navigate("/auth/earn/overview");
  });


  // Check The Table and Add The Vertical Line Accordingly
  const [isLastColumnVisible, setIsLastColumnVisible] = useState(true);

  const tableRef = React.useCallback(node => {
    if (node !== null) {
      const lastColumn = node.querySelector('th:last-child');
      const handleResize = () => {
        if (lastColumn) {
          const rect = lastColumn.getBoundingClientRect();
          setIsLastColumnVisible(rect.right <= window.innerWidth);
          //console.log("Last Column Width:", rect.width, "Window Width:", window.innerWidth, "Is Visible:", rect.right <= window.innerWidth);
        }
      };
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  useEffect(() => {
    if (!isLastColumnVisible) {
      document.documentElement.style.setProperty('--VerticalLine', 'linear-gradient(90deg, rgba(242, 242, 242, 0) 0%, rgb(18, 18, 18) 100%)');
    } else {
      document.documentElement.style.setProperty('--VerticalLine', 'none');
    }
  }, [isLastColumnVisible]);

  // options array will be changed once language changed. Updated the selected option accordingly. 
  useEffect(() => {
    const selectedOp = options.find(o => o.value === selectedOption.value);
    setSelectedOption({ value: selectedOption.value, label: selectedOp?.label || "" });
  }, options)

  return (
    <section className="EarnAmmPools">
      <div className="EarnAmmPoolsHead">
        <h3>
          <button onClick={() => navigate("/auth/earn/overview")}>
            <LeftArrow />
          </button>{" "}
          {t('ammPoolsLiquidityPools')}
        </h3>
        <div className="EarnAmmPoolsHead-options">
          <div className="EarnAmmPoolsHead-options-display">
            {!buttonPanelClicked && (
              <Select className="EarnAmmPoolsHead-options-ordering"
                value={selectedOption}
                hideSelectedOptions={true}
                options={options}
                styles={customStyles}
                isSearchable={false}
                onChange={handleChange}
              />
            )}
            <button className="EarnAmmPoolsHead-options-display-button"
              style={{ color: buttonPanelClicked ? '#4169ef' : 'white' }}
              onClick={handleClickPanelButton} >
              <TableViewIcon />
            </button>
            <button className="EarnAmmPoolsHead-options-display-button"
              style={{ color: buttonPanelClicked ? 'white' : '#4169ef' }}
              onClick={handleClickTableButton} >
              <PanelViewIcon />
            </button>
          </div>
          <InputCustom
            leftIcon={<SearchIconTwo />}
            placeholder={t('earnPoolSearchPlaceholder')}
            onChange={(e: any) => {
              e.preventDefault();
              handleSearch(globalPoolData, e?.target?.value);
            }}
            value={searchQuery}
          />
        </div>
      </div>


      {/*  Code start for panel view */}
      {!buttonPanelClicked && (

        <div className={`liquidity-pools-section${ ( Loader && combinedResults.length == 0 ) || notLoaded || combinedResults.length === 0 ? '' : ' grid'}`}>
          {!walletAddress && combinedResults.length === 0 &&
            <div style={{ height: '20px' }}>
              <CommonCard walletNotConnected={walletAddress} />
            </div>
          }

          {!Loader && !notLoaded || combinedResults.length !== 0
            ? combinedResults?.map((item: any, index) => {
              const itemId = item.id;

              //for (let key in item) {
              //  console.log("item - " + key + ':', item[key]);
              //}
              return (

                <div>
                  {/* Code start for "view pool" */}
                  {!showUpdatedContent[index] && (
                    <CommonCard
                      walletNotConnected={walletAddress ? true : false}
                    >
                      <div className="liquidity-pool-container" key={itemId}>
                        <div className="liquidity-pool-sub-container-panel-1">
                          <div className="liquidity-pool-header">
                            <span className="poolIcons">
                              <img
                                className="poolIconOne"
                                src={item?.firstTokenURI}
                                alt=""
                              />
                              <img
                                className="poolIconTwo"
                                src={item?.secondTokenURI}
                                alt=""
                              />
                            </span>
                            <span>
                              <span>{item?.token0Name}</span>
                              <span>/</span>
                              <span>{item?.token1Name}</span>
                            </span>

                          </div>
                          <div className="liquidity-pool-id-container">
                            <a href={`https://sepolia.arbiscan.io/address/${item?.pairAddress}`} target="_blank">
                              {`${item?.pairAddress.substring(0, 6)}...${item?.pairAddress.substring(item?.pairAddress.length - 4)}`}
                            </a>
                          </div>
                          <div className="liquidity-pool-apr-title-container">
                            <div className="liquidity-pool-apr-title-sub-container">
                              <div className="liquidity-pool-apr-title">
                                <div className="liquidity-pool-apr-title-text">
                                  {t('ammPoolsAvgAPR')}
                                </div>
                                <Tooltip>
                                  <p>{t('ammPoolsAvgAPRToolTip')}</p>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                          <div className="liquidity-pool-apr-data-container">
                            <div className="liquidity-pool-apr-data">
                              <span>2.01</span>
                              <span>%</span>
                            </div>
                          </div>
                          <div className="liquidity-pool-details-container">
                            <table className="liquidity-pool-details-table">
                              <tbody>
                                <tr>
                                  <td className="liquidity-pool-details-table-column1">{t('ammPoolsVolumeColumn')}</td>
                                  <td className="liquidity-pool-details-table-column2">{t('ammPoolsTVLColumn')}</td>
                                </tr>
                                <tr>
                                  <td className={`liquidity-pool-details-table-column1 ${isRefreshing ? 'text-shadow' : ''}`}>                     
                                    <p>
                                      {item?.volumeInUSD ? (
                                        <Tooltip
                                          className="wallet_text_tooltip"
                                          heading={item?.volume}
                                          icon={document.body.dir === "rtl" ? `${formatNumberWithMagnitudeSuffix(item?.volumeInUSD)}$` : `$${formatNumberWithMagnitudeSuffix(item?.volumeInUSD)}`}
                                        />
                                      ) : (
                                        "0.00"
                                      )}
                                    </p>
                                  </td>
                                  <td className={`liquidity-pool-details-table-column2 ${isRefreshing ? 'text-shadow' : ''}`}>
                                    <p>
                                      <span>$</span>
                                      <span>{formatNumberWithMagnitudeSuffix(calculateTVL(item))}</span>
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="liquidity-pool-separator"></div>
                          <div className="liquidity-pool-details-container">
                            <table className="liquidity-pool-details-table">
                              <tbody>
                                <tr>
                                  <td className="liquidity-pool-details-table-column1">{t('ammPoolsLockedAssetValueColumn')}</td>
                                  <td className="liquidity-pool-details-table-column2">{t('ammPoolsMyPoolShareColumn')}</td>
                                </tr>
                                <tr>
                                  <td className={`liquidity-pool-details-table-column1 ${isRefreshing ? 'text-shadow' : ''}`}>
                                    <p>
                                      <span>$</span>
                                      <span>{formatNumberWithMagnitudeSuffix(calculateMyLiquidity(item))}</span>
                                    </p>
                                  </td>
                                  <td className={`liquidity-pool-details-table-column2 ${isRefreshing ? 'text-shadow' : ''}`}>
                                    <p>
                                      <span>{`${item?.share ? toCustomFixed(item?.share, 2) : "0.00"
                                        } `}</span>
                                      <span>%</span>
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="liquidity-pool-buttons">
                            <td>
                              <ButtonCustom
                                className="bordered-blue"
                                title={t('ammPoolsEarnButton')}
                                onClick={() => {
                                  handleShow(true, "earn", item);
                                }}
                              />
                              <ButtonCustom
                                className="bordered-blue"
                                title={t('ammPoolsRedeemButton')}
                                onClick={() => {
                                  handleShow(true, "redeem", item);
                                }}
                              />
                            </td>
                          </div>
                          <div role="button" className="liquidity-pool-switch-panel-container" onClick={() => handleButtonClick(index)}>
                            <span className="liquidity-pool-switch-panel-img">
                              <PanelSwitchIcon />
                            </span>
                            <div className="liquidity-pool-switch-panel-text">
                              {t('ammPoolsViewPoolDetails')}
                            </div>
                          </div>
                        </div>
                      </div>
                    </CommonCard>
                  )}

                  {/* Code start for "view pool details" */}
                  {showUpdatedContent[index] && (
                    <CommonCard
                      walletNotConnected={walletAddress ? true : false}
                    >
                      <div className="liquidity-pool-container" key={itemId}>
                        <div className="liquidity-pool-sub-container-panel-2">
                          <div className="liquidity-pool-header">
                            <span className="poolIcons">
                              <img
                                className="poolIconOne"
                                src={item?.firstTokenURI}
                                alt=""
                              />
                              <img
                                className="poolIconTwo"
                                src={item?.secondTokenURI}
                                alt=""
                              />
                            </span>
                            <span>
                              <span>{item?.token0Name}</span>
                              <span>/</span>
                              <span>{item?.token1Name}</span>
                            </span>
                          </div>

                          <div className="liquidity-pool-assets-container">
                            <div className="liquidity-pool-assets-container-image">
                              <img
                                className="poolIconOne"
                                src={item?.firstTokenURI}
                                alt=""
                              />
                            </div>
                            <div className="liquidity-pool-assets-token-1-container">
                              <div className="liquidity-pool-assets-token-1">
                                {`${item?.token0Name}`}
                              </div>
                              <div className={`liquidity-pool-assets-token-1-bis ${isRefreshing ? 'text-shadow' : ''}`}>
                                {formatNumberWithMagnitudeSuffix(item?.reserve1)}
                              </div>
                              <div className={`liquidity-pool-assets-token-1-bis ${isRefreshing ? 'text-shadow' : ''}`}>
                              <span style={{ color: 'rgb(169, 169, 169)' }}>{calculateToken1Percentage(item?.reserve1, item?.reserve2).percentageOfFirstToken}%</span>
                              </div>
                            </div>
                            <div className="liquidity-pool-assets-token-2-container">
                              <div className="liquidity-pool-assets-token-2">
                                {`${item?.token1Name}`}
                              </div>
                              <div className={`liquidity-pool-assets-token-2-bis ${isRefreshing ? 'text-shadow' : ''}`}>
                                {formatNumberWithMagnitudeSuffix(item?.reserve2)}
                              </div>
                              <div className={`liquidity-pool-assets-token-2-bis ${isRefreshing ? 'text-shadow' : ''}`}>
                                <span style={{ color: 'rgb(169, 169, 169)' }}>{calculateToken1Percentage(item?.reserve1, item?.reserve2).percentageOfSecondToken}%</span>
                              </div>
                            </div>
                            <div className="liquidity-pool-assets-container-image">
                              <img
                                className="poolIconTwo"
                                src={item?.secondTokenURI}
                                alt=""
                              />
                            </div>
                          </div>

                          <div className="liquidity-pool-current-price-container">
                            <div className="liquidity-pool-current-price-title">
                              <p>
                                {t('ammPoolsCurrentPrice')}
                              </p>
                            </div>
                            <div className="liquidity-pool-current-price">
                              {displayFirstValue ? (
                                <p className={`liquidity-pool-current-price-text ${isRefreshing ? 'text-shadow' : ''}`}>
                                  <span>1</span>
                                  <span>{item?.token0Name}</span>
                                  <span>=</span>
                                  <span>{formatNumberWithMagnitudeSuffix(item?.dollarValue0 / item?.dollarValue1)}</span>
                                  <span>{item?.token1Name}</span>
                                </p>
                              ) : (
                                <p className={`liquidity-pool-current-price-text ${isRefreshing ? 'text-shadow' : ''}`}>
                                  <span>1</span>
                                  <span>{item?.token1Name}</span>
                                  <span>=</span>
                                  <span>{formatNumberWithMagnitudeSuffix(item?.dollarValue1 / item?.dollarValue0)}</span>
                                  <span>{item?.token0Name}</span>
                                </p>
                              )}
                              <button className="swapBtn" onClick={handleClick}>
                                <SwapIcon />
                              </button>
                            </div>
                          </div>

                          <div className="liquidity-pool-details-container">
                            <table className="liquidity-pool-details-table">
                              <tbody>
                                <tr>
                                  <td className="liquidity-pool-details-table-column1">{t('ammPoolsLockedAssetValueColumn')}</td>
                                  <td className="liquidity-pool-details-table-column2">{t('ammPoolsMyPoolShareColumn')}</td>
                                </tr>
                                <tr>
                                  <td className={`liquidity-pool-details-table-column1 ${isRefreshing ? 'text-shadow' : ''}`}>
                                    <p>
                                      <span>$</span>
                                      <span>{formatNumberWithMagnitudeSuffix(calculateMyLiquidity(item))}</span>
                                    </p>
                                  </td>
                                  <td className={`liquidity-pool-details-table-column2 ${isRefreshing ? 'text-shadow' : ''}`}>
                                    <p>
                                      <span>{`${item?.share ? toCustomFixed(item?.share, 2) : "0.00"} `}</span>
                                      <span>%</span>
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="liquidity-pool-buttons">
                            <td>
                              <ButtonCustom
                                className="bordered-blue"
                                title={t('ammPoolsEarnButton')}
                                onClick={() => {
                                  handleShow(true, "earn", item);
                                }}
                              />
                              <ButtonCustom
                                className="bordered-blue"
                                title={t('ammPoolsRedeemButton')}
                                onClick={() => {
                                  handleShow(true, "redeem", item);
                                }}
                              />
                            </td>
                          </div>
                          <div role="button" className="liquidity-pool-switch-panel-container" onClick={() => handleButtonClick(index)}>
                            <span className="liquidity-pool-switch-panel-img">
                              <PanelSwitchIcon />
                            </span>
                            <div className="liquidity-pool-switch-panel-text">
                              {t('ammPoolsViewPool')}
                            </div>
                          </div>
                        </div>
                      </div>
                    </CommonCard>
                  )}

                </div>

              );


            })
            : notLoaded ? (
              // Render "Ops" if notLoaded is true
              <p>{t('failedloading')}</p>
            ) : (
              // Render "Loading" if Loader is true
              <p>{t('loading')}</p>
            )
          }


        </div>
      )}


      {/*  Code start for table view */}
      {buttonPanelClicked && (
        <CommonCard
          walletNotConnected={walletAddress ? true : false}
        >
          <CustomTable
            sortIcons
            fields={[
              {
                key: "pool",
                label: t('ammPoolsPool')
              },
              {
                key: "myPoolShares",
                label: t('ammPoolsPositions')
              },
              {
                key: "lockedLPTokens",
                label: t('ammPoolsLiquidity')
              },
              {
                key: "volume",
                label: t('ammPoolsVolume')
              },
              {
                key: "apr",
                label: t('ammPoolsAPR')
              },
              {
                key: "",
                label: ""
              },
            ]}
            updateSortedData={updateSortedData}
            loader={searchResults.length !== 0 ? false : Loader}
            scrollingBar={true}
            tableRef={tableRef}
          >

            { !Loader && !notLoaded || searchResults.length !== 0
              ? searchResults?.map((item: any) => {
                return (
                  <tr>
                    <td>
                      <span className="poolIcons">
                        <img
                          className="poolIconOne"
                          src={item?.firstTokenURI}
                          alt=""
                        />
                        <img
                          className="poolIconTwo"
                          src={item?.secondTokenURI}
                          alt=""
                        />
                      </span>
                      <span>
                        <span>{item?.token0Name}</span>
                        <span>/</span>
                        <span>{item?.token1Name}</span>
                      </span>
                    </td>
                    <td className={`text-decoration ${isRefreshing ? 'text-shadow' : ''}`}>
                      <span>{`${item?.share ? toCustomFixed(item?.share, 2) : "0.00"} `}</span>
                      <span>%</span>
                    </td>
                    <td className={`text-decoration ${isRefreshing ? 'text-shadow' : ''}`}>
                      {item?.totalLPAmount ? (
                        <Tooltip
                          className="wallet_text_tooltip"
                          heading={item?.totalLPAmount}
                          icon={cryptoDecimals(item?.totalLPAmount)}
                        />
                      ) : (
                        "0.00"
                      )}
                    </td>
                    <td className={`${isRefreshing ? 'text-shadow' : ''}`}>
                      {item?.volumeInUSD ? (
                        <Tooltip
                          className="wallet_text_tooltip"
                          heading={item?.volumeInUSD}
                          icon={cryptoDecimals(item?.volumeInUSD)}
                        />
                      ) : (
                        "0.00"
                      )}
                    </td>
                    <td className={`text-decoration ${isRefreshing ? 'text-shadow' : ''}`}>-</td>
                    <td>
                      <ButtonCustom
                        className="bordered-blue"
                        title={t('ammPoolsEarnButton')}
                        onClick={() => {
                          handleShow(true, "earn", item);
                        }}
                      />
                      <ButtonCustom
                        className="bordered-blue"
                        title={t('ammPoolsRedeemButton')}
                        onClick={() => {
                          handleShow(true, "redeem", item);
                        }}
                      />
                    </td>
                  </tr>
                );
              })
              : t('dataFetching')}
          </CustomTable>
        </CommonCard>
      )}
      {/*  Code end for table view */}

      <EarnModal
        show={show}
        handleClose={() => setShow(false)}
        showTab={showTab}
        handlePools={handlePools}
        showSettingsModal={() => {
          setModalShow(true);
        }}
        selectedPool={poolData || ""}
      />
      <SettingModal
        show={ModalShow}
        handleClose={() => {
          setModalShow(false);
        }}
      />
    </section>
  );
};

export default EarnAMMPools;
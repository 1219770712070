import React, { ReactNode } from "react";
import { InfoIcon } from "../../../Assets/Images/Icons/SvgIcons";
import "./Tooltip.scss";
import { OverlayTrigger, Tooltip as BTooltip } from "react-bootstrap";

const Tooltip = (props: {
  icon?: ReactNode;
  children?: ReactNode;
  heading?: string;
  className?: string;
  content?: string;
  placement?: "left" | "right" | "top" | "bottom";
}) => {
  return (
    <>
      <OverlayTrigger
        placement={props?.placement ? "left" : "auto"}
        overlay={
          <BTooltip
            className={`CommonTooltip ${props?.className || ""}`}
            id="button-tooltip"
          >
            <>
              {props?.children || (
                <div>
                  <h4>{props?.heading}</h4>
                  <p>{props?.content}</p>
                </div>
              )}
            </>
          </BTooltip>
        }
      >
        <span className="tooltip_btn">{props?.icon || <InfoIcon />}</span>
      </OverlayTrigger>
    </>
  );
};

export default Tooltip;

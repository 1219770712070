import { useEffect, useState, useCallback } from "react";
import {
  getLPBalance,
  getTotalSupply,
} from "../Services/contractCallService";
import { COMMON_DATA } from "../interfaces/commonInterfaces";
import { callApiPostMethod } from "../Redux/Actions/api.action";
import { DOLLAR_VAL, POOLS } from "../Redux/Actions/apiResponseInterfaces";
import { getTotalReservesOfTokensInPool, zeroAddress } from "../Components/Pages/Private/EarnAMMPools/EarnAMMHelper";
import { callContractGetMethod } from "../Redux/Actions/contract.action";
import { dynamicContractDetails } from "../Services/dynamicContractDetails";
import { hundred } from "../Utils";

export const getPoolData = async ({
  dispatch,
  walletAddress,
  sortBy,
  reverse,
}: {
    dispatch: any;
    walletAddress: string;
    sortBy?: string;
    reverse?: boolean;
  }) => {
  const result = await dispatch(
    callApiPostMethod(
      "GET_POOLS",
      sortBy ? { sort: sortBy, reverse } : {},
      false,
      false
    )
  );

  const fetchDollarValue = async () => {
    const result: DOLLAR_VAL = await dispatch(
      callApiPostMethod("DOLLAR_VALUE", {}, false, false)
    );
    return result?.data;
  };

  if (result) {
    const dollarValueArray: DOLLAR_VAL["data"] = await fetchDollarValue();
    const outputData: any = await JSON.parse(
      JSON.stringify(result?.data)
    )?.map(async (data: any) => {
      if (data?.pairAddress?.toLowerCase() != zeroAddress?.toLowerCase()) {
        let totalLiquidity: number | 0 = await getTotalSupply({
          pairAddress: data?.pairAddress,
          dispatch,
        });
        const rdata: COMMON_DATA = {
          tokenOneAddress: data?.token0Address,
          tokenTwoAddress: data?.token1Address,
          dispatch,
        };

        let reserveA, reserveB;
        if (rdata.tokenOneAddress !== undefined && rdata.tokenTwoAddress !== undefined) {
          ({ reserveA, reserveB } = await getTotalReservesOfTokensInPool(rdata));
        }

        if (reserveA && reserveB) {
          const decimalsA: number = await dispatch(
            callContractGetMethod(
              "decimals",
              [],
              "dynamic",
              false,
              data?.token0Address
            )
          );
          const decimalsB: number = await dispatch(
            callContractGetMethod(
              "decimals",
              [],
              "dynamic",
              false,
              data?.token1Address
            )
          );
          data.reserve1 = reserveA.toString()
          data.decimals1 = decimalsA
          data.reserve2 = reserveB.toString()
          data.decimals2 = decimalsB
        } else {
          data.reserve1 = "0"
          data.decimals1 = 0
          data.reserve2 = "0"
          data.decimals2 = 0
        }

        data.firstTokenURI = dynamicContractDetails?.find(
          (info: any) =>
            info?.address?.toLowerCase() ==
            data?.token0Address?.toLowerCase()
        )?.img;
        data.secondTokenURI = dynamicContractDetails?.find(
          (info: any) =>
            info?.address?.toLowerCase() ==
            data?.token1Address?.toLowerCase()
        )?.img;
        data.dollarValue0 = dollarValueArray.find(
          (value: any) =>
            data?.token0Address.toLowerCase() == value?.assetAddress
        )?.price;
        data.dollarValue1 = dollarValueArray.find(
          (value: any) =>
            data?.token1Address.toLowerCase() == value?.assetAddress
        )?.price;

        // const volumeProportion = data.volume / data.totalLPAmount;
        // const tvlUSD = (Number(data.reserve1) * data.dollarValue0) + (Number(data.reserve2) * data.dollarValue1);
        // data.volumeInUSD = volumeProportion * tvlUSD;

        data.volumeInUSD = data.volume;

        // calculating my postions from user liquidity
        if (walletAddress) {
          let liquidity: string | 0 = await getLPBalance({
            pairAddress: data?.pairAddress,
            dispatch,
            walletAddress,
          });

          data.share =
            Number(totalLiquidity) != 0
              ? (Number(liquidity) * hundred) / Number(totalLiquidity)
              : "0.00";
          data.balance = 0;
        } else {
          data.share = "0.00%";
        }

      } else {
        data.liquidity = "0.00";
        data.share = "0.00";
      }
      return data;
    });
    let poolData: POOLS["data"] = await Promise.all(outputData);
    if (sortBy === 'myPoolShares') {
      poolData = poolData.sort((prev: any, next: any) => {
        return reverse
          ? next.share - prev.share
          : prev.share - next.share;
      });
    }
    return poolData;
  } else {
    return [];
  }
};


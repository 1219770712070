import { callContractSendMethod } from "../Redux/Actions/contract.action";
import { ROUTER_ADDRESS, hundred } from "../Utils";
import {
  AddLiquidity,
  AddLiquidityEth,
  commonRemoveLiquidity,
} from "../interfaces/contractCallInterfaces";
import { localeStringFunction, slicedValue } from "./common.service";
import { dynamicContractDetails } from "./dynamicContractDetails";

// const walletAddress = storeInstance.getState().user.walletAddress;

const routerAddress: string | number | undefined = dynamicContractDetails?.find(
  (data) => data?.symbol == "router"
)?.address;

/**
 * this is a helper function for adding liquidity in a pool for two custom tokens
 * @param data it is an object that contains various params required for this function
 * tokenOneAddress is the address of token One
 * tokenTwoAddress is the address of token Two
 * amountTokenADesired is the input amount of tokenA
 * amountTokenBDesired is the input amount of tokenB
 * amountTokenAMin is the minimum amount of tokenA that user will add in the pool
 * amountTokenBMin is the minimum amount of tokenB that user will add in the pool
 * walletAddress is user's wallet address
 * deadline is the epoch time after which the transaction will revert
 * @returns add liquidity transaction status
 */

const addLiquidityService = async (data: AddLiquidity) => {
  try {
    const {
      tokenOneAddress,
      tokenTwoAddress,
      amountTokenADesired,
      amountTokenBDesired,
      amountTokenAMin,
      amountTokenBMin,
      deadLine,
      walletAddress,
      dispatch,
    } = data;
    return dispatch(
      callContractSendMethod(
        "addLiquidity",
        [
          tokenOneAddress,
          tokenTwoAddress,
          amountTokenADesired?.toString(),
          amountTokenBDesired?.toString(),
          amountTokenAMin?.toString(),
          amountTokenBMin?.toString(),
          walletAddress,
          deadLine,
        ],
        walletAddress,
        "router",
        "0",
        routerAddress
      )
    );
  } catch (error) {}
};

/**
 * this is a helper function for adding liquidity in a pool for one custom token and one native token
 * @param data it is an object that contains various params required for this function
 * inputOne is the input amount of tokenA
 * inputTwo is the input amount of tokenB
 * tokenOneAddress is the address of token One
 * amountTokenMin is the minimum amount of custom token that user will add in the pool
 * amountTokenETHMin is the minimum amount of native token that user will add in the pool
 * walletAddress is user's wallet address
 * deadline is the epoch time after which the transaction will revert
 * @returns add liquidity transaction status
 */

const addLiquidityEthService = async (data: AddLiquidityEth) => {
  try {
    const {
      inputOne,
      inputTwo,
      tokenOneAddress,
      amountTokenMin,
      amountTokenETHMin,
      deadLine,
      dispatch,
      walletAddress,
    } = data;
    return dispatch(
      callContractSendMethod(
        "addLiquidityETH",
        [
          data.tokenOneAddress,
          data.inputTwo?.toString(),
          data.amountTokenMin?.toString(),
          data.amountTokenETHMin?.toString(),
          data.walletAddress,
          data.deadLine,
        ],
        data.walletAddress,
        "router",
        data.inputOne?.toString(),
        routerAddress
      )
    );
  } catch (error) {}
};

/**
 * this is a helper function for removing liquidity from a pool that consists of one custom token and one native token
 * @param data it is an object that contains various params required for this function
 * liquidity is the amount of liquidity that a user wants to remove from the pool
 * customTokenAddress is the address of custom token in the pool
 * tokenAmin is the minimum amount of tokenA that user is willing to receive
 * tokenBmin is the minimum amount of tokenB that user is willing to receive
 * approveMax is a boolean value indicating whether user is willing to give max approval of its LP token or not to the contract
 * signature is the EIP712 generated signature
 * deadline is the epoch time after which the transaction will revert
 * walletAddress is user's wallet address
 * slippageTolerance is the slippage percentage that user decided
 * @returns remove liquidity transaction status
 */

const removeLiquidityEthWithPermitService = async (
  data: commonRemoveLiquidity
) => {
  const {
    liquidity,
    customTokenAddress,
    tokenAmin,
    tokenBmin,
    approveMax,
    signature,
    deadLine,
    walletAddress,
    slippageTolerance,
    dispatch,
  } = data;
  console.log("removeLiquidityEthWithPermitService",data)
  const { v, r, s } = signature;
  try {
    const tokenAminWithSlippage: string = slicedValue(
      localeStringFunction(
        tokenAmin - (tokenAmin * slippageTolerance) / hundred
      )
    );
    const tokenBminWithSlippage: string = slicedValue(
      localeStringFunction(
        tokenBmin - (tokenBmin * slippageTolerance) / hundred
      )
    );

    return dispatch(
      callContractSendMethod(
        "removeLiquidityETHWithPermit",
        [
          customTokenAddress,
          liquidity,
          //data.customTokenAddress == "0x21a20419571bb5cb44be3f1aadcf36e8d22c8874" ? 0 : tokenAminWithSlippage,        
          0, //tokenAminWithSlippage,
          0, //tokenBminWithSlippage,
          walletAddress,
          deadLine,
          approveMax,
          v,
          r,
          s,
        ],
        walletAddress,
        "router",
        undefined,
        routerAddress
      )
    );
  } catch (error: any) {
    if (error?.code !== 4001) {
      //let taxPercentage: number = 50;
      // try {
      //   const tokenAminWithSlippage: string = slicedValue(
      //     localeStringFunction(
      //       tokenAmin - (tokenAmin * taxPercentage) / hundred
      //     )
      //   );
      //   const tokenBminWithSlippage: string = slicedValue(
      //     localeStringFunction(
      //       tokenBmin - (tokenBmin * taxPercentage) / hundred
      //     )
      //   );
      //   return dispatch(
      //     callContractSendMethod(
      //       "removeLiquidityETHWithPermitSupportingFeeOnTransferTokens",
      //       [
      //         customTokenAddress,
      //         liquidity,
      //         tokenAminWithSlippage,
      //         tokenBminWithSlippage,
      //         walletAddress,
      //         deadLine,
      //         approveMax,
      //         v,
      //         r,
      //         s,
      //       ],
      //       walletAddress,
      //       "router",
      //       undefined,
      //       routerAddress
      //     )
      //   );
      // } catch (error) {
      //   // errorHelperContract(
      //   //   error,
      //   //   "send",
      //   //   "removeLiquidityETHWithPermitSupportingFeeOnTransferTokens"
      //   // );
      // }
    }
  }
  // errorHelperContract(error, "send", "removeLiquidityEthWithPermitService");
};

/**
 * this is a helper function for removing liquidity from a pool that consists of two custom tokens
 * @param data it is an object that contains various params required for this function
 * liquidity is the amount of liquidity that a user wants to remove from the pool
 * tokenOneAddress is the address of token One in the pool
 * tokenTwoAddress is the address of token Two in the pool
 * tokenAmin is the minimum amount of tokenA that user is willing to receive
 * tokenBmin is the minimum amount of tokenB that user is willing to receive
 * approveMax is a boolean value indicating whether user is willing to give max approval of its LP token or not to the contract
 * signature is the EIP712 generated signature
 * deadline is the epoch time after which the transaction will revert
 * walletAddress is user's wallet address
 * slippageTolerance is the slippage percentage that user decided
 * @returns remove liquidity transaction status
 */

const removeLiquidityWithPermitService = async (
  data: commonRemoveLiquidity
) => {
  try {
    const {
      liquidity,
      tokenOneAddress,
      tokenTwoAddress,
      tokenAmin,
      tokenBmin,
      approveMax,
      signature,
      deadLine,
      walletAddress,
      slippageTolerance,
      dispatch,
    } = data;
    const { v, r, s } = signature;
    const tokenAminWithSlippage: string = slicedValue(
      localeStringFunction(
        tokenAmin - (tokenAmin * slippageTolerance) / hundred
      )
    );
    const tokenBminWithSlippage: string = slicedValue(
      localeStringFunction(
        tokenBmin - (tokenBmin * slippageTolerance) / hundred
      )
    );

    return dispatch(
      callContractSendMethod(
        "removeLiquidityWithPermit",
        [
          tokenOneAddress,
          tokenTwoAddress,
          liquidity,
          0, //tokenAminWithSlippage,
          0, //tokenBminWithSlippage,
          walletAddress,
          deadLine,
          approveMax,
          v,
          r,
          s,
        ],
        walletAddress,
        "router",
        undefined,
        routerAddress
      )
    );
  } catch (error) {
    // errorHelperContract(error, "send", "removeLiquidityWithPermitService");
  }
};

const removeLiquidityWithPermitServiceUSDT = async (
  data: commonRemoveLiquidity
) => {
  try {
    const {
      liquidity,
      tokenOneAddress,
      tokenTwoAddress,
      tokenAmin,
      tokenBmin,
      approveMax,
      signature,
      deadLine,
      walletAddress,
      slippageTolerance,
      dispatch,
    } = data;
    console.log(data)
    const { v, r, s } = signature;
    const tokenAminWithSlippage: string = slicedValue(
      localeStringFunction(
        tokenAmin - (tokenAmin * slippageTolerance) / hundred
      )
    );
    const tokenBminWithSlippage: string = slicedValue(
      localeStringFunction(
        tokenBmin - (tokenBmin * slippageTolerance) / hundred
      )
    );
    return dispatch(
      callContractSendMethod(
        "removeLiquidityWithPermit",
        [
          tokenTwoAddress,
          tokenOneAddress,
          liquidity,
          tokenAminWithSlippage,
          0, //Need to figure out how to represent the 6 deci usdt pair here, for now, no slippage rules
          walletAddress,
          deadLine,
          approveMax,
          v,
          r,
          s,
        ],
        walletAddress,
        "router",
        undefined,
        routerAddress
      )
    );
  } catch (error) {
    // errorHelperContract(error, "send", "removeLiquidityWithPermitService");
  }
};

export {
  addLiquidityService,
  addLiquidityEthService,
  removeLiquidityEthWithPermitService,
  removeLiquidityWithPermitService,
  removeLiquidityWithPermitServiceUSDT,
  localeStringFunction,
};

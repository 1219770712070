import Web3 from "web3";
import DynamicABI from "../Abi/DynamicABI.json";
import IcoAbi from "../Abi/Ico.ABI.json";
import FactoryABI from "../Abi/FactoryABI.json";
import RouterABI from "../Abi/RouterABI.json";
import PairABI from "../Abi/PairABI.json";
import LimitABI from "../Abi/LimitOrderABI.json";

/**ADDRESS FOR INSTANCE */
import { RPC_URL, ICO_ADDRESS } from "../Utils";
import store from "../Redux/Store";
import { connectmetamask } from "../Redux/Actions/user.action";

let web3Instance: any, icoInstance: any;
let dynamicInstance = web3Instance;

const wallets: any = ["Metamask", "walletConnect"];

export const callWeb3 = async () => {
  const { walletType, NetworkChainId }: any = store?.getState()?.user;
  const { ethereum } = window;
  switch (walletType) {
    case wallets[0]:
      web3Instance = new Web3(ethereum)
      web3Instance.transactionBlockTimeout = 500;
      web3Instance.eth.transactionBlockTimeout = 500;
      return web3Instance;
      //return (web3Instance = new Web3(ethereum));
    default:
      web3Instance = new Web3(RPC_URL)
      web3Instance.transactionBlockTimeout = 500;
      web3Instance.eth.transactionBlockTimeout = 500;
      return web3Instance;
      //return (web3Instance = new Web3(RPC_URL));
  }
};

export const createInstance = async () => {
  let web3: any = await callWeb3();

  /**CREATE CONTRACT INSTANCE WITH ABI */
  icoInstance = new web3.eth.Contract(IcoAbi, ICO_ADDRESS);
  return true;
};

createInstance();

/**SEND CONTRACT TYPE AND DYAMIC ADDRESS(OPTIONAL) FOR GET CONTRACT INSTANCE*/
const getContractInstance = async (
  contractType: string,
  dynamicAddress: string | number | undefined
) => {
  return new Promise(async (resolve, reject) => {
    switch (contractType) {
      case "ico":
        return icoInstance
          ? resolve(icoInstance)
          : createInstance()
              .then(() => {
                resolve(icoInstance);
              })
              .catch(reject);
      case "dynamic":
        dynamicInstance = web3Instance
          ? await new web3Instance.eth.Contract(
              JSON.parse(JSON.stringify(DynamicABI)),
              dynamicAddress
            )
          : await createInstance().then(async () => {
              return await new web3Instance.eth.Contract(
                JSON.parse(JSON.stringify(DynamicABI)),
                dynamicAddress
              );
            });
        resolve(dynamicInstance);
        break;
      case "factory":
        dynamicInstance = web3Instance
          ? await new web3Instance.eth.Contract(
              JSON.parse(JSON.stringify(FactoryABI)),
              dynamicAddress
            )
          : await createInstance().then(async () => {
              return await new web3Instance.eth.Contract(
                JSON.parse(JSON.stringify(FactoryABI)),
                dynamicAddress
              );
            });
        resolve(dynamicInstance);
        break;
      case "router":
        dynamicInstance = web3Instance
          ? await new web3Instance.eth.Contract(
              JSON.parse(JSON.stringify(RouterABI)),
              dynamicAddress
            )
          : await createInstance().then(async () => {
              return await new web3Instance.eth.Contract(
                JSON.parse(JSON.stringify(RouterABI)),
                dynamicAddress
              );
            });
        resolve(dynamicInstance);
        break;
      case "limit":
        dynamicInstance = web3Instance
          ? await new web3Instance.eth.Contract(
              JSON.parse(JSON.stringify(LimitABI)),
              dynamicAddress
            )
          : await createInstance().then(async () => {
              return await new web3Instance.eth.Contract(
                JSON.parse(JSON.stringify(LimitABI)),
                dynamicAddress
              );
            });
        resolve(dynamicInstance);
        break;
      case "pair":
        dynamicInstance = web3Instance
          ? await new web3Instance.eth.Contract(
              JSON.parse(JSON.stringify(PairABI)),
              dynamicAddress
            )
          : await createInstance().then(async () => {
              return await new web3Instance.eth.Contract(
                JSON.parse(JSON.stringify(PairABI)),
                dynamicAddress
              );
            });
        resolve(dynamicInstance);
        break;
      default:
        return null;
    }
  });
};

/**CALL CONTRACT GET METHODS. ALL PARAMS WILL BE DYNAMIC */
export const callGetMethod = async (
  method: string,
  data: any,
  contractType: string,
  dynamicAddress: string | number | undefined
) => {
  return new Promise(async (resolve, reject) => {
    try {
      /**GET SELECTED CONTRACT INSTANCE */
      let contract: any = await getContractInstance(
        contractType,
        dynamicAddress
      );
      if (contract.methods) {
        /**CALL GET METHOD */
        contract.methods[method]
          .apply(null, Array.prototype.slice.call(data))
          .call()
          .then((result: object) => {
            resolve(result);
          })
          .catch((error: Error) => {
            reject(error);
          });
      } else {
        reject(new Error("Contract not found."));
      }
    } catch (error) {
      console.log("callGetMethod: ", error);
      reject(error);
    }
  });
};

/**CALL CONTRACT SEND METHODS. ALL PARAMS WILL BE DYNAMIC */
export const callSendMethod = async (
  method: string,
  data: any,
  walletAddress: string,
  contractType: string,
  value: any,
  dynamicAddress: string | number | undefined
) => {
  return new Promise(async (resolve, reject) => {
    try {
      /**CHECK WALLET IS CONNECTED */
      if (walletAddress === "") {
        reject(new Error("Please connect wallet"));
      }

      /**CREATE DATA FOR CALL SEND METHOD */
      let dataForSend: any = { from: walletAddress };

      /**CHECK IF WE NEED TO SEND VALUE IN SEND METHOD */
      if (value) {
        dataForSend.value = value;
      }

      /**GET SELECTED CONTRACT INSTANCE */
      let contract: any = await getContractInstance(
        contractType,
        dynamicAddress
      );

      if (contract.methods) {
        // /**ESTIMATE GAS FOR TRANSACTION */
        // const gasLimit = await contract.methods[method]
        //   .apply(null, Array.prototype.slice.call(data))
        //   .estimateGas(dataForSend);
        // dataForSend.gasLimit = Number((gasLimit * 1.2)?.toFixed());
        /**ESTIMATE GAS FOR TRANSACTION */
        const gas = await contract.methods[method]
          .apply(null, Array.prototype.slice.call(data))
          .estimateGas(dataForSend);

        const gasLimit = BigInt(gas) * BigInt(120) / BigInt(100);
        dataForSend.gasLimit = gasLimit.toString();

        /**CALL SEND METHOD */
        contract.methods[method]
          .apply(null, Array.prototype.slice.call(data))
          .send(dataForSend)
          .then((result: object) => {
            resolve(result);
            return result;
          })
          .catch((error: Error) => {
            reject(error);
          });
      } else {
        reject(new Error("Contract not found."));
      }
    } catch (error) {
      console.log("callSendMethod: ", error);
      reject(error);
    }
  });
};

export const checkUserConnectivity = async (dispatch: any) => {
  const { ethereum } = window;
  if (ethereum && !ethereum?.eth_accounts) {
  //if (ethereum && !ethereum?.selectedAddress) { --> selectedAddress is deprecated
    const result = await dispatch(connectmetamask());
    return result;
  } else if (ethereum && ethereum?.eth_accounts) {
  // } else if (ethereum && ethereum?.selectedAddress) {
    return true;
  } else {
    return false;
  }
};

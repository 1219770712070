import React from "react";
import { useTranslation } from "react-i18next";

function ErrorFallback({ error, errorMessage }) {
  const { t } = useTranslation();
  return (
    <div className="error_page">
      <div className="error_page_icon">
      </div>
      <h2 className="mt-2">{t('errorPageLine1')}</h2>
      <h3>{t('errorPageLine2')}</h3>
    </div>
  );
}

export default ErrorFallback;

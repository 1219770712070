import React, { useState } from "react";
import {
  DescriptionIcon,
  RefreshIcon,
  SwapIcon,
  SwapIconVertical,
  WalletIcon,
} from "../../../../Assets/Images/Icons/SvgIcons";
import TokenSelect from "../../../Common/FormInputs/TokenSelect/TokenSelect";
import ButtonCustom from "../../../Common/Button/ButtonCustom";
import "./AssetStakingToken.scss";
import GetWallet from "../../../Common/Modals/GetWallet/GetWallet";
import { useTranslation } from "react-i18next";

const AssetStakingToken = () => {
  const [show, setShow] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <div className="AssetStakingToken">
      <div className="AssetStakingCard">
        <div className="AssetCardHeader">
          <h3>ETH {t('assetStakingTokenStaking')}</h3>
          <div>
            <button>
              <RefreshIcon />
            </button>
            <button onClick={() => setShow(true)}>
              <DescriptionIcon />
            </button>
          </div>
        </div>
        <div className="AssetCardBody">
          <label className="availtext">{(t('assetAvailableBalance')).replace('{{balance}}', "0.00")}</label>
          <div>
            <div className="inputValueBox">
              <input className="amountInput" placeholder="0.0" type="text" />
              <TokenSelect />
            </div>
            <button className="swapBtn">
              <SwapIcon />
            </button>
            <div className="inputValueBox">
              <input className="amountInput" placeholder="0.0" type="text" />
              <TokenSelect />
            </div>
          </div>
          <p className="valueText">
            <span>1</span>
            <span>LIBFI</span>
            <span>=</span>
            <span>=</span>
            <span>0.03084</span>
            <span>ETH</span>
            <SwapIconVertical />
          </p>
          <div className="feeText">
            <label>{t('assetFee')}</label>
            <p>0 WSTETH</p>
          </div>
          <ButtonCustom
            // onClick={() => setShow(true)}
            className="walletBtn"
            fluid
            title={
              <>
                <WalletIcon /> {t('connectWallet')}
              </>
            }
          />
        </div>
        <GetWallet show={show} handleClose={() => setShow(false)} />
      </div>
    </div>
  );
};

export default AssetStakingToken;

import React, { useState } from "react";
import "./EarnAssetStaking.scss";
import { Outlet, useNavigate } from "react-router-dom";
import {
  LeftArrow,
  WalletIcon,
} from "../../../../Assets/Images/Icons/SvgIcons";
import ButtonCustom from "../../../Common/Button/ButtonCustom";
import TransactionDone from "../../../Common/Modals/TransactionDone/TransactionDone";
import { useTranslation } from "react-i18next";

const EarnAssetStaking = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);
  return (
    <section className="EarnAssetStaking">
      <div className="EarnAssetStakingHead">
        <h3>
          <button onClick={() => navigate(-1)}>
            <LeftArrow />
          </button>{" "}
          {t('amanaVaults')}
        </h3>
        <ButtonCustom
          onClick={() => setShow(true)}
          title={t('earnMyInvestments')}
          className="bordered-blue"
        />
      </div>
      {false ? (
        <div className="wallet_not_connected">
          <p>
            {t('earnAssetDescription')}
          </p>
          <ButtonCustom
            title={
              <>
                <span className="me-2">
                  <WalletIcon />
                </span>
                {t('connectWallet')}
              </>
            }
          />
        </div>
      ) : (
        <>
          <Outlet />
        </>
      )}
      <TransactionDone show={show} handleClose={() => setShow(false)} />
    </section>
  );
};

export default EarnAssetStaking;

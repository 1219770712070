import React, { useEffect, useState } from "react";
import CommonCard from "../CommonCard/CommonCard";
import CustomTable from "../../Table/Table";
import {
  CalendarIcon,
  DownRedArrow,
  DownloadIcon,
  TrandingIcon,
} from "../../../../Assets/Images/Icons/SvgIcons";
import "./TradehistoryCard.scss";
import ButtonCustom from "../../Button/ButtonCustom";
import { useDispatch, useSelector } from "react-redux";
import { callApiPostMethod } from "../../../../Redux/Actions/api.action";
import { Dispatch } from "redux";
import DatePicker from "react-date-picker";
import {
  cryptoDecimals,
  localeStringFunction,
} from "../../../../Services/common.service";
import { tokenCollection } from "../../../../Services/dynamicContractDetails";
import moment from "moment";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const TradehistoryCard = () => {
  const dispatch: any = useDispatch();
  const { t } = useTranslation();
  const userwalletAddress: string = useSelector(
    (state: any) => state?.user?.walletAddress
  );
  const [userTradeHistory, setUserTradeHistory] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>(new Date("2023/06/27"));
  const [endDate, setEndDate] = useState<Date>(new Date("2023/06/28"));

  useEffect(() => {
    if (userwalletAddress) getUserTradeHistory();
  }, []);

  /**
   * function to get recent trade of the user
   */
  const getUserTradeHistory = async () => {
    setLoader(true);
    let startDateEpoch: number = moment(startDate, "M/D/YYYY").unix();
    let endDateEpoch: number = moment(endDate, "M/D/YYYY").unix();

    const startDateUser = moment.unix(startDateEpoch);
    const endDateUser = moment.unix(endDateEpoch);

    const result: any = await dispatch(
      callApiPostMethod(
        "TRADE_HISTORY",
        { userAddress: userwalletAddress },
        false,
        false
      )
    );
    if (result?.status == 200) {
      const userUpdatedAssets = await JSON.parse(
        JSON.stringify(result?.data)
      ).map((item: any) => {
        const tokenInfo: any = tokenCollection.find(
          (info) => info?.symbol == item?.assetName
        );
        item.amount = localeStringFunction(
          item?.amount / 10 ** tokenInfo?.decimals
        );
        // item.openPrice = item?.openPrice;
        item.convertedTimestamp = moment(item?.timestamp * 1000).format(
          "DD/MM/YYYY hh:mm"
        );
        result?.price.map((data: any) => {
          if (item?.assetAddress == data?.tokenAddress) {
            item.price = data?.marketPrice;
          }
        });
        result?.profitloss.map((data: any) => {
          if (item?.assetAddress == data?.tokenAddress) {
            item.changePercent = data?.change;
            item.changeAmount = data?.changeAmount;
          }
        });
        return item;
      });
      setUserTradeHistory(userUpdatedAssets);
      setLoader(false);
    }
  };
  /**
   * function to update states of start and end date according to user selection
   * @param data date that user has selected
   * @param field whether user has selected end date or start date
   */
  const handleDate = (data: any, field: number) => {
    if (field == 1) {
      setStartDate(data);
    } else {
      setEndDate(data);
    }
  };
  return (
    <CommonCard
      className="TradeHistoryCard"
      cardTitle={t('homeMyTradeHistory')}
      viewAllNavigate="/auth/dashboard/trade-history"
      walletNotConnected={!userwalletAddress ? false : true}
    >
      <div className="TradeHistoryCardHeader">
        <span className="TradeHistoryCardHeaderDate">
          {/* <CalendarIcon /> */}
          {t('tradeHistoryStartDate')}
          <DatePicker
            onChange={(date: any) => handleDate(date, 1)}
            value={startDate}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            minDate={new Date("2023/06/27")}
          />
        </span>
        <span className="TradeHistoryCardHeaderDate">
          {t('tradeHistoryEndDate')}
          {/* <CalendarIcon /> */}
          <DatePicker
            onChange={(date: any) => handleDate(date, 2)}
            value={endDate}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            minDate={startDate}
          />
        </span>
        <ButtonCustom
          title={
            <>
              <DownloadIcon /> Download CSV
            </>
          }
          className="bordered-blue"
        />
      </div>
      <CustomTable
        fields={[
          {
            key: "asset",
            label: t('tradeHistoryAsset')
          },
          {
            key: "openPrice",
            label: t('tradeHistoryOpenPrice')
          },
          {
            key: "marketPrice",
            label: t('tradeHistoryMarketPrice')
          },
          {
            key: "size",
            label: t('tradeHistorySize')
          },
          {
            key: "profitLoss",
            label: t('tradeHistoryProfitLoss')
          },
          {
            key: "feesPaid",
            label: t('tradeHistoryFeesPaid')
          },
          {
            key: "timestamp",
            label: t('tradeHistoryTimestamp')
          },
        ]}
        loader={loader}
      >
        {userTradeHistory.map((item) => (
          <tr key={item}>
            <td>{item?.assetName}</td>
            <td>
              <span>$</span>
              <span>{cryptoDecimals(item?.openPrice)}</span>
            </td>
            <td>{cryptoDecimals(item?.price)}</td>
            <td>{cryptoDecimals(Math.abs(item?.amount))}</td>
            <td className="value_text">
              <h3>
                <span>$</span>
                <span>{cryptoDecimals(Math.abs(item?.changeAmount || 0))}</span>
              </h3>
              <p>
                <span>
                  {item?.changePercent > 0 ? (
                    <TrandingIcon />
                  ) : (
                    <DownRedArrow />
                  )}
                </span>
                <span className={item?.changePercent > 0 ? "" : "danger"}>
                  {cryptoDecimals(item?.changePercent || 0)}%
                </span>
              </p>
            </td>
            <td>{"-"}</td>
            <td>{item?.convertedTimestamp}</td>
          </tr>
        ))}
      </CustomTable>
    </CommonCard>
  );
};

export default TradehistoryCard;

// import toaster from "../../Components/Common/Toast";
import { connectmetamask } from "./user.action";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { callGetMethod, callSendMethod } from "../../Services/contract.service";
import { getError } from "../../Services/common.service";
import { loader } from "../Slices/loader.slice";
import Web3 from "web3";

/**CALL COONTRACT'S GET METHODS */
export const callContractGetMethod = (
  method: string,
  data: any = [],
  contractType: string,
  loading = true,
  dynamicAddress: string | number | undefined
) => {
  return async (dispatch: Dispatch<any> = useDispatch()) => {
    try {
      /**SHOW LOADING */
      // if (loading) dispatch(loader(true));
      /**CALL GET METHOD */
      const result = await callGetMethod(
        method,
        data,
        contractType,
        dynamicAddress
      );
      if (loading) dispatch(loader(false));
      return result;
    } catch (error) {
      if (loading) dispatch(loader(false));
      // return showError ? toaster.error(getError(error)) : null;
    }
  };
};

/**CALL COONTRACT'S SEND METHODS */
export function callContractSendMethod(
  method: string,
  data: any = [],
  walletAddress: string,
  contractType: string,
  value: string | number | undefined,
  dynamicAddress: string | number | undefined
) {
  return async (dispatch: Dispatch<any> = useDispatch(), getState: any) => {
    try {
      // let wallet = getState().user.wallet;
      // let verifyAccount: any = false;

      /**VALIDATE WALLET */
      // if (wallet === "MetaMask") {
      //   verifyAccount = await dispatch(connectmetamask());
      // }
      // if (wallet !== "MetaMask" || (wallet === "MetaMask" && verifyAccount)) {
      /**SHOW LOADING */
      // dispatch(loader(true));

      /**CREATE INSTANCE WITH WALLET */

      /**CALL SEND METHOD */
      const web3 = new Web3(window.ethereum);
      //web3.transactionBlockTimeout = 500;
      //web3.eth.transactionBlockTimeout = 500;

      //for (let key in web3) {
      //  console.log(key + ": ", web3[key]);
      //}
      
      const result = await callSendMethod(
        method,
        data,
        walletAddress,
        contractType,
        value,
        dynamicAddress
      );
      interface TransactionResult {
        transactionHash: string;
      }
      
      const txHash = (result as TransactionResult).transactionHash;
      console.log("Tx Hash:", txHash);
      
      web3.eth.getTransactionReceipt(txHash).then(receipt => {
        if (receipt && receipt.status) {
          console.log("Tx confirmed", receipt);
          dispatch(loader(false));
        }
      }).catch(error => {
        console.error("Tx failed", error);
        dispatch(loader(false));
      });

      return result;
    } catch (error) {
      console.log("Error in MetaMask call", error);
      dispatch(loader(false));
    }
  };
}

import React, { useEffect, useState, useRef } from "react";
import "./TopPools.scss";
import CommonCard from "../CommonCard/CommonCard";
import CustomTable from "../../Table/Table";
import { redirect } from "react-router-dom";
import { RightArrow } from "../../../../Assets/Images/Icons/SvgIcons";
import { useTranslation } from "react-i18next";
import { cryptoDecimals } from "../../../../Services/common.service";

const TopPools = ({pools}) => {
  const { t } = useTranslation();
  // Check The Table and Add The Vertical Line Accordingly
  const [isLastColumnVisible, setIsLastColumnVisible] = useState(true);

  const tableRef = React.useCallback(node => {
    if (node !== null) {
      const lastColumn = node.querySelector('th:last-child');
      const handleResize = () => {
        if (lastColumn) {
          const rect = lastColumn.getBoundingClientRect();
          setIsLastColumnVisible(rect.right <= window.innerWidth);
          //console.log("Last Column Width:", rect.width, "Window Width:", window.innerWidth, "Is Visible:", rect.right <= window.innerWidth);
        }
      };
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  const handlePoolNavigate = async (data) => {
    if (data?.link) window.location.href = data?.link;
  };

  useEffect(() => {
    if (!isLastColumnVisible) {
      document.documentElement.style.setProperty('--VerticalLine', 'linear-gradient(90deg, rgba(242, 242, 242, 0) 0%, rgb(18, 18, 18) 100%)');
    } else {
      document.documentElement.style.setProperty('--VerticalLine', 'none');
    }
  }, [isLastColumnVisible]);

  return (
    <CommonCard
      noHeaderSpacing
      cardTitle={t('homeTopLiquidityPools')}
      className="TopPools"
      walletNotConnected={true}
    >
      <CustomTable fields={[
        {
          key: "",
          label: ""
        },
        {
          key: "pool",
          label: t('homeTopLiquidityPoolsPool')
        },
        {
          key: "type",
          label: t('homeTopLiquidityPoolsType')
        },
        {
          key: "apy",
          label: t('homeTopLiquidityPoolsAPY')
        },
        {
          key: "volume",
          label: t('homeTopLiquidityPools24hVol')
        },
        {
          key: "",
          label: ""
        },
        {
          key: "",
          label: ""
        },
      ]} scrollingBar={true} tableRef={tableRef} >
        {pools?.map((item, index) => (
          <tr key={index}>
            <td></td>
            <td>
              <div className="poolName">
                <img src={item?.image} alt="" />
                <div>
                  <h4>{item?.name}</h4>
                  <p>{item?.description}</p>
                </div>
              </div>
            </td>
            <td>{item?.type}</td>
            <td>
              <span>{item?.apy}</span>
              <span>%</span>
            </td>
            <td>
              <span>$</span>
              <span>{cryptoDecimals(item?.volume)}</span>
            </td>
            <td className="text-end">
              <button className="arrow_btn" onClick={() => handlePoolNavigate(item)}>
                <RightArrow/>
              </button>
            </td>
            <td></td>
          </tr>
        ))}
      </CustomTable>
    </CommonCard>
  );
};

export default TopPools;

import React, { useEffect, useState } from "react";
import moment from 'moment';
import 'moment/min/locales';
import "./NewsCard.scss";
import { Dispatch } from "redux";
import CommonCard from "../CommonCard/CommonCard";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { callApiGetMethod } from "../../../../Redux/Actions/api.action";
import Shimmer from "../../Shimmer/Shimmer";
import { NEWS } from "../../../../Redux/Actions/apiResponseInterfaces";
import { useTranslation } from "react-i18next";

const NewsCard = () => {
  const dispatch: any = useDispatch();
  const { t, i18n } = useTranslation();
  const [news, setNews] = useState<NEWS>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getLatestNews();
  }, []);

  moment.locale(i18n.language);

  /**
   * function to fetch news
   */
  const getLatestNews = async () => {
    setLoading(true);
    try {
      const res: NEWS = await dispatch(
        callApiGetMethod("GET_NEWS", {}, false, false)
      );
      setNews(res);
    } catch (error) {
      console.error("Error fetching news:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CommonCard
      cardTitle={t('newsTitle')}
      className="NewsCard"
      viewAllNavigate={"/auth/dashboard/libfi"}
      walletNotConnected={true}
      scrollingBar={true}
    >
      <ul className={loading ? "bg-transparent" : ""}>
        {loading ? (
          <Shimmer fluid height={272} />
        ) : (
          <>
            {news?.length === 0 ? (
              <p className="text-center p-4">{t('newsDataNotFound')}</p>
            ) : (
              news?.map((item: any) => (
                <li key={item?.title}>
                  <Link
                    to={item?.url}
                    target="_blank"
                    className={`${item?.active ? "active" : ""}`}
                  >
                    {/* <img src={item.image} alt="" /> */}
                    <div>
                      <strong>{item?.title}</strong>
                      <span>
                        {moment(item?.date).format("D MMMM YYYY, h:mm:ss a")}
                      </span>
                    </div>
                  </Link>
                </li>
              ))
            )}
          </>
        )}
      </ul>
    </CommonCard>
  );
};

export default NewsCard;

import React from "react";
import "./DetailsCard.scss";
import { DetailsCardIcon } from "../../../../Assets/Images/Icons/SvgIcons";
import tokenABi from "../../../../Abi/tokenabi.json";
import { tokenCollection } from "../../../../Services/dynamicContractDetails";
import { settokenOne, settokenTwo } from "../../../../Redux/Slices/tokenSlice";
import { useDispatch } from "react-redux";
import { updatePairChange } from "../../../../Redux/Slices/user.slice";
import { TOKENS } from "../../../../interfaces/commonInterfaces";

type propTypes = {
  className?: string;
  type?: "blue" | "yellow" | "darkBlue";
  value?: string;
  heading?: string;
  loader?: boolean
};

const DetailsCard = (props: propTypes) => {
  const { className, value, heading, type, loader } = props;
  const dispatch = useDispatch();

  /**
   * function to update token selected whenever user clicks on the tiles at the trading desk page
   */
  const updateTokens = async () => {
    const tokenOne: TOKENS = {
      name: value?.split("/")[0],
      symbol: value?.split("/")[0],
      address: tokenCollection?.find((a) => a?.symbol == value?.split("/")[0])
        ?.address,
      abi: tokenABi,
      img: tokenCollection?.find((a) => a?.symbol == value?.split("/")[0])?.img,
      isSelected: true,
      isNative: false,
      id: tokenCollection?.find((a) => a?.symbol == value?.split("/")[0])
        ?.address,
      decimals: tokenCollection?.find((a) => a?.symbol == value?.split("/")[0])
        ?.decimals,
    };
    const tokenTwo: TOKENS = {
      name: value?.split("/")[1],
      symbol: value?.split("/")[1],
      address: tokenCollection?.find((a) => a?.symbol == value?.split("/")[1])
        ?.address,
      abi: tokenABi,
      img: tokenCollection?.find((a) => a?.symbol == value?.split("/")[1])?.img,
      isSelected: true,
      isNative: true,
      id: tokenCollection?.find((a) => a?.symbol == value?.split("/")[1])
        ?.address,
      decimals: tokenCollection?.find((a) => a?.symbol == value?.split("/")[1])
        ?.decimals,
    };

    dispatch(settokenOne(tokenOne));
    dispatch(settokenTwo(tokenTwo));
    dispatch(updatePairChange(`${tokenOne?.address}+${tokenTwo?.address}`));
  };

  return (
    <div
      className={`DetailsCard ${type || "blue"} ${className || ""}`}
      onClick={() => updateTokens()}
    >
      <span className="DetailsCardIcon">
        <DetailsCardIcon />
      </span>
      <div>
        {<h3 className={loader ? 'text-shadow': ''}>{value}</h3>}
        {heading && <p>{heading}</p>}
      </div>
    </div>
  );
};

export default DetailsCard;

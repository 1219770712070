import React from "react";
import "./QuickLinks.scss";
import CommonCard from "../CommonCard/CommonCard";
import {
  EarnAndInvestIcon,
  GovernanceIcon,
  LearningCentreIcon,
  RedirectIcon,
  TradingIcon,
} from "../../../../Assets/Images/Icons/SvgIcons";
// import { Link } from 'react-router-dom';
import ButtonCustom from "../../Button/ButtonCustom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const QuickLinks = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const data = [
    {
      icon: <TradingIcon />,
      title: t('homeTradingDesk'),
      to: "/auth/trade/simple/market"
    },
    {
      icon: <EarnAndInvestIcon />,
      title: t('homeEarnInvest'),
      to: "/auth/earn/overview"
    },
    {
      icon: <LearningCentreIcon />,
      title: t('homeLearningCenter'),
      to: "",
    },
    {
      icon: <GovernanceIcon />,
      title: t('homeGovernance'),
      to: "",
    },
  ];
  return (
    <CommonCard
      cardTitle={t('homeQuickLinks')}
      className="QuickLinks"
      walletNotConnected={true}
    >
      <ul>
        {data?.map((item) => (
          <li
            key={item.title}
            title={
              item?.title != t('homeEarnInvest') && item?.title != t('homeTradingDesk')
                ? t('comingSoon')
                : item?.title
            }
          >
            <ButtonCustom
              fluid
              title={
                <>
                  <span>{item.icon}</span>
                  {item.title}
                  <span className="redirectIcon">
                    <RedirectIcon/>
                  </span>
                </>
              }
              // navigate={item.to}
              onClick={() => {
                navigate(item.to);
              }}
              className="bordered-blue QuickLink"
            />
          </li>
        ))}
      </ul>
    </CommonCard>
  );
};

export default QuickLinks;

import { Provider } from "react-redux";
import store from "./Redux/Store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import Application from "./Application";
import { Toaster } from "react-hot-toast";
import Loader from "./Components/Common/Loader/Loader";
import { io } from "socket.io-client";
import ReactDOM from "react-dom";
import { Suspense } from "react";

/**CREATE STORE PERSIST INSTANCE */
let persistor = persistStore(store);

// export const socket: any = io("Your Socket URL comes here", {
//   reconnection: true,
//   transports: ["websocket"],
// });

function App() {
  // console.log = () => {};
  console.warn = () => {};
  console.error = () => {};

  console.log("build number", 6);
  window?.addEventListener("navigationhandler", function (e) {
    window?.history?.pushState(null, document?.title, window?.location?.href);
    window?.addEventListener("popstate", function (event) {
      window?.history?.pushState(null, document?.title, window?.location?.href);
    });
  });
  return (
    <Provider store={store}>
      <Suspense fallback={<Loader />}>
        <PersistGate loading={null} persistor={persistor}>
          {<>{ReactDOM?.createPortal(<Toaster />, document?.body)}</>}
          <Loader />
          <Application />
        </PersistGate>
      </Suspense>
    </Provider>
  );
}

export default App;

import React from "react";
import SubHeader from "../../../Common/SubHeader/SubHeader";
import { Outlet } from "react-router-dom";
import "./EarnPage.scss";
import { useTranslation } from "react-i18next";

const EarnPage = () => {
  const { t } = useTranslation();
  const navigationLinks = [
    {
      id: 1,
      to: "/auth/earn/overview",
      label: `${t('earnOverview')}`,
    },
    {
      id: 2,
      to: "/auth/earn/my-investments",
      label: `${t('earnMyInvestments')}`,
    },
  ];
  return (
    <section className="EarnPage">
      <SubHeader backBtn heading={t('leftNavEarn')} navigationLinks={navigationLinks} />
      <Outlet />
    </section>
  );
};

export default EarnPage;

import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

/**AUTHGAURD FOR INNER PAGES */
export const RequireAuth = (props: any) => {
  const walletAddress: string = useSelector(
    (state: any) => state?.user?.walletAddress
  );
  const setWallet: any = useSelector((state: any) => state.user.setWallet);
  return walletAddress || setWallet ? props.children : props.children;
};

import React, { Dispatch, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./SettingModal.scss";
import { CloseIcon } from "../../../../Assets/Images/Icons/SvgIcons";
import ButtonCustom from "../../Button/ButtonCustom";
import Input from "../../FormInputs/Input/Input";
import Tooltip from "../../Tooltip/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import {
  saveSlippage,
  saveTransactionDeadline,
} from "../../../../Redux/Slices/user.slice";
import toast from "react-hot-toast";
import { number } from "yup";
import {
  createUserProgress,
  handleUserProgress,
} from "../../../../Redux/Actions/user.action";
import useDebounce from "../../../../hooks/useDebounce";
import { GET_USER } from "../../../../Redux/Actions/apiResponseInterfaces";
import { useTranslation } from "react-i18next";

type Settings = {
  show: boolean;
  handleClose: () => void;
};
const SettingModal = (props: Settings) => {
  const slippagePercentage = [
    {
      value: 0.1,
      label: document.body.dir === "rtl" ? "%0.1": "0.1%",
    },
    {
      value: 0.5,
      label: document.body.dir === "rtl" ? "%0.5": "0.5%",
    },
    {
      value: 1,
      label: document.body.dir === "rtl" ? "%1": "1%",
    },
  ];

  const dispatch: any = useDispatch();
  const { t } = useTranslation();
  const {
    TransactionDeadline,
    slippage,
  }: { TransactionDeadline: number; slippage: number } = useSelector(
    (state: any) => state?.user
  );

  const userwalletAddress: string = useSelector(
    (state: any) => state?.user?.walletAddress
  );
  
  const [transactionDeadline, setTransactionDeadline] = useState<any>(TransactionDeadline);
  const [slippage1, setSlippage] = useState<any>(slippage);

  const [userSlippage, setuserSlippage] = useState(null);
  const [userTansactionDeadline, setUserTansactionDeadline] = useState(null);

  useEffect(() => {
    if (userwalletAddress) getPersonalisedSettings();
  }, [userwalletAddress]);

  const getPersonalisedSettings = async () => {
    if (userwalletAddress) {
      const info = {
        walletAddress: userwalletAddress,
        dispatch,
        from: "Settings",
      };
      const res: GET_USER | undefined = await handleUserProgress(info);
      if (res) {
        dispatch(saveSlippage(!res?.data?.slippage ? 1 : res?.data?.slippage));
        dispatch(
          saveTransactionDeadline(
            res?.data?.deadlineInMin ? res?.data?.deadlineInMin : 10
          )
        );
        setSlippage(!res?.data?.slippage ? 1 : res?.data?.slippage);
        setTransactionDeadline(
          res?.data?.deadlineInMin ? res?.data?.deadlineInMin : 10
        );
        setuserSlippage(slippage1)
        setUserTansactionDeadline(transactionDeadline)
      }
    }
  };

  const setPersonalisedSettings = async () => {
    if (userwalletAddress && slippage1 != "" && transactionDeadline != "") {
      const info = {
        address: userwalletAddress,
        slippage: slippage1.toString(),
        // deadlineInSec: deadlineInEpoch,
        deadlineInMin: transactionDeadline,
        dispatch,
      };

      const res = await createUserProgress(info);
    }
  };

  /**
   * Handling of slippage
   * @param e
   */

  const [errorSlippage, setErrorSlippage] = useState(false);
  const [errorMessageSlippage, setErrorMessageSlippage] = useState('');

  const handleSlippage = (e: any) => {
    setSlippage(e);

    if (Number(e) > 100) {
      setErrorMessageSlippage((t('tradeSettingsSlippageExceedError')).replace("{{percentage}}", "100%"));
      setErrorSlippage(true);
    } else if (e.trim() == '') {
      setErrorMessageSlippage(t('tradeSettingsSlippageMandatoryError'))
      setErrorSlippage(false);
    } else {
      if (userwalletAddress) dispatch(saveSlippage(e));
      dispatch(saveSlippage(e));
      setErrorMessageSlippage("");
      setErrorSlippage(false);
    }
  };

  const handleSlippageSelect = async (key) => {
    if (key) {
      dispatch(saveSlippage(key));
      setSlippage(key);
      setErrorMessageSlippage("")
      setErrorSlippage(false);
    }
  };

  /**
   * Handling of deadline duration slippage
   * @param e
   */

  const [errorDeadline, setErrorDeadline] = useState(false);
  const [errorMessageDeadline, setErrorMessageDeadline] = useState('');

  const handleSlippageTime = (e: any) => {
    const value: any = e.target.value;
    setTransactionDeadline(value);

    if (Number(value) > 100) {
      setErrorMessageDeadline((t('tradeSettingsTXDeadlineExceedError')).replace("{{minutes}}", "100"));
      setErrorDeadline(true);
    } else if (Number(value) < 3 && value.trim() != "") {
      setErrorMessageDeadline((t('tradeSettingsTXDeadlineMinMintuesError')).replace("{{minutes}}", "3"));
      setErrorDeadline(true);
    } else if (value.trim() == "") {
      setErrorMessageDeadline(t('tradeSettingsTXDeadlineMandatoryError'));
      setErrorDeadline(false);
    } else {
      dispatch(saveTransactionDeadline(value));
      setErrorMessageDeadline("");
      setErrorDeadline(false);
    }
  };

  return (
    <Modal  
      keyboard={false}
      backdrop="static"
      show={props?.show}
      centered
      className="setting_modal"
      onHide={props?.handleClose}
    >
      <Modal.Header>
        <Modal.Title>{t('tradeSettingsTitle')}</Modal.Title>
        <button
          className="modal_close_btn"
          onClick={() => {
            if (errorMessageSlippage.trim() != '' || errorMessageDeadline.trim() != '') {
              setErrorSlippage(true);
              setErrorDeadline(true);
              toast.error(
                <div>
                  <div style={{ textAlign: "center" }}>{t('tradeSettingsSaveErrorLine1')}</div>
                  <div style={{ textAlign: "center" }}>{t('tradeSettingsSaveErrorLine2')}</div>
                </div>,
                { id: "WrongInput" }
              );
            } else {
              if (userSlippage != slippage1 || userTansactionDeadline != transactionDeadline) {
                toast.success(t('tradeSettingsSaveSuccess'), {id: "WrongInput"})
              }
              setPersonalisedSettings();
              props?.handleClose();
            }
          }}
        >
          <CloseIcon />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="modal_text">
          <h3>{t('tradeSettingsSlippage')}</h3>
          <Tooltip
            heading={t('tradeSettingsSlippage')}
            content={t('tradeSettingsSlippageTooltip')}
          />
        </div>
        <div className="setting_modal_btn">
          <div style={{ marginBottom: errorSlippage ? '0px' : '' }}>
            <Input
              //placeholder="0.0"
              type="number"
              className="amountInput" 
              name="fname"
              value={slippage1}
              max={100}
              onChange={(e) => handleSlippage(e.target.value)}
            />
          </div>
          {errorSlippage && <p className="error_msg">{errorMessageSlippage}</p>}
          <div className="input_btn">
            {slippagePercentage?.map((item) => (
              <ButtonCustom
                title={item?.label}
                className={item?.value != slippage1 ? "bordered-blue" : "setting-panel-slippage-matched"}
                onClick={() => handleSlippageSelect(item?.value)}
              />
            ))}
          </div>

        </div>
        <div className="deadline_input">
          <div className="modal_text">
            <h3>{t('tradeSettingsTXDeadline')}</h3>
            <Tooltip
              heading={t('tradeSettingsTXDeadline')}
              content={t('tradeSettingsTXDeadlineTooltip')}
            />
          </div>
          <div className="deadline_input_inner">
            <Input
              //placeholder="DeadLine Limit"
              type="number"
              className="amountInput"
              name="fname"
              value={transactionDeadline}
              onChange={handleSlippageTime}
            />
            <label>{t('tradeSettingsMinutes')}</label>
          </div>
          {errorDeadline && <p className="error_msg">{errorMessageDeadline}</p>}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SettingModal;

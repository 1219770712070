import React from "react";
import CommonCard from "../CommonCard/CommonCard";
import graph from "../../../../Assets/Images/graph-images/revenue-graph.png";
import "./RevenueCard.scss";
import ComingSoon from "../../ComingSoon/ComingSoon";
import { useTranslation } from "react-i18next";
const RevenueCard = () => {
  const { t } = useTranslation();
  return (
    <CommonCard walletNotConnected cardTitle={t('portfolioRevenue')} className="RevenueCard">
      <img src={graph} alt="graph" />
      <ComingSoon />
    </CommonCard>
  );
};

export default RevenueCard;

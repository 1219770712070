import React from "react";
import { Modal, ProgressBar } from "react-bootstrap";
import {
  CloseIcon,
  TransactionDoneIcon,
} from "../../../../Assets/Images/Icons/SvgIcons";
import "./TransactionDone.scss";
import { EXPLORER_LINK } from "../../../../Utils";
import ButtonCustom from "../../Button/ButtonCustom";
import { useTranslation } from "react-i18next";

const TransactionDone = ({
  show,
  handleClose,
  handleFunction,
}: {
  show: any;
  handleClose: () => void;
  handleFunction?: any;
  }) => {
  const { t } = useTranslation();
  return (
    <Modal
      centered
      className="transModal"
      heading={"Really"}
      show={show.show}
      onHide={handleClose}
    >
      <div>
        <button className="modal_close_btn" onClick={handleClose}>
          <CloseIcon />
        </button>
        <>
          <Modal.Body>
            <TransactionDoneIcon />
            <h3>
              {show?.item == "Empty"
                ? (t('transactionCancelOrderError')).replace('{{status}}', show?.status || "")
                : show?.item != ""
                ? t('transactionCancelOrderConfirmation')
                : ""}
            </h3>
            {/* <span>7 of 7 Completed</span> */}
            {/* <p>
              Validators confirmation <br />
            </p> */}
            <div className="d-flex mt-4 align-items-center">
              {show?.item == "Empty" ? (
                <ButtonCustom
                  fluid
                  className="me-2"
                  onClick={(e) => {
                    handleClose();
                  }}
                >
                  {t('transactionDoneOk')}
                </ButtonCustom>
              ) : show?.item != "" ? (
                <>
                  <ButtonCustom
                    fluid
                    className="me-2"
                    onClick={() => {
                      handleFunction(show?.item);
                    }}
                  >
                    {t('transactionDoneConfirmYes')}
                  </ButtonCustom>
                  <ButtonCustom
                    fluid
                    onClick={(e) => {
                      handleClose();
                    }}
                  >
                    {t('transactionDoneConfirmNo')}
                  </ButtonCustom>
                </>
              ) : (
                ""
              )}
            </div>
            {/* <ProgressBar animated now={100} /> */}
            {/* <div className="loading">
                    <span className='loadingDone' />
                </div> */}
          </Modal.Body>
        </>
      </div>
    </Modal>
  );
};

export default TransactionDone;

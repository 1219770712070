import React, { SetStateAction, useEffect } from "react";
import "./Sidebar.scss";
import {
  SmallLogo,
  TradeIcon,
  PortfolioIcon,
  EarnIcon,
  GovernanceIconTwo,
  AnalyticsIcon,
  EducationIcon,
  SupportIcon,
  LockIcon,
  OpenLock,
  GlowingBulb,
  BulbOffIcon,
} from "../../../Assets/Images/Icons/SvgIcons";
import IslamIcon from '../../../Assets/Images/Icons/islam.png'
import { Link, NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUserOnboardingData } from "../../../Redux/Slices/user.slice";
import { changeTheme } from "../../../Redux/Slices/theme.slice";
import { callApiPostMethod } from "../../../Redux/Actions/api.action";
import { Dispatch } from "redux";
import { RESPONSES } from "../../../Utils";
import { useTranslation } from "react-i18next";

const Sidebar = ({ lock, setLock, setShowSidebar, setLockVisitor }: { lock?: any; setLock: any, setShowSidebar?: any, setLockVisitor?: any, }) => {
  const location = useLocation();
  const dispatch: Dispatch<any> = useDispatch();
  const { t } = useTranslation();

  const userwalletAddress: any = useSelector(
    (state: any) => state?.user?.walletAddress
  );
  // const handleProgressBar = () => {
  //   if (!userwalletAddress) setCurrentStep(0, "/");
  // };

  const updateUserLockStatus = async (value) => {
    setLock(value);
    setLockVisitor(value);
    const result: any = await dispatch(
      callApiPostMethod(
        "USER_ONBOARDING",
        {
          walletAddress: userwalletAddress,
          lock: value.toString(),
        },
        false,
        false
      )
    );
  };

  const navigationLinks = [
    {
      id: 1,
      path: "/auth/trade/simple/market",
      icon: <TradeIcon />,
      label: t("leftNavTrade"),
    },
    {
      id: 2,
      path: "/auth/dashboard/portfolio",
      icon: <PortfolioIcon />,
      label: t("leftNavPortfolio"),
    },
    {
      id: 3,
      path: "/auth/earn/overview",
      icon: <EarnIcon />,
      label: t("leftNavEarn"),
    },
    {
      id: 4,
      path: "/auth/dashboard/libfi",
      icon: <GovernanceIconTwo />,
      label: t("leftNavGovernance")
    },
    {
      id: 5,
      path: "/auth/dashboard/libfi",
      icon: <AnalyticsIcon />,
      label: t("leftNavAnalytics")
    },
    {
      id: 6,
      path: "/auth/dashboard/libfi",
      icon: <EducationIcon />,
      label: t("leftNavEducation")
    },
    {
      id: 7,
      path: "/auth/shariah/tokens",
      icon: <img src={IslamIcon} style={{ height: 20, width: 20 }} />,
      label: t('leftNavShariah')
    },
    {
      id: 8,
      path: "/auth/dashboard/libfi",
      icon: <SupportIcon />,
      label: t("leftNavSupport")
    },
  ];
  const theme: any = useSelector((state: any) => state?.theme);

  return (
    <aside className={`Sidebar ${lock ? "locked" : ""}`}>
      <Link
        to={!userwalletAddress ? "/" : "/auth/dashboard/libfi"}
        onClick={() =>
          !userwalletAddress
            ? dispatch(setUserOnboardingData({ progress: 0, path: "/" }))
            : null
        }
        className="sidebar_logo"
      >
        <SmallLogo />
        <span className="sidebar_logo_text">{t('leftNavLogo')}</span>
      </Link>
      <ul className="navigation_link">
        {navigationLinks?.map((item) => (
          <li key={item?.id}>
            <NavLink to={item?.path} className="nav_item" onClick={() => setShowSidebar(false)}>
              <span className="nav_item_icon">{item?.icon}</span>
              <span className="nav_item_text">{item?.label}</span>
            </NavLink>
          </li>
        ))}
      </ul>
      <ul className="sidebar_action">
        <li className="d-md-block d-none">
          <button
            onClick={() => {
              updateUserLockStatus(!lock);
            }}
          >
            {lock ? <LockIcon /> : <OpenLock />}
          </button>
        </li>
        <li className="d-none">
          <button
            onClick={() =>
              dispatch(changeTheme(theme?.theme === "light" ? "dark" : "light"))
            }
          >
            {theme?.theme === "light" ? <GlowingBulb /> : <BulbOffIcon />}
          </button>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;

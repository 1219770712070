import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./MyLibfiPage.scss";
import ButtonCustom from "../../../Common/Button/ButtonCustom";
import { Col, Row } from "react-bootstrap";
import MyPortfolio from "../../../Common/Cards/MyPortfolio/MyPortfolio";
import QuickLinks from "../../../Common/Cards/QuickLinks/QuickLinks";
import NewsCard from "../../../Common/Cards/NewsCard/NewsCard";
import WhatHot from "../../../Common/Cards/WhatHot/WhatHot";
import TopPools from "../../../Common/Cards/TopPools/TopPools";
import { useDispatch, useSelector } from "react-redux";
import { callApiPostMethod, callAdminApiGetMethod } from "../../../../Redux/Actions/api.action";
import {
  getNativeBalance,
  getTokenBalanceForNewUser,
} from "../../../../Services/contractCallService";
import {
  dynamicContractDetails,
  tokenCollection,
} from "../../../../Services/dynamicContractDetails";
import { setGlobalPoolData } from "../../../../Redux/Slices/user.slice";
import { zeroAddress } from "../EarnAMMPools/EarnAMMHelper";
import {
  DATA_INSERTED,
  DOLLAR_VAL,
  WHATS_HOT,
  TOP_LIQUIDITY_POOL
} from "../../../../Redux/Actions/apiResponseInterfaces";
import { getPoolData } from "../../../../hooks/useFetchPoolData";

const MyLibfiPage = () => {
  const dispatch: any = useDispatch();
  const userwalletAddress: string = useSelector(
    (state: any) => state?.user?.walletAddress
  );
  const globalPoolData = useSelector((state: any) => state?.user?.poolData);
  const [hotPools, setHotPools] = useState<WHATS_HOT[]>([]);
  const [topLiquidityPools, setTopLiquidityPools] = useState<TOP_LIQUIDITY_POOL[]>([]);
  interface Banner {
    heading: string;
    subheading: string;
    image: string;
    buttontext: string;
    buttonlink: string;
  }
  const [banners, setBanners] = useState<Banner[]>([]);
  const { walletAddress } = useSelector((state: any) => state?.user);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    rtl: document.body.dir === "rtl"
  };

  useEffect(() => {
    window.dispatchEvent(new CustomEvent("navigationhandler"));
  });

  useEffect(() => {
    if (userwalletAddress) updateTokenBalanceForNewUser();
    if (!globalPoolData?.length) getPoolsInfo();
  }, [userwalletAddress]);

  useEffect(() => {
    getHotPools();
    getTopLiquidityPools();
  }, []);

  useEffect(() => {
    
    const fetchBanners = async () => {
      try {
        const result: Banner[] = await dispatch(
          callAdminApiGetMethod(
            'GET_HOME_BANNERS',
            [], // params
            false, // showToaster
            false // showLoader
          )
        );
        setBanners(result);
      } catch (error) {
        console.error('Error fetching banners:', error);
      }
    };

    fetchBanners();

  }, []);

  /**
   * Get Recent Transaction Trade History Data
   */

  const updateTokenBalanceForNewUser = async () => {
    let result: any;
    const updatedList = await tokenCollection.filter((value) => {
      return value.symbol != "WETH";
    });
    const tokensBalance = await Promise.all(
      updatedList.map(async (item: any) => {
        if (item?.symbol == "ETH") {
          let nativeBalance: any = await getNativeBalance(userwalletAddress);
          result = nativeBalance?.res;
        } else {
          result = await getTokenBalanceForNewUser({
            tokenAddress: item?.address,
            dispatch,
            walletAddress: userwalletAddress,
          });
        }
        return {
          name: item?.symbol,
          token: item?.address?.toLowerCase(),
          balance: result,
        };
      })
    );
    const apiResult: DATA_INSERTED = await dispatch(
      callApiPostMethod(
        "USER_ONBOARDING",
        { walletAddress: userwalletAddress, balance: tokensBalance },
        false,
        false
      )
    );
    if (apiResult?.status == 200) {
    }
  };

  const getPoolsInfo = async () => {
    const poolData = await getPoolData({ dispatch, walletAddress });
    dispatch(setGlobalPoolData(poolData));
  };

  const getHotPools = async () => {
    const result: WHATS_HOT[] = await dispatch(
      callAdminApiGetMethod("HOT_POOLS", {}, false, false)
    );
    if (result) { 
      setHotPools(result);
    }
  };
  const getTopLiquidityPools = async () => {
    const result: TOP_LIQUIDITY_POOL[] = await dispatch(
      callAdminApiGetMethod("TOP_LIQUIDITY_POOLS", {}, false, false)
    );
    if (result) { 
      setTopLiquidityPools(result);
    }
  };
  return (
    <div className="LibfiPage">
      <div className="LibfiPageBanner">
        {/* <Slider className="BannerSlider" {...settings}>
          <div className="BannerSlide">
            <h2>Get Hyped For ETH Shanghai</h2>
            <h3>Reap the benefits in many ways</h3>
            <ButtonCustom
              title="Start trading"
              navigate="/auth/trade/simple/market"
            />
          </div>
          <div className="BannerSlide">
            <h2>Get Hyped For ETH Shanghai</h2>
            <h3>Reap the benefits in many ways</h3>
            <ButtonCustom
              title="Start trading"
              navigate="/auth/trade/simple/market"
            />
          </div>
          <div className="BannerSlide">
            <h2>Get Hyped For ETH Shanghai</h2>
            <h3>Reap the benefits in many ways</h3>
            <ButtonCustom
              title="Start trading"
              navigate="/auth/trade/simple/market"
            />
          </div>
        </Slider> */}
        <Slider className="BannerSlider" {...settings}>
    {banners.map((banner, index) => (
        
        <div className="BannerSlide" key={index}>
            <img src={banner.image} alt={banner.heading} className="banner-image"/>
            <h2>{banner.heading}</h2>
            <h3>{banner.subheading}</h3>
            <ButtonCustom title={banner.buttontext} navigate={banner.buttonlink} />
        </div>
    ))}
</Slider>


      </div>
      <Row>
        <Col lg={4} sm={6}>
          <MyPortfolio />
        </Col>
        <Col lg={4} sm={6}>
          <QuickLinks />
        </Col>
        <Col lg={4} xs={12}>
          <NewsCard />
        </Col>
        <Col xs={12}>
          <WhatHot pools={hotPools} newSlider={true} />
        </Col>
        <Col xs={12}>
          <TopPools pools={topLiquidityPools}/>
        </Col>
      </Row>
    </div>
  );
};

export default MyLibfiPage;
